import { LoanVariationFlag, VaultAccountStatus } from '@harmoney/api-interfaces';
import { PaymentStatusEnum, UpdateDirectDebitPlan } from '@prisma/client';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export const contractualStartDate = dayjs().add(1, 'day').toDate();
export const contractualEndDate = dayjs().add(60, 'day').toDate();

export const loanStatusBadgeVariant = {
  [VaultAccountStatus.ACCOUNT_STATUS_CANCELLED]: 'tertiary',
  [VaultAccountStatus.ACCOUNT_STATUS_CLOSED]: 'tertiary',
  [VaultAccountStatus.ACCOUNT_STATUS_OPEN]: 'success',
  [VaultAccountStatus.ACCOUNT_STATUS_PENDING]: 'warning',
  [VaultAccountStatus.ACCOUNT_STATUS_PENDING_CLOSURE]: 'warning',
  [VaultAccountStatus.ACCOUNT_STATUS_UNKNOWN]: 'tertiary',
};

export const vaultFlags = [LoanVariationFlag.INTEREST_ACCRUED_PAUSE_FLAG, LoanVariationFlag.REPAYMENT_HOLIDAY_FLAG];

export const getFlagLinkText = (flagName: LoanVariationFlag) => {
  return vaultFlags.includes(flagName) ? 'variation history' : 'change summary';
};

export const smoothScrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const downloadCSV = (csvData, fileName) => {
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.csv`;
  link.click();
  window.URL.revokeObjectURL(link.href);
};

export const createScheduleExtraPaymentFlags = (oneOffPayments) => {
  if (!oneOffPayments) return [];
  return oneOffPayments
    .filter((data) => {
      return data.schedules.some(
        (schedule) =>
          dayjs(schedule?.scheduledAt).isSameOrAfter(dayjs(), 'day') && schedule?.status !== PaymentStatusEnum.cancelled
      );
    })
    .map((data) => {
      const validSchedules = data.schedules.filter(
        (schedule) =>
          dayjs(schedule?.scheduledAt).isSameOrAfter(dayjs(), 'day') &&
          (schedule?.status === PaymentStatusEnum.new || schedule?.status === PaymentStatusEnum.processing)
      );
      const firstValidSchedule = validSchedules[0];

      const flag = {
        flagName: LoanVariationFlag.SCHEDULE_EXTRA_PAYMENT_FLAG,
        scheduledAt: firstValidSchedule?.scheduledAt,
        amount: firstValidSchedule?.amount,
      };

      if (validSchedules?.length > 1) {
        flag['numberOfOtherPayments'] = validSchedules.length - 1;
      }

      return flag;
    });
};

export const createUpdatedDirectDebitFlags = (directDebitPlans: UpdateDirectDebitPlan[]) => {
  if (directDebitPlans?.length === 0) return [];

  const validDirectDebitPlans = directDebitPlans?.filter((plan) => {
    return dayjs(plan?.paymentDate).isSameOrAfter(dayjs(), 'day');
  });

  const firstValidDirectDebitPlan = validDirectDebitPlans[0];

  const flag = {
    flagName: LoanVariationFlag.UPDATE_DIRECT_DEBIT_FLAG,
    paymentDate: firstValidDirectDebitPlan?.paymentDate,
    paymentFrequency: firstValidDirectDebitPlan?.paymentFrequency,
  };

  return flag;
};

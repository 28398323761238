import { useRouter } from 'next/router';
import { useState } from 'react';
import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { useBankStatementRefStatus, useFriendlyURL } from '@harmoney/hooks';
import {
  useGetBankStatementReferencesByLoanApplicationIdQuery,
  useGetFinancialSummaryQuery,
  useGetLoanApplicationQuery,
  useGetLoanProductByTaskIdQuery,
  useGetUserProfileQuery,
  useGetVariablesQuery,
  useSaveExpensesMutation,
  useSubmitIncomeMutation,
} from '@harmoney/redux';
import {
  AmountFrequency,
  ArrowCircleRightIcon,
  Button,
  Card,
  Dialog,
  Form,
  QuoteLoading,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { errors, frequencyOptions, stringToBool, toggleYesNoOptions } from '@harmoney/ui-utils';
import { isDebtCon, isPersonalLoan } from '@harmoney/utilities';
import { isEmpty } from 'lodash';
import { z } from 'zod';

import { CommonProps } from '../../common-props';
import {
  Assets,
  BankAccounts,
  BasicInformation,
  DebtConLoanDetails,
  LoanDetails,
} from '../FinancialSummary/components';
import { LivingSituation } from '../FinancialSummary/components/LivingSituation';

import { Debts, Expenses, Incomes } from './components';

const summaryPageSchema = z
  .object({
    hasIncomeChange: z.string().min(1, { message: errors.defaultRequiredField }),
    hasExpenseChange: z.string().min(1, { message: errors.defaultRequiredField }),
    incomeDeclaredAmount: z.coerce.number().optional(),

    incomeFrequency: z.string().optional(),

    expenseDeclaredAmount: z.coerce.number().optional(),
    expenseFrequency: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.hasIncomeChange === 'Yes' && data.incomeDeclaredAmount === 0) {
        return false;
      }
      return true;
    },
    {
      message: errors.defaultValidAmount,
      path: ['incomeDeclaredAmount'],
    }
  )
  .refine(
    (data) => {
      if (data.incomeDeclaredAmount > 0 && isEmpty(data.incomeFrequency)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['incomeFrequency'],
    }
  )
  .refine(
    (data) => {
      if (data.hasExpenseChange === 'Yes' && data.expenseDeclaredAmount === 0) {
        return false;
      }
      return true;
    },
    {
      message: errors.defaultValidAmount,
      path: ['expenseDeclaredAmount'],
    }
  )
  .refine(
    (data) => {
      if (data.expenseDeclaredAmount > 0 && isEmpty(data.expenseFrequency)) return false;
      return true;
    },
    {
      message: errors.defaultRequiredFrequency,
      path: ['expenseFrequency'],
    }
  );

export function FinancialSummaryV2({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const form = useForm({
    mode: 'onTouched',
    schema: summaryPageSchema,
    defaultValues: {
      hasExpenseChange: '',
      hasIncomeChange: '',
      incomeDeclaredAmount: null,
      expenseDeclaredAmount: null,
      incomeFrequency: '',
      expenseFrequency: '',
    },
  });

  const { register, watch } = form;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();

  const watchForm = watch();

  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: userProfileData } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables?.loanApplicationId,
    refetchOnMountOrArgChange: true,
  });

  const { data: financialSummary } = useGetFinancialSummaryQuery(
    { id: variables?.financialProfileId.toString(), loanApplicationId: variables?.loanApplicationId?.toString() },
    {
      skip: !variables,
    }
  );

  const { data: bankStatementReferences } = useGetBankStatementReferencesByLoanApplicationIdQuery(
    variables?.loanApplicationId?.toString(),
    {
      skip: !variables,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const { allReferencesStatus } = useBankStatementRefStatus(bankStatementReferences);

  const { data: loanProductData } = useGetLoanProductByTaskIdQuery(taskId as string, {
    skip: taskId === undefined,
  });

  const [saveExpenses] = useSaveExpensesMutation();
  const [submitIncome] = useSubmitIncomeMutation();

  const [showBankStatementDialog, setShowBankStatementDialog] = useState(false);

  const handleSubmit = async (data) => {
    let incomeChangeAmount, expenseChangeAmount, incomeChangeFrequency, expenseChangeFrequency;
    if (data.hasIncomeChange === 'Yes') {
      incomeChangeAmount = data.incomeDeclaredAmount;
      incomeChangeFrequency = data.incomeFrequency;
    } else {
      incomeChangeAmount = 0;
      incomeChangeFrequency = null;
    }

    if (data.hasExpenseChange === 'Yes') {
      expenseChangeAmount = data.expenseDeclaredAmount;
      expenseChangeFrequency = data.expenseFrequency;
    } else {
      expenseChangeAmount = 0;
      expenseChangeFrequency = null;
    }

    const incomeData = {
      networthSourceId: NetworthSourceEnum.INCOME_CHANGE_ID,
      declaredAmount: incomeChangeAmount,
      frequency: incomeChangeFrequency,
      expectIncreaseDecreaseChange: stringToBool(data.hasIncomeChange),
    };
    const expenseData = {
      networthSourceId: NetworthSourceEnum.EXPENSE_CHANGE_ID,
      declaredAmount: expenseChangeAmount,
      frequency: expenseChangeFrequency,
      expectIncreaseDecreaseChange: stringToBool(data.hasExpenseChange),
    };
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 240000);

    await submitIncome({
      incomes: [incomeData],
      taskId: taskId,
    });
    await saveExpenses({
      expenses: [expenseData],
      taskId: taskId,
    });
    await completeTask({ taskId });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      {isSubmitting ? (
        <QuoteLoading />
      ) : (
        <>
          <h1>
            Good to go? Please <strong className="text-primary">check your information</strong>
          </h1>
          {isDebtCon(loanProductData?.name) ? (
            <DebtConLoanDetails loanApplicationData={loanApplicationData} />
          ) : (
            <LoanDetails loanApplicationData={loanApplicationData} />
          )}
          {isPersonalLoan(loanProductData?.name) && (
            <BasicInformation loanApplicationData={loanApplicationData} userProfileData={userProfileData} />
          )}
          <BankAccounts financialSummary={financialSummary} />
          <LivingSituation financialSummary={financialSummary} isDebtCon={isDebtCon(loanProductData?.name)} />
          <Expenses financialSummary={financialSummary} />
          <Incomes financialSummary={financialSummary} />
          <Assets financialSummary={financialSummary} />
          <Debts debts={financialSummary?.liabilities} productName={loanProductData?.name} />

          <Form form={form} onSubmit={handleSubmit}>
            <>
              <Card>
                <ToggleGroup
                  {...register('hasIncomeChange')}
                  key={`hasIncomeChange`}
                  label="Do you anticipate your income to significantly decrease in a way that might affect your ability to make repayments to us?"
                  options={toggleYesNoOptions}
                />

                {watchForm?.hasIncomeChange === 'Yes' && (
                  <AmountFrequency
                    register={register}
                    label={`By how much will your income decrease?`}
                    inputKey="incomeDeclaredAmount"
                    selectKey="incomeFrequency"
                    name="income-changes"
                    options={frequencyOptions}
                    className="mt-4"
                  />
                )}
              </Card>
              <Card>
                <ToggleGroup
                  {...register('hasExpenseChange')}
                  key={`hasExpenseChange`}
                  label="Do you anticipate your expenses to significantly increase in a way that might affect your ability to make repayments to us?"
                  options={toggleYesNoOptions}
                />

                {watchForm?.hasExpenseChange === 'Yes' && (
                  <AmountFrequency
                    register={register}
                    label={`By how much will your expenses increase?`}
                    inputKey="expenseDeclaredAmount"
                    selectKey="expenseFrequency"
                    name="expense-changes"
                    options={frequencyOptions}
                    className="mt-4"
                  />
                )}
              </Card>
            </>
            <div className="flex justify-center">
              {['success', 'partial-success'].includes(allReferencesStatus) ? (
                <Button
                  type="submit"
                  alignIcon="end"
                  variant="primary"
                  icon={<ArrowCircleRightIcon size="large" />}
                  hasShadow
                >
                  Continue
                </Button>
              ) : (
                <Button
                  type="button"
                  alignIcon="end"
                  variant="primary"
                  icon={<ArrowCircleRightIcon size="large" />}
                  hasShadow
                  onClick={() => setShowBankStatementDialog(true)}
                >
                  Continue
                </Button>
              )}
            </div>
          </Form>
          <Dialog
            open={showBankStatementDialog}
            title="Processing bank statements..."
            defaultOpen={false}
            onOpenChange={setShowBankStatementDialog}
            modal
            // showCloseButton={false}
          >
            <div className="flex flex-col p-4 gap-2">
              <p>About 2-3 minutes remaining.</p>
              <p>Processing bank statements is taking longer than expected. Please wait or come back later.</p>
              <Button
                type="button"
                alignIcon="end"
                variant="primary"
                className="mb-4 sm:mb-0"
                icon={<ArrowCircleRightIcon size="large" />}
                hasShadow
                isFullWidth
                onClick={() => router.reload()}
              >
                Refresh
              </Button>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}

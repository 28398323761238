import { browserName, osName, browserVersion, getUA, deviceDetect, isMobile } from 'react-device-detect';

export class Metadata {
  private device = deviceDetect(getUA);

  get() {
    return {
      TIME: new Date().toISOString(),
      'RESOURCE ADDRESS': window.location.origin + window.location.pathname,
      'FULL URL': window.location.href,
      'REFERRER URL': window.document.referrer,
      COOKIES: this.cookies(),
      DEVICE: isMobile
        ? `mobile - ${this.device.vendor} - ${this.device.model}`
        : `${this.device.osName} - ${this.device.osVersion}`,
      OS: osName,
      BROWSER: browserName,
      'BROWSER VERSION': browserVersion,
      'SCREEN WIDTH': window.screen.width,
      'SCREEN HEIGHT': window.screen.height,
    };
  }

  private cookies() {
    const cookies = window.document.cookie;

    return cookies.split(/\s*;\s*/).reduce((result, cookie) => {
      const [name, value] = cookie.trim().split('=');
      if (name && value) {
        result[name] = `${value}`;
      }
      return result;
    }, {} as any);
  }
}

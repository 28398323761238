import { useMemo } from 'react';
import { LoanPurposeDisplayType, LoanPurposeNameEnum } from '@harmoney/api-interfaces';
import { useFriendlyURL, useTransformLoanPurposes } from '@harmoney/hooks';
import { useGetLoanPurposesForPersonalLoanQuery, useGetUserQuery } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  Form,
  Label,
  RadioSelectSelector,
  useForm,
} from '@harmoney/ui-design-system';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import { defaultValues, formSchema } from './form-config';

export function RepeatLoanPurpose({ taskId, taskFriendlyURL, completeTask }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const { data: userData } = useGetUserQuery();
  const { data: loanPurposes, isLoading: isLoanPurposesLoading } = useGetLoanPurposesForPersonalLoanQuery(null);

  const filteredLoanPurposes = useMemo(() => {
    if (!loanPurposes) return [];

    return loanPurposes
      .filter((loanPurpose) => loanPurpose.name !== LoanPurposeNameEnum.DEBT_CONSOLIDATION)
      .map((loanPurpose) => {
        if (loanPurpose.name === LoanPurposeNameEnum.GOING_ON_HOLIDAY) {
          return { ...loanPurpose, displayType: LoanPurposeDisplayType.ICON };
        }
        return loanPurpose;
      });
  }, [loanPurposes]);

  const { primaryLoanPurposes, secondaryLoanPurposes } = useTransformLoanPurposes(filteredLoanPurposes);

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const {
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  const handleSubmit = (data: z.infer<typeof formSchema>) => {
    // TODO: Implement submit logic here when integrating with Repeat Extra Limit BPMN
    completeTask({
      taskId,
    });
  };

  return (
    <>
      <h1>
        Hi <span className="text-primary">{userData?.preferredName ?? ''}</span>, tell us more
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        {!isLoanPurposesLoading && primaryLoanPurposes.length > 0 && secondaryLoanPurposes.length > 0 && (
          <Card>
            <Label className="mb-2">Select a personal loan</Label>
            <RadioSelectSelector
              name="loanPurpose"
              radioOptions={primaryLoanPurposes}
              selectOptions={secondaryLoanPurposes}
            />
          </Card>
        )}

        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

import Image from 'next/image';
import { Fragment } from 'react';
import { FinancialProfileDto } from '@harmoney/api-interfaces';
import { Card, Divider, Label, Spinner } from '@harmoney/ui-design-system';

export const BankAccounts = ({ financialSummary }: { financialSummary: FinancialProfileDto }) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Bank statements</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />
      {financialSummary?.bankAccounts.length > 0 ? (
        financialSummary?.bankAccounts.map(({ id, bankSlug, bankAccountNumber, bankName, bankLogoUrl }) => (
          <Fragment key={`bankAccount-${id}`}>
            <div className="text-md flex flex-row items-center">
              <Image
                className="m-4"
                src={bankLogoUrl}
                alt={`${bankSlug} logo`}
                width="65"
                height="23"
                draggable={false}
              />
              <div className="p-4">
                <Label className="text-base">{bankName}</Label>
                <span className="text-xs">{bankAccountNumber}</span>
              </div>
            </div>
            <Divider className="text-grey-2 m-0 px-4" />
          </Fragment>
        ))
      ) : (
        <div className="m-4 flex items-center gap-x-4 pb-4">
          <Spinner variant="secondary" size="small" />
          <p>Your bank statements are currently processing.</p>
        </div>
      )}
      {/* <div className="flex justify-center p-4">
        <Button size="medium" variant="text">
          Add another bank
        </Button>
      </div> */}
    </Card>
  );
};

import { LoanApplicationQueryResponse } from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { amountOrNA, booleanToYesNoOrNA, convertMonthsToYears, DATE_TIME_FORMAT, valueOrNA } from '@harmoney/ui-utils';
import { AffordabilityModelEnum, ScorecardOperation, UmiCalculationOperation } from '@prisma/client';
import { Prisma } from '@prisma/client';
import dayjs from 'dayjs';

import { Field } from './shared';

interface Props {
  loanApplicationData: LoanApplicationQueryResponse;
  affordabilityData: UmiCalculationOperation;
  scorecardData: ScorecardOperation[];
  rootData: Prisma.JsonValue;
  extraLimitData: Prisma.JsonValue;
}

export const LoanQuoteDetails = ({
  loanApplicationData,
  affordabilityData,
  scorecardData,
  rootData,
  extraLimitData = {},
}: Props) => {
  if (!loanApplicationData || !affordabilityData || !scorecardData || !rootData) return null;

  return (
    <CollapsibleCard title="Quote" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="odd:border-r-grey-1 p-4 odd:border-r-2 space-y-2">
          <span className="text-primary font-bold">Quote summary</span>
          <Field className="space-x-4">
            <span>Final customer accepted amount:</span>
            <span>{amountOrNA(loanApplicationData?.fundedAmount)}</span>
          </Field>

          {loanApplicationData?.loanApplicationPurposes.length > 0 &&
            loanApplicationData?.loanApplicationPurposes.map((loanApplicationPurpose) => {
              return (
                <Field key={loanApplicationPurpose.id} className="space-x-4">
                  {loanApplicationPurpose.loanPurposeId && (
                    <>
                      <span>{loanApplicationPurpose.loanPurpose.displayName} accepted:</span>
                      <span>
                        {loanApplicationPurpose.fundedAmount
                          ? amountOrNA(loanApplicationPurpose.fundedAmount)
                          : amountOrNA(loanApplicationData.fundedAmount)}
                      </span>
                    </>
                  )}
                </Field>
              );
            })}

          <Field className="space-x-4">
            <span>Initial quote presented amount:</span>
            {(extraLimitData['extraLimitOutput']?.['quotePresentedAmount'] ||
              loanApplicationData?.quotePresentedAmount) && (
              <span>
                {amountOrNA(
                  extraLimitData['extraLimitOutput']?.['quotePresentedAmount'] ||
                    loanApplicationData?.quotePresentedAmount
                )}
              </span>
            )}
            {!extraLimitData['extraLimitOutput']?.['quotePresentedAmount'] &&
              !loanApplicationData?.quotePresentedAmount && (
                <span>{amountOrNA(rootData?.['quote']?.['maximumBorrowingLimit'])}</span>
              )}
          </Field>
          <Field className="space-x-4">
            <span>Product Type:</span>
            <span>{valueOrNA(rootData?.['selectedOffer'])}</span>
          </Field>
          <Field className="space-x-4">
            <span>Quote interest rate:</span>
            <span>{loanApplicationData?.finalInterestRate ? `${loanApplicationData?.finalInterestRate}%` : 'N/A'}</span>
          </Field>
          <Field className="space-x-4">
            <span>Quote accepted term:</span>
            <span>{convertMonthsToYears(loanApplicationData?.termInMonths)}</span>
          </Field>

          <Field className="space-x-4">
            <span>Quote start date:</span>
            <span>
              {loanApplicationData?.createdAt ? dayjs(loanApplicationData?.createdAt).format(DATE_TIME_FORMAT) : 'N/A'}
            </span>
          </Field>
          <Field className="space-x-4">
            <span>Quote expiry date:</span>
            <span>
              {loanApplicationData?.quoteExpiryAt
                ? dayjs(loanApplicationData?.quoteExpiryAt).format(DATE_TIME_FORMAT)
                : 'N/A'}
            </span>
          </Field>
        </div>
        <div className="p-4 space-y-2">
          <span className="text-primary font-bold">Quote logic</span>
          <Field className="space-x-4">
            <span>Loan limit from income:</span>
            <span>{amountOrNA(affordabilityData?.loanLimitFromIncome)}</span>
          </Field>
          <Field className="space-x-4">
            <span>Loan limit from risk:</span>
            <span>{amountOrNA(rootData?.['quote']?.['loanLimitFromRisk'])}</span>
          </Field>
          <Field className="space-x-4">
            <span>Loan limit from umi for three years:</span>
            <span>
              {amountOrNA(
                rootData?.['quote']?.['loanLimitFromUmi']?.['loanLimitFromUmiForThreeYears'] ??
                  rootData?.['quote']?.['loanLimitFromUmiForTerms']?.['loanLimitFromUmiForThreeYears']
              )}
            </span>
          </Field>
          <Field className="space-x-4">
            <span>Loan limit from umi for five years:</span>
            <span>
              {amountOrNA(
                rootData?.['quote']?.['loanLimitFromUmi']?.['loanLimitFromUmiForFiveYears'] ??
                  rootData?.['quote']?.['loanLimitFromUmiForTerms']?.['loanLimitFromUmiForFiveYears']
              )}
            </span>
          </Field>
          <Field className="space-x-4">
            <span>Loan limit from umi for seven years:</span>
            <span>
              {amountOrNA(
                rootData?.['quote']?.['loanLimitFromUmi']?.['loanLimitFromUmiForSevenYears'] ??
                  rootData?.['quote']?.['loanLimitFromUmiForTerms']?.['loanLimitFromUmiForSevenYears']
              )}
            </span>
          </Field>
          <Field className="space-x-4">
            <span>Requested amount:</span>
            <span>{amountOrNA(rootData?.['quote']?.['requestedAmount'])}</span>
          </Field>
          <Field className="space-x-4">
            <span>Credit limit from score card:</span>
            <span>{amountOrNA(rootData?.['creditLimit'])}</span>
          </Field>
          <Field className="space-x-4">
            <span>Maximum product amount:</span>
            <span>{amountOrNA(loanApplicationData?.loanProduct?.maximumLoanAmount)}</span>
          </Field>
          {(rootData?.['selectedOffer'] === AffordabilityModelEnum.hMoney ||
            rootData?.['selectedOffer'] === AffordabilityModelEnum.hMoneyWithSharedExpense) && (
            <Field className="space-x-4">
              <span>Borrowing limit for loan purpose:</span>
              <span>{amountOrNA(rootData?.['quote']?.['borrowingLimitForLoanPurpose'])}</span>
            </Field>
          )}
          <Field className="space-x-4">
            <span>Customer borrowing limit:</span>
            <span>
              {amountOrNA(
                extraLimitData['extraLimitOutput']?.['customerBorrowingLimit'] ||
                  loanApplicationData?.customerBorrowingLimit
              )}
            </span>
          </Field>
          <Field className="space-x-4">
            <span>Application borrowing limit:</span>
            <span>
              {amountOrNA(
                extraLimitData['extraLimitOutput']?.['applicationBorrowingLimit'] ||
                  loanApplicationData?.applicationBorrowingLimit
              )}
            </span>
          </Field>
          <Field className="space-x-4">
            <span>Has active mortgage:</span>
            <span>{booleanToYesNoOrNA(extraLimitData['hasActiveMortgage'])}</span>
          </Field>
        </div>
      </div>
    </CollapsibleCard>
  );
};

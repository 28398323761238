import { forwardRef, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { ToggleGroupSingleProps as RadixToggleGroupSingleProps } from '@radix-ui/react-toggle-group';
import * as RadixToggleGroup from '@radix-ui/react-toggle-group';
import classNames from 'classnames';

import { CommonOptionProps } from '../../types';
import { HelpText } from '../HelpText';
import { Label } from '../Label';
import { ValidationMessage } from '../ValidationMessage';

import styles from './ToggleGroup.module.scss';

export interface ToggleGroupProps extends Omit<RadixToggleGroupSingleProps, 'type' | 'asChild'> {
  /**
   * The name of the toggle group. Submitted with its owning form as part of a name/value pair.
   */
  name: string;
  /**
   * The items to display in the toggle group.
   * @param label The label to display for the item.
   * @param value The value of the item.
   */
  options: CommonOptionProps[];
  /**
   * The label to display for the toggle group.
   */
  label?: string | ReactNode;
  helpText?: string;
  /**
   * Return value of the selected item is string or boolean.
   * default is string (isBoolean = false)
   */
  isBoolean?: boolean;
  /**
   * Validation message
   */
  validationMessage?: string;
  /**
   * special case to hide the validation message in case not required
   */
  displayValidation?: boolean;
}

export const ToggleGroup = forwardRef<HTMLDivElement, ToggleGroupProps>(
  (
    {
      className,
      options,
      label,
      helpText,
      validationMessage,
      displayValidation = true,
      isBoolean = false,
      ...restProps
    }: ToggleGroupProps,
    forwardRef: React.Ref<HTMLDivElement>
  ) => {
    const {
      control,
      getFieldState,
      formState,
      formState: { errors },
    } = useFormContext();
    const { name, disabled } = restProps;
    const { invalid } = getFieldState(name, formState);
    const checkIsBoolean = (value: any) => typeof value === 'boolean';

    return (
      <div className={className} ref={forwardRef}>
        {label && <Label className="mb-2">{label}</Label>}
        {helpText && <HelpText>{helpText}</HelpText>}
        <div className="flex items-center gap-x-4 ">
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <RadixToggleGroup.Root
                className={classNames('inline-flex min-w-full', {
                  'sm:min-w-[20.5rem]': !className?.includes('sm:min-w-full'),
                })}
                type="single"
                data-testid={name}
                value={checkIsBoolean(value) ? value.toString() : value}
                disabled={disabled}
                onBlur={onBlur}
                onValueChange={(value) => {
                  const newValue = checkIsBoolean(value) ? value : isBoolean ? value === 'true' : value;
                  if (checkIsBoolean(newValue) || newValue) onChange(newValue);
                }}
                ref={forwardRef || ref}
                {...restProps}
              >
                {options &&
                  options.length > 0 &&
                  options.map(({ label, value }) => (
                    <RadixToggleGroup.Item
                      className={classNames(styles['item'], invalid && styles['item-invalid'], 'grow')}
                      key={value}
                      value={checkIsBoolean(value) ? value.toString() : value}
                      aria-label={value.toString()}
                      disabled={disabled}
                      data-testid={value.toString()}
                    >
                      {label}
                    </RadixToggleGroup.Item>
                  ))}
              </RadixToggleGroup.Root>
            )}
          />
        </div>
        {validationMessage && !errors[name] ? (
          <div className="text-error leading-sm mt-1 text-sm"> {validationMessage}</div>
        ) : (
          displayValidation && <ValidationMessage name={name} />
        )}
      </div>
    );
  }
);

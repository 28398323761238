import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Role, UserDto } from '@harmoney/api-interfaces';
import { RootState, useRegisterUserMutation } from '@harmoney/redux';
import { CUSTOMER_ACCOUNT_SIGN_IN, eventAnalytics, NEW_SESSION } from '@harmoney/ui-app-shell';
import * as Sentry from '@sentry/react';

import { useAuth } from '../../hooks/use-auth';
import { setAccessToken } from '../../redux/slice/access-token';
import { setUserId } from '../../redux/slice/user-id';

export function withAuth(WrappedComponent) {
  const AuthComponent = (props) => {
    const { login, isLoading, isAuthenticated, user, getUserRoles, getUserToken } = useAuth();

    const router = useRouter();
    const dispatch = useDispatch();
    const [registerUser] = useRegisterUserMutation();
    const userId = useSelector((state: RootState) => state.userId.value);
    const accessToken = useSelector((state: RootState) => state.accessToken.value);

    useEffect(() => {
      if (isLoading) {
        return;
      }
      if (!isAuthenticated) {
        login();
        return;
      }
      getUserToken()
        // eslint-disable-next-line promise/always-return
        .then((token) => {
          dispatch(setAccessToken(token.access_token));
        })
        .catch(() => {
          login();
        });
    }, [isAuthenticated, isLoading, dispatch, login, getUserToken]);

    useEffect(() => {
      async function fetchUserId() {
        const internalUser = await registerUser(undefined).unwrap();
        dispatch(setUserId(internalUser.id));
        identifyUser({ ...internalUser, s2Id: user['https://stellare.harmoney.com/s2-account-id'] });
        eventAnalytics.track(NEW_SESSION);
      }

      if (isAuthenticated && !userId && accessToken) {
        fetchUserId();
      }
    }, [isAuthenticated, dispatch, registerUser, userId, accessToken, user]);

    useEffect(() => {
      if (!isAuthenticated || !user || !getUserRoles) return;

      const roles = getUserRoles(user);
      const isAdmin = roles.includes(Role.ADMIN);

      if (!isAdmin) {
        eventAnalytics.loadFullStory();

        if (router.pathname.includes('admin')) {
          router.push('/');
        }
      }
    }, [user, getUserRoles, router, isAuthenticated]);

    const identifyUser = ({
      id: userId,
      branch,
      email,
      preferredName: name,
      intercomUserHash,
      s2Id,
    }: UserDto & { s2Id: string }) => {
      Sentry.setUser({
        userId,
      });
      eventAnalytics.identify(
        s2Id,
        {
          name,
          email,
          platform: 's2',
          branch,
        },
        { Intercom: { user_hash: intercomUserHash }, gtmOptions: { event: CUSTOMER_ACCOUNT_SIGN_IN } }
      );
    };

    return <WrappedComponent {...props} />;
  };

  AuthComponent.getLayout = WrappedComponent.getLayout;

  return AuthComponent;
}

import { useAppDispatch } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, useFormContext } from '@harmoney/ui-design-system';

import {
  setIsDirectDebitSuspendOpen,
  setIsScheduleExtraPaymentOpen,
  setShowUpdateDirectDebitPlan,
} from '../../../../redux/slice/admin';

import { ChangeSummaryTypeEnum } from './util';

interface PaymentPlanFormFooterProps {
  buttonText: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  type: ChangeSummaryTypeEnum;
}

export const PaymentPlanFormFooter = ({
  buttonText,
  isSubmitting = false,
  isDisabled = false,
  type,
}: PaymentPlanFormFooterProps) => {
  const dispatch = useAppDispatch();
  const { reset } = useFormContext();

  const handleCancel = () => {
    reset();
    switch (type) {
      case ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT:
        dispatch(setIsDirectDebitSuspendOpen(false));
        break;
      case ChangeSummaryTypeEnum.ONE_OFF_PAYMENT:
        dispatch(setIsScheduleExtraPaymentOpen(false));
        break;
      case ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN:
        dispatch(setShowUpdateDirectDebitPlan(false));
        break;
    }
  };

  return (
    <div className="mb-4 mt-8 flex gap-8 justify-end">
      <Button variant="tertiary" onClick={handleCancel} size="medium" className="!min-w-fit">
        Cancel
      </Button>
      <Button
        isLoading={isSubmitting}
        alignIcon="end"
        icon={<ArrowCircleRightIcon size="large" />}
        variant="primary"
        type="submit"
        className="!min-w-fit"
        disabled={isDisabled}
      >
        {buttonText}
      </Button>
    </div>
  );
};

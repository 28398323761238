import { InternalUserRoleEnum } from '.prisma/client';

export const internalRoleMapper = {
  [InternalUserRoleEnum.ADMIN]: 'admin',
  [InternalUserRoleEnum.CREDIT_OFFICER]: 'Credit Officers',
  [InternalUserRoleEnum.VARIATIONS_ADMIN]: 'Variations Admin',
};

export const roleNameEnumMapper: { [key: string]: InternalUserRoleEnum } = {
  admin: InternalUserRoleEnum.ADMIN,
  'Credit Officers': InternalUserRoleEnum.CREDIT_OFFICER,
  'Operations Admin': InternalUserRoleEnum.OPERATIONS_ADMIN,
  'Variations Admin': InternalUserRoleEnum.VARIATIONS_ADMIN,
};

export const transformRoles = (roles: InternalUserRoleEnum[]) => {
  if (!roles) {
    return [];
  }
  return roles.map((role) => internalRoleMapper[role]);
};

export const roleNameToEnums = (roles: string[]) => {
  if (!roles) {
    return [];
  }
  return roles.filter((role) => role in roleNameEnumMapper).map((role) => roleNameEnumMapper[role]);
};

export const roleEnumToNames = (roles: InternalUserRoleEnum[]) => {
  if (!roles) {
    return [];
  }

  return roles
    .filter((role) => Object.values(roleNameEnumMapper).includes(role))
    .map((role) => Object.keys(roleNameEnumMapper).find((key) => roleNameEnumMapper[key] === role));
};

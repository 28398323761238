import { useMemo } from 'react';
import {
  Alert,
  AmountFrequency,
  AmountInput,
  Badge,
  Button,
  ControlledInput,
  Label,
  PercentageInput,
  Select,
  ToggleGroup,
  useFormContext,
} from '@harmoney/ui-design-system';
import {
  bnplProviderOptions,
  frequencyOptionsWithYear,
  isPartnered,
  providerOptions,
  toggleStringOptions,
  toggleYesNoOptions,
} from '@harmoney/ui-utils';
import {
  isBooleanStringFalse,
  isBooleanStringTrue,
  isDebtCon,
  isPersonalLoan,
  isValidBooleanString,
} from '@harmoney/utilities';

const PROVIDER_OTHER = 'other';

export const DebtItem = ({
  code,
  index,
  formData,
  item,
  loanProduct,
  relationshipStatus,
  sharedMortgageExperimentValue,
  ...props
}) => {
  const { watch, setValue } = useFormContext();
  const isPrefilledAndPaidOff = useMemo(() => {
    return item.recordOrigin === 'prefill' && (item.paidOff === 'true' || !item.paidOff);
  }, [item?.recordOrigin, item?.paidOff]);

  const providerControl = [
    'credit_card',
    'personal_loan',
    'mortgage',
    'overdraft',
    'other_debts',
    'buy_now_pay_later',
    'car_loan',
    'line_of_credit',
  ];
  const amountControl = [
    'credit_card',
    'personal_loan',
    'mortgage',
    'overdraft',
    'other_debts',
    'buy_now_pay_later',
    'car_loan',
    'line_of_credit',
  ];
  const outstandingBalancePaidControl = ['credit_card'];
  const outstandingBalanceValueControl = [
    'personal_loan',
    'mortgage',
    'overdraft',
    'other_debts',
    'car_loan',
    'line_of_credit',
  ];
  const creditLimitControl = ['buy_now_pay_later', 'credit_card', 'line_of_credit'];
  const mortgageSharedControl = ['mortgage'];
  const debtNameControl = ['other_debts'];
  const debtConsolidationControl = ['mortgage', 'buy_now_pay_later', 'other_debts'];
  const interestRateControlWithoutCreditCard = [
    'personal_loan',
    'overdraft',
    'other_debts',
    'car_loan',
    'line_of_credit',
  ];

  const mortgageSharedExperimentLabels = useMemo(() => {
    if (sharedMortgageExperimentValue && sharedMortgageExperimentValue === 'B') {
      return {
        isJointMortgageLabel: 'Do you share this expense with your partner/spouse?',
        jointMortgageAmountLabel: "What's their share?",
      };
    }
    return {
      isJointMortgageLabel: 'Do you get any money towards your mortgage repayment?',
      jointMortgageAmountLabel: 'How much do you get towards it?',
    };
  }, [sharedMortgageExperimentValue]);

  const getCreditLimitControl = (code) => {
    const label = code === 'buy_now_pay_later' ? 'buy now pay later' : 'credit';
    return (
      <AmountInput
        key={`creditLimitControl_${code}_${index}`}
        {...props.register(`debts.${code}[${index}].creditLimit`)}
        label={`How much is your ${label} limit?`}
        type="text"
        className="mb-4"
      />
    );
  };

  const controls = useMemo(() => {
    const controls = [];
    if (debtNameControl.includes(code)) {
      {
        controls.push(
          <ControlledInput
            key={`debtNameControl_${code}_${index}`}
            {...props.register(`debts.${code}[${index}].debtType`)}
            label="What kind of debts do you have?"
            className="mb-4"
          />
        );
      }
    }

    if (item.recordOrigin === 'prefill') {
      {
        controls.push(
          <div className="flex flex-col gap-y-2" key={`prefill_${code}_${index}`}>
            <div className="mb-2 flex flex-col gap-y-2">
              <span className="heading font-medium">{item.provider}</span>
              <Badge label="Debt found on your credit file" variant="tertiary" />
            </div>
            <div className="flex flex-col gap-y-1">
              <Label>Have you paid off this debt?</Label>
              <span className="text-grey-4 text-sm">By saying yes, you agree to supply proof if needed.</span>
              <ToggleGroup
                {...props.register(`debts.${code}[${index}].paidOff`)}
                options={toggleStringOptions}
                className="mb-4"
              />
            </div>
          </div>
        );
      }
    }

    if (providerControl.includes(code) && item.recordOrigin !== 'prefill') {
      const provider = formData[code][index]['provider'];
      controls.push(
        <Select
          key={`providerControl_${code}_${index}`}
          label="Who is your provider?"
          {...props.register(`debts.${code}[${index}].provider`)}
          options={code === 'buy_now_pay_later' ? bnplProviderOptions : providerOptions}
          className="mb-4"
        />
      );
      {
        provider === PROVIDER_OTHER &&
          controls.push(
            <ControlledInput
              key={`otherProviderControl_${code}_${index}`}
              {...props.register(`debts.${code}[${index}].otherProvider`)}
              label="What is the name of your provider?"
              className="mb-4"
            />
          );
      }
    }

    if (creditLimitControl.includes(code)) {
      {
        ['credit_card', 'line_of_credit'].includes(code) &&
          !isPrefilledAndPaidOff &&
          controls.push(getCreditLimitControl(code));
      }
      {
        code === 'buy_now_pay_later' && controls.push(getCreditLimitControl(code));
      }
    }

    if (code === 'mortgage' && !isPrefilledAndPaidOff) {
      const Label = () => {
        if (isPartnered(relationshipStatus)) {
          return <span>How much is the total mortgage repayment?</span>;
        }
        return (
          <span>
            How much is <span className="font-medium">your</span> repayment?
          </span>
        );
      };
      controls.push(
        <AmountFrequency
          key={`amountControl_${code}_${index}`}
          register={props.register}
          label={<Label />}
          inputKey={`debts.${code}[${index}].repaymentAmount`}
          selectKey={`debts.${code}[${index}].frequency`}
          name="debt"
          options={frequencyOptionsWithYear}
          className="mb-4"
        />
      );
    }

    if (
      mortgageSharedControl.includes(code) &&
      ((item.recordOrigin === 'prefill' && isBooleanStringFalse(watch(`debts.${code}[${index}].paidOff`))) ||
        item.recordOrigin === 'manual')
    ) {
      controls.push(
        <ToggleGroup
          key={`isResidenceControl_${code}_${index}`}
          {...props.register(`debts.${code}[${index}].isResidence`, {
            onChange: () => {
              setValue(`debts.${code}[${index}].isJointMortgage`, '');
              setValue(`debts.${code}[${index}].rentalIncomeCoversMortgage`, '');
              setValue(`debts.${code}[${index}].jointMortgageAmount`, undefined);
              setValue(`debts.${code}[${index}].jointMortgageFrequency`, '');
              setValue(`debts.${code}[${index}].outstandingBalance`, undefined);
            },
          })}
          className="mb-4"
          label="Do you live in this property?"
          options={toggleStringOptions}
        />
      );
    }

    if (mortgageSharedControl.includes(code) && !isPrefilledAndPaidOff) {
      if (isPartnered(relationshipStatus)) {
        if (isBooleanStringTrue(watch(`debts.${code}[${index}].isResidence`))) {
          controls.push(
            <ToggleGroup
              key={`mortgageSharedControl_${code}_${index}`}
              {...props.register(`debts.${code}[${index}].isJointMortgage`, {
                onChange: (e) => {
                  if (isBooleanStringFalse(e.target.value)) {
                    setValue(`debts.${code}[${index}].jointMortgageAmount`, undefined);
                    setValue(`debts.${code}[${index}].jointMortgageFrequency`, '');
                  }
                },
              })}
              label={mortgageSharedExperimentLabels.isJointMortgageLabel}
              className="mb-4"
              options={toggleStringOptions}
            />
          );
        }
        if (isBooleanStringTrue(watch(`debts.${code}[${index}].isJointMortgage`))) {
          controls.push(
            <AmountFrequency
              key={`mortgageAmountControl_${code}_${index}`}
              register={props.register}
              label={mortgageSharedExperimentLabels.jointMortgageAmountLabel}
              inputKey={`debts.${code}[${index}].jointMortgageAmount`}
              selectKey={`debts.${code}[${index}].jointMortgageFrequency`}
              name="debt"
              options={frequencyOptionsWithYear}
              className="mb-4"
            />
          );
        }
      }

      if (isBooleanStringFalse(watch(`debts.${code}[${index}].isResidence`))) {
        controls.push(
          <ToggleGroup
            key={`mortgageSharedControl_${code}_${index}`}
            {...props.register(`debts.${code}[${index}].rentalIncomeCoversMortgage`)}
            label="Does the rental income you get for this property fully cover your mortgage?"
            className="mb-4"
            options={toggleStringOptions}
          />
        );
      }
    }

    if (outstandingBalancePaidControl.includes(code) && !isPrefilledAndPaidOff) {
      controls.push(
        <div key={`outstandingBalanceControl_${code}_${index}`}>
          <Label className="mb-2">
            Do you pay your{' '}
            <span className="font-medium">
              {isDebtCon(loanProduct?.name) ? 'outstanding balance' : 'current balance'}
            </span>{' '}
            at the end of every month?
          </Label>
          <ToggleGroup
            {...props.register(`debts.${code}[${index}].paysOutstandingBalance`)}
            options={toggleYesNoOptions}
            className="mb-4"
          />
        </div>
      );
    }

    if (outstandingBalanceValueControl.includes(code) && !isPrefilledAndPaidOff) {
      // if code is mortgage, then show outstanding balance when isJointMortgage is true or false or rentalIncomeCoversMortgage is true or false
      if (code === 'mortgage' && isPartnered(relationshipStatus)) {
        if (
          isValidBooleanString(watch(`debts.${code}[${index}].isJointMortgage`)) ||
          isValidBooleanString(watch(`debts.${code}[${index}].rentalIncomeCoversMortgage`))
        ) {
          controls.push(
            <AmountInput
              key={`outstandingBalanceValueControl_${code}_${index}`}
              {...props.register(`debts.${code}[${index}].outstandingBalance`)}
              label="How much is the outstanding balance?"
              type="text"
              className="mb-4"
            />
          );
        }
      } else {
        controls.push(
          <AmountInput
            key={`outstandingBalanceValueControl_${code}_${index}`}
            {...props.register(`debts.${code}[${index}].outstandingBalance`)}
            label="How much is the outstanding balance?"
            type="text"
            className="mb-4"
          />
        );
      }
    }

    if (
      isDebtCon(loanProduct?.name) &&
      code === 'credit_card' &&
      !isPrefilledAndPaidOff &&
      isBooleanStringFalse(watch(`debts.${code}[${index}]paysOutstandingBalance`))
    ) {
      controls.push(
        <AmountInput
          key={`outstandingBalanceValueControl_${code}_${index}`}
          {...props.register(`debts.${code}[${index}].outstandingBalance`)}
          label="How much is the outstanding balance?"
          type="text"
          className="mb-4"
        />
      );
    }

    if (
      (code !== 'mortgage' &&
        ((amountControl.includes(code) && !isPrefilledAndPaidOff && code !== 'credit_card') ||
          (isDebtCon(loanProduct?.name) &&
            code === 'credit_card' &&
            !isPrefilledAndPaidOff &&
            isBooleanStringFalse(watch(`debts.${code}[${index}]paysOutstandingBalance`))))) ||
      (isPersonalLoan(loanProduct?.name) && code === 'credit_card' && !isPrefilledAndPaidOff)
    ) {
      const Label = () => {
        return (
          <span>
            How much is <span className="font-medium">your</span> repayment?
          </span>
        );
      };
      controls.push(
        <AmountFrequency
          key={`amountControl_${code}_${index}`}
          register={props.register}
          label={<Label />}
          inputKey={`debts.${code}[${index}].repaymentAmount`}
          selectKey={`debts.${code}[${index}].frequency`}
          name="debt"
          options={frequencyOptionsWithYear}
          className="mb-4"
        />
      );
    }

    if (isDebtCon(loanProduct?.name) && !debtConsolidationControl.includes(code) && !isPrefilledAndPaidOff) {
      if (isBooleanStringTrue(watch(`debts.${code}[${index}].paysOutstandingBalance`))) {
        controls.push(
          <Alert variant="info" className="mb-4" key={`alert_not_available_${code}_${index}`}>
            <p className="text-sm">
              As you pay off the outstanding balance each month, this debt will not be available for consolidation.
            </p>
          </Alert>
        );
      } else if (
        (isBooleanStringFalse(watch(`debts.${code}[${index}]paysOutstandingBalance`)) && code === 'credit_card') ||
        interestRateControlWithoutCreditCard.includes(code)
      ) {
        controls.push(
          <PercentageInput
            key={`interestRateValueControl_${code}_${index}`}
            {...props.register(`debts.${code}[${index}].interestRate`)}
            label="What is the interest rate?"
            type="text"
            inputWidth="w-1/3 md:w-1/4"
            className="mb-4"
          />
        );
      }
    }

    if (index !== 0 && item.recordOrigin !== 'prefill') {
      controls.push(
        <span className="w-100 mx-4 mb-3 flex justify-end" key={`remove_button_${code}_${index}`}>
          <Button
            size="small"
            variant="text"
            onClick={() => props.removeItem(code, index)}
            className="inline-flex items-center"
          >
            Delete
          </Button>
        </span>
      );
    }

    return controls;
  }, [providerControl, code, index, props]);

  return (
    controls.length > 0 && (
      <div className="border-grey-3 border-1 mx-4 mb-3 rounded-lg	border border-inherit p-6 pb-0">{controls}</div>
    )
  );
};

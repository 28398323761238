import { AssetAndLiability } from '@prisma/client';
import { Prisma } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const debtPaymentApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getPostApprovalConsolidatedDebts: builder.query<(AssetAndLiability & { networthSourceName: string })[], void>({
      query: () => ({
        url: `/financial-profile/liabilities/post-approval-consolidated-debts`,
        method: 'GET',
      }),
    }),
    saveDisbursements: builder.mutation<void, Prisma.DisbursementCreateManyInput[]>({
      query: (data) => ({
        url: `/disbursement`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetPostApprovalConsolidatedDebtsQuery, useSaveDisbursementsMutation } = debtPaymentApi;

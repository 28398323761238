import Link from 'next/link';
import React from 'react';
import { ArrowCircleRightIcon, Button, Card, PuzzleIcon } from '@harmoney/ui-design-system';

type Props = {
  subtitle: string;
  description: string;
  ctaText: string;
  mainProcessData: { processId: string };
};

const ProductCard = ({ subtitle, description, ctaText, mainProcessData }: Props) => {
  return (
    <Card className="flex flex-col items-stretch md:px-16">
      <div className="mx-auto">
        <PuzzleIcon />
      </div>
      <div className="flex flex-col gap-1 py-6">
        <p className="m-0 text-center font-semibold">{subtitle}</p>
        <p className="text-center text-sm">{description}</p>
      </div>
      {mainProcessData?.processId && (
        <Link href={`journey?journeyId=${mainProcessData?.processId}`} passHref>
          <Button alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} isFullWidth variant="primary">
            {ctaText}
          </Button>
        </Link>
      )}
      <p className="pt-2 text-center text-xs">Won&rsquo;t affect your credit score</p>
    </Card>
  );
};

export default ProductCard;

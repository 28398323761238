import { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ValidationMessage } from '../ValidationMessage';

import { Input, InputProps } from './Input';

export interface ControlledInputProps extends InputProps {
  /**
   * Validation message
   */
  validationMessage?: string;
  /**
   * special case to hide the validation message in case not required
   */
  displayValidation?: boolean;
}

export const ControlledInput = forwardRef<HTMLDivElement, ControlledInputProps>(
  (
    { className, validationMessage, displayValidation = true, name, type = 'text', ...restProps }: ControlledInputProps,
    forwardRef: React.Ref<HTMLDivElement>
  ) => {
    const {
      control,
      getFieldState,
      formState,
      formState: { errors },
    } = useFormContext();

    const {
      field: { ref, ...inputProps },
    } = useController({
      name,
      control,
      defaultValue: '',
    });

    const { isDirty, isTouched, invalid } = getFieldState(name, formState);

    return (
      <div className={className} ref={forwardRef}>
        <Input
          isDirty={isDirty}
          isTouched={isTouched}
          isInvalid={invalid}
          type={type}
          ref={ref}
          {...restProps}
          {...inputProps}
        />
        {validationMessage && !errors[name] ? (
          <small className="text-grey-4 leading-lg block text-sm">{validationMessage}</small>
        ) : (
          displayValidation && <ValidationMessage name={name} />
        )}
      </div>
    );
  }
);

import { Html } from 'react-pdf-html';
import { Document, Page, StyleSheet } from '@react-pdf/renderer';
interface LoanAgreementGeneralTermsTemplateProps {
  withHtml?: boolean;
}

export const LoanAgreementGeneralTermsTemplate = ({ withHtml = true }: LoanAgreementGeneralTermsTemplateProps) => {
  const paddingValue = withHtml ? 0 : 20;
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 35,
      paddingHorizontal: 35,
    },
  });
  const templateStyle = `<style>
      body {
          font-family: 'Helvetica';
      }
      .logo {
          width: 150px;
          height: auto;
      }
      .address {
          width: 50%;
      }
      p {
          margin: 1px !important;
          font-size: 14px;
          text-align: justify;
          font-family: 'Helvetica';
      }
      .address p {
          text-align: right;
      }

      h1.bold {
          font-size: 19px;
      }
      .bold,
      b,
      .heading,
      h2 {
          font-family: 'Helvetica-Bold';
      }
      .text-center {
          text-align: center;
      }
      ol {
          list-style: auto;
          font-size: 14px;
          max-width: 95%;
          font-family: 'Helvetica-Bold';
          font-weight: normal;
          padding-left: ${paddingValue}px; 
      }

      ul {
          list-style: disc;
          max-width: 95%;
          padding-left: ${paddingValue}px; 
      }

      li ol {
          list-style: lower-alpha;
          font-weight: normal;
          max-width: 95%;
      }

      ol li ol ol {
          list-style: lower-roman;
          font-weight: normal;
          max-width: 95%;
      }
      @media screen and (max-width: 700px) {
          table {
              display: block;
          }
          .address,
          .logo {
              display: block;
              text-align: center;
              width: 100%;
          }
          ol,
          ul {
              max-width: 98%;
          }
      }
    </style>`;
  const template1 = `<html>${templateStyle}
  <body>
    <div>
      <table style="width: 100%">
        <tbody>
          <tr>
            <td class="logo">
              <img
                src="https://production-harmoney-cms-shared-assets.s3.ap-southeast-2.amazonaws.com/public/Logos/hmy-logo.png"
                alt="Company Logo"
              />
            </td>
            <td class="address">
              <p class="bold">Harmoney Australia Pty Ltd</p>
              <p>ABN 12 604 342 823</p>
              <p>Level 36, Governor Phillip Tower,</p>
              <p>1 Farrer Place,</p>
              <p>Sydney, NSW, 2000, Australia</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div class="text-center">
        <h1 class="bold">CREDIT GUIDE</h1>
      </div>
      <br />
      <h1 class="bold">Welcome!</h1>
      <p>
        Your credit provider is Harmoney Australia Pty Ltd (ABN 12 604 342 823)
        Australian Credit Licence number 474726. We are delighted that you have
        considered a Harmoney loan.
      </p>
      <br />
      <p>
        This credit guide sets out a few things we need to tell you about your
        rights and our dispute resolution procedures and responsible lending
        obligations. You can contact us on 1300 042 766 if you have any
        questions.
      </p>
      <br />
      <p class="heading">We will not make an unsuitable contract with you</p>
      <p>
        We will not enter into a contract with you if it would be unsuitable for
        you. A contract will be unsuitable for you if:
      </p>
      <ul>
        <li>
          <p>it does not meet your stated requirements and objectives; or</p>
        </li>
        <li>
          <p>
            it is unlikely that you will be able to meet your financial
            obligations underthe contract without suffering substantial
            hardship.
          </p>
        </li>
      </ul>
      <br />
      <p class="heading">We will make an assessment</p>
      <p>
        We must make an assessment that the contract is not unsuitable for you
        before we enter into a contract with you. To do this, we must make
        reasonable enquiries about your financial situation and your
        requirements and objectives. We must take reasonable steps to verify
        your financial situation.
      </p>
      <br />
      <p>
        If you ask us to, we will give you a copy of our assessment before we
        enter into a contract with you. You can also ask us for a copy of our
        assessment within 7 years of the date the contract is made. We will not
        charge you a fee to obtain a copy of our assessment.
      </p>
      <br />
      <p>
        If your request is made within 2 years, we will provide a copy of our
        assessment within 7 business days after we receive your request.
        Otherwise, we will provide a copy of our assessment within 21 business
        days after we receive your request.
      </p>
      <br />
      <p>
        We are not required to provide you a copy of our assessment if we do not
        enter into a contract with you.
      </p>
      <br />
      <p class="heading">Resolving disputes</p>
      <p>
        We are committed to listening to our customers. If you are not satisfied
        with any aspect of our service, or your contract, we genuinely want to
        hear from you. We will do everything we can to rectify your problem
        promptly. Our contact details are set out below.
      </p>
      <br />
      <p>
        If you are writing to us don’t forget to include your contract details,
        contact information and details about your concerns.
      </p>
      <br />
      <p>
        Our internal dispute resolution team will investigate the issues and
        work towards a resolution. Wherever possible, our aim is to resolve your
        concern within 14 business days.
      </p>
      <br />
      <p class="heading">Contacting us</p>
      <p>You can contact us at:</p>
      <br />
      <div style="padding-left: 52px">
      <p class="heading">Harmoney Australia Pty Ltd</p>
      <p>Level 36, Governor Phillip Tower,</p>
      <p>1 Farrer Place,</p>
      <p>Sydney NSW 2000</p>
        <p>Telephone: 1300 042 766</p>
        <p>
          Website:
          <a href="http://www.harmoney.com.au/" target="_blank"
            >www.harmoney.com.au</a
          >
        </p>
      </div>
      <br />
      <p>
        If we do not resolve your concern to your satisfaction, you can seek
        assistance from Australian Financial Complaints Authority (AFCA). AFCA
        is an impartial, independent and free external dispute resolution
        service that assists individual customers. They can be contacted on 1800
        931 678 or
        <a href="mailto:info@afca.org.au" target="_blank">info@afca.org.au.</a>
      </p>  
  </body>
</html>


`;
  const template2 = `<html>${templateStyle}
  <body><br />
      <h1 class="bold" style="text-align: center">INFORMATION STATEMENT</h1>
      <br />
      <p class="heading">
        Things you should know about your proposed credit contract
      </p>
      <p>
        This statement tells you about some of the rights and obligations of
        yourself and us, your credit provider. It does not state the terms and
        conditions of your contract.
      </p>
      <br />
      <p>
        If you have any concerns about your contract, contact us and, if you
        still have concerns, contact <span>the AFCA</span> scheme, or get legal
        advice.
      </p>
      <br />
      <p class="heading">The contract</p>
      <ol>
        <li>
          <p class="heading">
            How can I get details of my proposed credit contract?
          </p>
          <p>
            We must give you a pre-contractual statement containing certain
            information about your contract. The pre-contractual statement, and
            this document, must be given to you before —
          </p>
          <ul>
            <li>
              <p>your contract is entered into; or</p>
            </li>
            <li>
              <p>you make an offer to enter into the contract,</p>
            </li>
          </ul>
          <p>whichever happens first.</p>
        </li>
        <br />
        <li>
          <p class="heading">How can I get a copy of the final contract?</p>
          <p>
            If the contract document is to be signed by you and returned to us,
            you must be given a copy to keep. Also, we must give you a copy of
            the final contract within 14 days after it is made.
          </p>
          <p>
            This rule does not, however, apply if <span>we</span> have
            previously given you a copy of the contract document to keep.
          </p>
          <p>
            If you want another copy of your contract, write to us and ask for
            one. We may charge you a fee. We must give you a copy —
          </p>
          <ul>
            <li>
              <p>
                within 14 days of your written request if the original contract
                came into existence 1 year or less before your request; or
              </p>
            </li>
            <li>
              <p>otherwise, within 30 days of your written request.</p>
              <br />
            </li>
          </ul>
        </li>
        <li>
          <p class="heading">Can I terminate the contract?</p>
          <p>
            Yes. You can terminate the contract by writing to us so long as —
          </p>
          <ul>
            <li>
              <p>you have not obtained any credit under the contract; or</p>
            </li>
            <li>
              <p>
                a card or other means of obtaining credit given to you by us has
                not been used to acquire goods or services for which credit is
                to be provided under the contract.
              </p>
              <p>
                However, you will still have to pay any fees or charges incurred
                before you terminated the contract.
              </p>
              <br />
            </li>
          </ul>
        </li>
        <li>
          <p class="heading">Can I pay my credit contract out early?</p>
          <p>
            Yes. <span>P</span>ay us the amount required to pay out your credit
            contract on the day you wish to end your contract.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">How can I find out the pay out figure?</p>
          <p>
            You can write to us at any time and ask for a statement of the pay
            out figure as at any date you specify. You can also ask for details
            of how the amount is made up.
          </p>
          <p>
            We must give you the statement within 7 days after you give your
            request to us. You may be charged a fee for the statement.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">
            Will I pay less interest if I pay out my contract early?
          </p>
          <p>
            Yes. The interest you can be charged depends on the actual time
            money is owing. However, you may have to pay an early termination
            charge (if your contract permits us to charge one) and other fees.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">
            Can my contract be changed by my credit provider?
          </p>
          <p>Yes, but only if your contract says so.</p>
          <br />
        </li>
        <li>
          <p class="heading">
            Will I be told in advance if my credit provider is going to make a
            change in the contract?
          </p>
          <p>That depends on the type of change. For example —</p>
          <ul>
            <li>
              <p>
                you get at least same day notice for a change to an annual
                percentage rate. That notice may be a written notice to you or a
                notice published in a newspaper.
              </p>
            </li>
            <li>
              <p>you get 20 days advance written notice for —</p>
              <ul>
                <li>
                  <p>a change in the way in which interest is calculated; or</p>
                </li>
                <li>
                  <p>a change in credit fees and charges; or</p>
                </li>
                <li>
                  <p>any other changes by us;</p>
                  <p>
                    except where the change reduces what you have to pay or the
                    change happens automatically under the contract.
                  </p>
                  <br />
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b>Is there anything I can do if I think that my contract is
              unjust?</b>
            <p>
             Yes. You should first talk to us.
            <span>Discuss the matter and</span> see if <span>you</span> can come
            to some arrangement.
            </p>
          </p>
          <p>
            <span>If that is not successful, you may contact</span> the AFCA
            <span>scheme.</span> The AFCA scheme
            <span
              >is a free service established to provide you with an independent
              mechanism to resolve specific complaints.</span
            >
            The AFCA scheme <span>can be contacted on 1800 931 678</span>, at
            <a href="mailto:info@afca.org.au" target="_blank"
              >info@afca.org.au</a
            >
            ,
            <a href="http://www.afca.org.au/" target="_blank"
              >www.afca.org.au</a
            >
            or at GPO Box 3, Melbourne VIC 3001.
          </p>
          <p>
            Alternatively, you can go to court. You may wish to get legal
            advice, for example from your community legal centre or Legal Aid.
          </p>
          <br />
          <br />
          <p>
            You can also contact ASIC, the regulator, for information on 1300
            300 630 or through ASIC’s website at
            <a href="http://www.asic.gov.au/" target="_blank"
              >www.asic.gov.au.</a
            >
          </p>
          <br />
          <p class="heading">Insurance</p>
          <br />
        </li>
        <li>
          <p class="heading">Do I have to take out insurance?</p>
          <p>
            We can insist you take out or pay the cost of types of insurance
            specifically allowed by law. These are compulsory third party
            personal injury insurance, mortgage indemnity insurance or insurance
            over property covered by any mortgage. Otherwise, you can decide if
            you want to take out insurance or not. If you take out insurance, we
            cannot insist that you use any particular insurance company.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Will I get details of my insurance cover?</p>
          <p>
            Yes, if you have taken out insurance over mortgaged property or
            consumer credit insurance and the premium is financed by us. In that
            case the insurer must give you a copy of the policy within 14 days
            after the insurer has accepted the insurance proposal.
          </p>
          <p>
            Also, if you acquire an interest in any such insurance policy which
            is taken out by us then, within 14 days of that happening, we must
            ensure you have a written notice of the particulars of that
            insurance.
          </p>
          <p>
            You can always ask the insurer for details of your insurance
            contract. If you ask in writing, your insurer must give you a
            statement containing all the provisions of the contract.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">
            If the insurer does not accept my proposal, will I be told?
          </p>
          <p>
            Yes, if the insurance was to be financed by the credit contract. The
            insurer will inform you if the proposal is rejected.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">In that case, what happens to the premiums?</p>
          <p>
            We must give you a refund or credit unless the insurance is to be
            arranged with another insurer.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">
            What happens if my credit contract ends before any insurance
            contract over mortgaged property?
          </p>
          <p>
            You can end the insurance contract and get a proportionate rebate of
            any premium from the insurer.
          </p>
          <br />
          <p class="heading">Mortgages</p>
          <br />
        </li>
        <li>
          <p class="heading">
            If my contract says I have to give a mortgage, what does this mean?
          </p>
          <p>
            A mortgage means that you give us certain rights over any property
            you mortgage. If you default under your contract, you can lose that
            property and you might still owe money to us.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Should I get a copy of my mortgage?</p>
          <p>
            Yes. It can be part of your credit contract or, if it is a separate
            document, you will be given a copy of the mortgage within 14 days
            after your mortgage is entered into.
          </p>
          <p>
            However, you need not be given a copy if we have previously given
            you a copy of the mortgage document to keep.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">
            Is there anything that I am not allowed to do with the property I
            have mortgaged?
          </p>
          <p>
            The law says you can not assign or dispose of the property unless
            you have our, or the court’s, permission. You must also look after
            the property. Read the mortgage document as well. It will usually
            have other terms and conditions about what you can or can not do
            with the property.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">
            What can I do if I find that I can not afford my repayments and
            there is a mortgage over property?
          </p>
          <p>See the answers to questions 22 and 23. Otherwise you may—</p>
          <ul>
            <li>
              <p>
                <span
                  >if the mortgaged property is goods — give the property back
                  to us, together with a letter saying you want us to sell the
                  property for you;</span
                >
              </p>
            </li>
            <li>
              <p>sell the property, but only if we give permission first;</p>
              <p>OR</p>
            </li>
            <li>
              <p>
                give the property to someone who may then take over the
                repayments, but only if we give permission first.
              </p>
              <p>
                If we won’t give permission, you can contact the AFCA scheme for
                help.
              </p>
              <p>
                If you have a guarantor, talk to the guarantor who may be able
                to help you.
              </p>
              <p>
                You should understand that you may owe money to us even after
                the mortgaged property is sold.
              </p>
              <br />
            </li>
          </ul>
        </li>
        <li>
          <p class="heading">
            Can my credit provider take or sell the mortgaged property?
          </p>
          <p>
            Yes, if you have not carried out all of your obligations under your
            contract.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">
            If my credit provider writes asking me where the mortgaged goods
            are, do I have to say where they are?
          </p>
          <p>
            Yes. You have 7 days after receiving our request to tell us. If you
            do not have the goods you must give us all the information you have
            so they can be traced.
          </p>
        </li>
        <li>
          <p class="heading">
            When can my credit provider or its agent come into a residence to
            take possession of mortgaged goods?
          </p>
          <p>
            We can only do so if it has the court’s approval or the written
            consent of the occupier which is given after the occupier is
            informed in writing of the relevant section in the National Credit
            Code.
          </p>
          <br />
          <p class="heading">General</p>
          <br />
        </li>
        <li>
          <p class="heading">What do I do if I cannot make a repayment?</p>
          <p>
            Get in touch with us immediately. Discuss the matter and see if you
            can come to some arrangement. You can ask us to change your contract
            in a number of ways, <span>for</span> example —
          </p>
          <ul>
            <li>
              <p>to extend the term of your contract and reduce payments; or</p>
            </li>
            <li>
              <p>
                to extend the term of your contract and delay payments for a set
                time; or
              </p>
            </li>
            <li>
              <p>to delay payments for a set time.</p>
              <br />
            </li>
          </ul>
        </li>
        <li>
          <p>
            <b
              >What if my credit provider and I cannot agree on a suitable
              arrangement?</b
            >
            If we refuse your request to change the repayments, you can ask us
            to review this decision if you think it is wrong. If we still refuse
            your request you can complain to the <span>AFCA</span> scheme.
            Further details about this scheme are set out below in question 13.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Can my credit provider take action against me?</p>
          <p>
            Yes, if you are in default under your contract. But the law says
            that you cannot be unduly harassed or threatened for repayments. If
            you think you are being unduly harassed or threatened, contact
            <span>the AFCA</span> scheme or ASIC, or get legal advice.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Do I have any other rights and obligations?</p>
          <p>
          Yes. The law will give you other rights and obligations. You should also
          READ YOUR CONTRACT carefully.
         </p>
        </li>
      </ol>
      <br />
      <p>
        IF YOU HAVE ANY COMPLAINTS ABOUT YOUR CREDIT CONTRACT, OR WANT MORE
        INFORMATION, CONTACT US. YOU MUST ATTEMPT TO RESOLVE YOUR COMPLAINT WITH
        US BEFORE CONTACTING <span>THE AFCA</span> SCHEME. IF YOU HAVE A
        COMPLAINT WHICH REMAINS UNRESOLVED AFTER SPEAKING TO US YOU CAN CONTACT
        <span>THE AFCA</span> SCHEME OR GET LEGAL ADVICE.
      </p>
      <p>
        THE AFCA SCHEME IS A FREE SERVICE ESTABLISHED TO PROVIDE YOU WITH AN
        INDEPENDENT MECHANISM TO RESOLVE SPECIFIC COMPLAINTS. THE AFCA SCHEME
        CAN BE CONTACTED ON 1800 931 678, AT
        <a href="mailto:INFO@AFCA.ORG.AU" target="_blank">INFO@AFCA.ORG.AU</a>,
        <a href="http://WWW.AFCA.ORG.AU/" target="_blank">WWW.AFCA.ORG.AU</a> AND
        AT GPO BOX 3, MELBOURNE VIC 3001.<br />
      </p>
      <p class="heading">
        PLEASE KEEP THIS INFORMATION STATEMENT. YOU MAY WANT SOME INFORMATION
        FROM IT AT A LATER DATE.
      </p>
</body></html>`;

  const template3 = `<html>${templateStyle}
  <body><br/>
      <div style="text-align: center">
        <h1 class="bold">HARMONEY AUSTRALIA PTY LTD</h1>
        <p style="text-align: center">
          ABN 12 604 342 823 AUSTRALIAN CREDIT LICENCE NUMBER 474726
        </p>
        <p style="text-align: center" class="bold">
          Level 36, Governor Phillip Tower,
        </p>
        <p style="text-align: center" class="bold">
          1 Farrer Place, Sydney NSW, 2000.
        </p>
      </div>
      <br />
      <p class="heading">Version 2024-1</p>
      <br />
      <p class="heading">Loan Agreement General Terms</p>
      <p>
        This document does not contain all the terms of your Loan Agreement and,
        if your loan is regulated under the National Credit Code, all of the
        information we are required by law to give you before the Loan Agreement
        is made. Further terms and information are in the Details. Together, the
        Details and these General Terms form the Loan Agreement. The Loan
        Agreement only becomes binding on us when we have accepted your offer to
        borrow from us. If you subsequently grant us a Security Interest over
        property to secure your obligations under this Loan Agreement, by
        executing a Security Schedule, that Security Schedule, and the terms in
        this Loan Agreement relating to Security Interests and Secured Property,
        will together form a Security Agreement between you and us.
      </p>
      <p>
        The meaning of capitalised words in this document are set out in clause
        33 below.
      </p>
      <br />
      <ol>
        <li>
          <p class="heading">Parties</p>
          <p>The parties to this Loan Agreement are:</p>
          <ol>
            <li>
              <p>each Customer as shown in the Details; and</p>
            </li>
            <li>
              <p>Harmoney Australia Pty Ltd.</p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Effective date</p>
          <p>
            This Loan Agreement commences on the date on which we accept your
            offer to borrow the Loan Amount from us.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Multiple Customers</p>
          <p>
            When there is more than one Customer who is a party to this Loan
            Agreement:
          </p>
          <ol>
            <li>
              <p>all Customers are jointly liable to us;</p>
            </li>
            <li>
              <p>each Customer is individually liable to us;</p>
            </li>
            <li>
              <p>
                each of you gives us your consent to disclose your personal
                information to the other Customer, in accordance with the
                Privacy Act;
              </p>
            </li>
            <li>
              <p>
                we can accept instructions from either of you, without reference
                to the other Customer, in respect of both of your rights and
                obligations under this Loan Agreement;
              </p>
            </li>
            <li>
              <p>
                either of you can agree to amendments to this Loan Agreement on
                behalf of the other Customer; and
              </p>
            </li>
            <li>
              <p>
                disclosures and notifications that we are required to give you
                in connection with the Loan Agreement will be provided to each
                of you.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Loan</p>
          <p>
            You authorise us to establish a Loan Account in your name and to
            debit to it each part of the Loan Amount on the date we lend it to
            you, and any amount which you must pay under this Loan Agreement on
            or after the date it becomes due without
          </p>
          <p>
            first notifying you. We agree to advance the Loan Amount to you for
            the term specified in the Details, provided:
          </p>
          <ol>
            <li>
              <p>
                we have received all documents and information we require
                (including you having given us a Direct Debit Authority) in a
                form that is satisfactory to us;
              </p>
            </li>
            <li>
              <p>
                you have complied with any other conditions that we have
                reasonably requested that you satisfy;
              </p>
            </li>
            <li>
              <p>
                you are not in default under this Loan Agreement (for example,
                you have paid all relevant Fees and Charges, or have not given
                us any misleading financial or other information);
              </p>
            </li>
            <li>
              <p>
                nothing has happened since your Loan Application which has led
                or could lead to a worsening in your financial circumstances, or
                your ability to comply with any of your obligations under this
                Loan Agreement; and
              </p>
            </li>
            <li>
              <p>
                the Settlement Date occurs within 14 days of the Disclosure
                Date.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Loan payment</p>
          <p>
            On the Settlement Date, except to the extent that the Details
            indicate the Loan Amount is to be paid to others, we will pay the
            Loan Amount to you or as directed by you or your agent.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Loan term</p>
          <p>
            The term of the Loan Agreement begins on the Settlement Date and
            continues for the period of time specified in the Details.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Interest charges</p>
          <p>
            You must pay us interest charges for each day on the Balance Owing
            on your Loan Account for the end of that day. Interest charges are
            calculated daily by applying the daily rate to the Balance Owing on
            your Loan Account. The daily rate is the Secured Annual Percentage
            Rate or the Unsecured Annual Percentage Rate (as applicable, in
            accordance with clauses 8 and 9 below) divided by 365 (or 366 in a
            leap year). Interest charges accrue daily from and including the
            Settlement Date. They are debited to your Loan Account monthly
            (starting in the calendar month following the month that the
            Settlement Date falls) and on the last day of the term of the Loan
            Agreement. Interest charges for each month will be debited on the
            same day of the relevant month as the Settlement Date fell, or on
            the last Business Day of the relevant month if that is earlier or
            the relevant month has no corresponding day.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">If you have applied for an Unsecured Loan</p>
          <p>If you have applied for an Unsecured Loan:</p>
          <ol>
            <li>
              <p>
                the Unsecured Annual Percentage Rate will be applicable to the
                Loan Agreement for the entire Loan Term; and
              </p>
            </li>
            <li>
              <p>
                clauses in these General Terms relating to Secured Property and
                Security Interests are not applicable to your Loan Agreement.
                For the avoidance of doubt, clauses 9, 10, 11, 17.a.6,
                17.a.8, 18 and 19 are not applicable to your Loan Agreement.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">If you have applied for a Secured Loan</p>
          <p>If you have applied for a Secured Loan:</p>
          <ol>
            <li>
              <p>
                The Secured Annual Percentage Rate will be applicable to the
                Loan Agreement for the first 60 days of the Loan Term.
              </p>
            </li>
            <li>
              <p>
                If within 60 days of the Settlement Date you complete, execute
                and provide to us a Security Schedule in a form acceptable to
                us, granting us a Security Interest in Secured Property which is
                satisfactory to us, and any other information that we require to
                successfully register a first ranking Security Interest over the
                Secured Property on the PPSR, then subject to clause 9.e the
                Secured Annual Percentage Rate will continue to be applicable to
                the Loan Agreement for the remainder of the Loan Term;
              </p>
            </li>
            <li>
              <p>
                If you do not satisfy clause 9.b within 60 days of the
                Settlement Date, then on the 61 <span>st</span> day from the
                Settlement Date the Unsecured Annual Percentage Rate will become
                applicable to the Loan Agreement and, subject to clause 9.d, the
                Unsecured Annual Percentage Rate will be applicable to the Loan
                Agreement for the remainder of the Loan Term
              </p>
            </li>
            <li>
              <p>
                If you do not satisfy clause 9.b within 60 days of the
                Settlement Date, but at a later date you complete, execute and
                provide to us a Security Schedule in a form acceptable to us,
                granting us a Security Interest in Secured Property which is
                satisfactory to us, and any other information that we require to
                successfully register a first ranking Security Interest over the
                Secured Property on the PPSR, we may agree, in our discretion
                and subject to clause 9. e, to apply the Secured Annual
                Percentage Rate to the Loan Agreement from the date we receive
                the Security Schedule and any other information we require.
              </p>
            </li>
            <li>
              <p>
                If we are unable to register a Security Interest granted by you
                as a first ranking Security Interest over the Secured Property
                on the PPSR, notwithstanding having used our best endeavours to
                do so, the rate applicable to your Loan Agreement will change or
                revert to the Unsecured Annual Percentage Rate with
                retrospective effect from the date upon which you provided the
                Security Schedule and any other information we required, and any
                necessary adjustments will be made to the Balance Owing on your
                Loan Account.
              </p>
            </li>
            <li>
              <p>
                The Secured Annual Percentage Rate is available only if the Loan
                Amount is used in whole or in part to acquire the Secured
                Property, unless we otherwise agree in writing.
              </p>
            </li>
            <li>
              <p>
                If the rate applicable to your Loan Agreement changes from the
                Secured Annual Percentage Rate to the Unsecured Annual
                Percentage Rate, we may change the amount and/or number of your
                repayments.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Security interest</p>
          <ol>
            <li>
              <p>
                By executing the Loan Agreement and a Security Schedule, you
                acknowledge that the Security Schedule and the terms in this
                Loan Agreement relating to Security Interests and Secured
                Property will together form a Security Agreement between you and
                us and you grant a first ranking Security Interest to us over
                the following:
              </p>
              <ol>
                <li>
                  <p>the Secured Property;</p>
                </li>
                <li>
                  <p>
                    if the Secured Property is a Vehicle, any replacement
                    vehicle and additions or accessories added to the Vehicle,
                    any proceeds of the Vehicle (for example, any insurance
                    proceeds for loss of or damage to the Vehicle or other
                    Proceeds); and
                  </p>
                </li>
                <li>
                  <p>
                    any rebate relating to any insurance or warranty financed
                    under this Loan Agreement.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                The Security Interest secures payment of the Loan Amount and all
                other amounts payable, and performance of all your obligations,
                to us under this Loan Agreement.
              </p>
            </li>
            <li>
              <p>
                If we ask, you must promptly provide any information, sign any
                document or do anything that we reasonably require to perfect
                and protect the Security Interest and maintain its priority over
                all other security interests. You must do this in the time we
                specify.
              </p>
            </li>
            <li>
              <p>
                If any Secured Property may or must be described in the PPSR by
                serial number under the PPSA, you must provide us with a
                detailed description of the Secured Property, and the serial
                number and all other information that is required to be included
                in a financing statement in order to describe the Secured
                Property by serial number. This information must be provided to
                us in writing.
              </p>
            </li>
            <li>
              <p>
                We are entitled to receive all Proceeds of the Secured Property
                (like insurance proceeds, damages or compensations payments of
                that kind). You transfer your rights to those things to us and
                if you receive money you hold that money on trust for us.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Condition of Secured Property</p>
          <br />
          <ol>
            <li>
              <p class="heading">Maintenance</p>
              <p>You must:</p>
              <ol>
                <li>
                  <p>
                    keep the Secured Property in good repair and condition and
                    fully operational;
                  </p>
                </li>
                <li>
                  <p>
                    have the Secured Property serviced, repaired and maintained
                    by appropriately qualified people in accordance with good
                    practice, the manufacturer’s recommendations and any
                    warranty requirements;
                  </p>
                </li>
                <li>
                  <p>
                    keep the Secured Property safe so that it is secure against
                    theft, damage or destruction;
                  </p>
                </li>
                <li>
                  <p>
                    comply with all laws and the terms of any insurance policy
                    which in any way affect the Secured Property, including your
                    right to have or use the Secured Property (like
                    registration) and our Security Interest in it;
                  </p>
                </li>
                <li>
                  <p>
                    if we ask, promptly tell us where the Secured Property is
                    located and ordinarily kept and any other information you
                    have that we ask for in connection with the Secured
                    Property;
                  </p>
                </li>
                <li>
                  <p>
                    replace any part of the Secured Property which becomes worn
                    out, non-operational, destroyed, lost or removed, using only
                    parts of at least the same quality and specification as the
                    replaced item in its new condition; and
                  </p>
                </li>
                <li>
                  <p>
                    pay for all repairs and services to the Secured Property
                    promptly
                  </p>
                  <p>and by their due date.</p>
                </li>
              </ol>
            </li>
            <li>
              <p class="heading">Inspection</p>
              <ol>
                <li>
                  <p>
                    You must ensure that we can fully inspect the Secured
                    Property and any records relating to it (for example,
                    service records).
                  </p>
                </li>
                <li>
                  <p>
                    We may inspect the Secured Property and any records relating
                    to it at any reasonable time or ask you to produce the
                    Secured Property for inspection, to assess the value of the
                    Secured Property and to check that you are complying with
                    this Loan Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    We will try to give you at least 24 hours’ prior written or
                    verbal notice of an inspection (except in an emergency or
                    after a Default has occurred).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p class="heading">
                Restrictions on what you can do with the Secured Property
              </p>
              <ol>
                <li>
                  <p>
                    Unless you first get our written consent of the law says
                    otherwise, you must not:
                  </p>
                  <ol>
                    <li>
                      <p>
                        sell, transfer, lend, assign, lease, licence or
                        otherwise part with possession or control of the Secured
                        Property;
                      </p>
                    </li>
                    <li>
                      <p>
                        create or allow to exist a Security Interest in the
                        Secured Property (except Security Interests which arise
                        solely by operation of law, which you discharge promptly
                        after they arise);
                      </p>
                    </li>
                    <li>
                      <p>
                        use the Secured Property for any purpose that it is not
                        designed for, or that is not in accordance with the
                        manufacturer’s instructions;
                      </p>
                    </li>
                    <li>
                      <p>
                        use the Secured Property in a dangerous or negligent
                        way, or where the risk of damage to the Secured Property
                        or other property or people is higher than would be
                        expected from normal use;
                      </p>
                    </li>
                    <li>
                      <p>
                        modify the Secured Property in a way that may void any
                        warranty for the Secured Property or cause the Secured
                        Property to be unroadworthy, illegal or not fully
                        covered by insurance;
                      </p>
                    </li>
                    <li>
                      <p>
                        fit something which will become part of the Secured
                        Property (for example, an accessory) where that thing is
                        subject to any Security Interest in favour of someone
                        else;
                      </p>
                    </li>
                    <li>
                      <p>
                        remove or attempt to remove the Secured Property from
                        Australia; or
                      </p>
                    </li>
                    <li>
                      <p>
                        if the Secured Property is a Vehicle, use the Vehicle to
                        provide commercial transport services, such as taxi,
                        hire-car or ride-sharing purposes.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p class="heading">Insurance</p>
              <ol>
                <li>
                  <p>
                    You must keep the Secured Property covered by comprehensive
                    insurance at all times:
                  </p>
                  <ol>
                    <li>
                      <p>
                        for the full insurable value of the Secured Property or
                        an agreed value of $10,000 (whichever is higher);
                      </p>
                    </li>
                    <li>
                      <p>
                        with a reputable insurer, and on terms that are
                        acceptable to us;
                      </p>
                    </li>
                    <li>
                      <p>
                        with “Harmoney Australia Pty Ltd” as the loss payee and
                        our interest as secured party noted on the policy; and
                      </p>
                    </li>
                    <li>
                      <p>
                        so that the proceeds of any insurance claim are to be
                        paid to us.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    If we enforce our Security Interest or any other rights we
                    have under this Loan Agreement, we may cancel any insurance
                    and receive any rebate or refund of premium and credit it
                    against the Balance Owing on your Loan Account.
                  </p>
                </li>
                <li>
                  <p>You must:</p>
                  <ol>
                    <li>
                      <p>make full disclosure to each insurer;</p>
                    </li>
                    <li>
                      <p>
                        not do anything which might reduce or prejudice any
                        insurance;
                      </p>
                    </li>
                    <li>
                      <p>
                        not create or allow to exist any Security Interest on
                        the insurance or the proceeds of the insurance (other
                        than to us);
                      </p>
                    </li>
                    <li>
                      <p>
                        ensure that all insurance money in respect of the
                        Secured Property is paid to us or as we direct; and
                      </p>
                    </li>
                    <li>
                      <p>
                        promptly give us any information we ask for about
                        insurance (such as proof that you have paid your
                        premiums or that the cover is current).
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    You must not insure the Secured Property other than as
                    agreed in this clause 11.d. If you do, you will be taken to
                    do so on trust for us (so that we have the benefit of that
                    insurance).
                  </p>
                </li>
                <li>
                  <p>
                    We may make, negotiate, and settle any insurance claims
                    concerning the Secured Property if you fail to do so. You
                    must help us do this, as we ask, and accept any settlement
                    we agree with the insurance company. We may decide whether
                    to use insurance proceeds (except for insurance proceeds
                    payable to compensate liability to others):
                  </p>
                  <ol>
                    <li>
                      <p>
                        to repair or replace the Secured Property, discharge a
                        liability, or make good a loss; or
                      </p>
                    </li>
                    <li>
                      <p>
                        towards payment of the Balance Owing on your Loan
                        Account (whether or not the Balance Owing on your Loan
                        Account would otherwise have become due), or partly to
                        each.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    If you make a claim that the insurer refuses, then you must
                    tell us. We can require you to give us your rights to take
                    further action against that insurer on your claim.
                  </p>
                </li>
                <li>
                  <p>
                    If you do not take out and keep current all required
                    insurance, or if you do not give us evidence of this on
                    request, we may take out the insurance and debit the cost to
                    your account, but we have no duty to do so.
                  </p>
                  <br />
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Repayments</p>
          <p>
            You must repay the Loan Amount, and pay us interest charges and any
            other amounts payable to us under this Loan Agreement by making the
            repayments set out in the Details and, in any case, on or by the
            last day of the term of the Loan Agreement as specified in clause 6.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Repayment provisions</p>
          <ol>
            <li>
              <p>You must make each repayment under this Loan Agreement:</p>
              <ol>
                <li>
                  <p>
                    in the amount and in the frequency set out in the Details,
                    unless we agree otherwise in writing; and
                  </p>
                </li>
                <li>
                  <p>
                    in cleared funds and in full, without any set-off,
                    deduction, counterclaim or withholding, except as may be
                    required by law (and we will not treat any repayment as made
                    to the Loan Account until it is cleared).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                Unless otherwise agreed by us in writing, repayments must be
                made by direct debit from the Nominated Account by no later than
                the day they are due.
              </p>
            </li>
            <li>
              <p>
                You authorise us to establish a direct debit against the 
                Nominated Account on the terms set out in the Direct Debit 
                Authority in relation to any amount payable by you to us under 
                this Loan Agreement. We may vary the amount or timing of direct 
                debits against your Nominated Account as agreed with, or instructed by, 
                you from time to time. We will have the right to adjust debits to 
                your Loan Account for the purposes of correcting any errors to 
                any amount debited from your Nominated Account.
              </p>
            </li>
            <li>
              <p>
                You must not cancel any Direct Debit Authority you give us or
                close the Nominated Account unless you first notify us of
                another direct debit authorisation acceptable to us.
              </p>
            </li>
            <li>
              <p>
                You must ensure there is enough money in the Nominated Account
                to meet each debit.
              </p>
            </li>
            <li>
              <p>
                We may assign any date we consider appropriate to a debit or a
                credit to the Loan Account. However, for a debit that date will
                not be earlier than the date on which the debit occurs. We will
                credit payments to the Loan Account as soon as practicable after
                we actually receive them. This is not necessarily the same day
                that you pay.
              </p>
            </li>
            <li>
              <p>
                We may subsequently adjust debits and credits to the Balance
                Owing on your Loan Account so as to accurately reflect your, or
                our, legal obligations (for example, because of an error, or
                because a debit to your Nominated Account is reversed). If we do
                this, we may make consequential changes (including to interest
                charges).
              </p>
            </li>
            <li>
              <p>
                Unless we have agreed with you otherwise, we may use any repayment 
                we receive (or any part of it) in connection with this Loan Agreement 
                or any of the other loan agreement(s) you have entered into with us to 
                reduce the Balance Owing on this Loan Account or the balance owing on 
                any of the other loan account(s) you have with us, in any order we 
                choose, acting reasonably in the circumstances.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Other costs and charges</p>
          <ol>
            <li>
              <p>
                You must pay us all applicable Fees and Charges, as set out in
                the Details at the time, and in the circumstances set out in the
                Details.
              </p>
            </li>
            <li>
              <p>
                You must pay us an amount equal to any government charges and
                duties on receipts and withdrawals under this Loan Agreement (if
                any) calculated in accordance with the relevant legislation.
              </p>
            </li>
            <li>
              <p>
                When we ask, you must pay us any reasonable enforcement expenses
                we reasonably incur in enforcing this Loan Agreement.
              </p>
            </li>
            <li>
              <p>
                Words or expressions used in this clause 14(d) that are defined
                in the GST
              </p>
              <p>
                Act have the same meaning given to them in the GST Act. If we
                are liable to pay GST or any similar tax on a supply made in
                connection with this Loan Agreement, you must pay us an
                additional amount equal to the consideration payable for the
                supply multiplied by the prevailing GST rate. This clause does
                not merge on completion and will continue to apply after
                expiration or termination of this Loan Agreement.
              </p>
            </li>
            <li>
              <p>
                You authorise us to debit any of the above amounts to your Loan
                Account.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Early repayment option</p>
          <p>You can:</p>
          <ol>
            <li>
              <p>
                repay the Total Amount Owing at any time before it is due. We
                will, on request, provide you with written confirmation of a
                payout figure for this purpose. There is no fee or charge for
                paying the Total Amount Owing before it is due;
              </p>
            </li>
            <li>
              <p>
                repay any portion of the Balance Owing on your Loan Account
                before it is due. If you repay only part of the Loan Amount
                early, your repayment amount does not change (but it may mean
                that you repay the Loan Amount more quickly). You must continue
                to make the repayments in the amounts and at the times set out
                in the Details, until the Total Amount Owing is paid; and/or
              </p>
            </li>
            <li>
              <p>
                if you tell us beforehand, pay a scheduled repayment in two or
                more instalments, provided that those instalments result in the
                repayment being made earlier than the date stipulated in the
                Details (for example, if a monthly repayment is due on the last
                day of the month, two fortnightly instalments paid in that
                month, but before the last day of the month). We may be able to
                help you facilitate instalment payments if you give us notice of
                the frequency, dates, and amounts of the instalments.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Collection and enforcement</p>
          <p>In carrying out collections services we will:</p>
          <ol>
            <li>
              <p>
                monitor all repayment obligations under this Loan Agreement;
              </p>
            </li>
            <li>
              <p>
                take appropriate steps to contact you for repayment arrears; and
              </p>
            </li>
            <li>
              <p>
                take appropriate recovery action if there is a repayment
                default, including recovery from you of our reasonable
                enforcement expenses.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Default</p>
          <ol>
            <li>
              <p>
                You will be in Default of your obligations under this Loan
                Agreement if:
              </p>
              <ol>
                <li>
                  <p>
                    you do not pay any money due under this Loan Agreement on
                    the due date (this includes if our Direct Debit Authority is
                    subsequently reversed by the paying institution);
                  </p>
                </li>
                <li>
                  <p>
                    you do not perform any of your other obligations under this
                    Loan Agreement fully and on time;
                  </p>
                </li>
                <li>
                  <p>
                    anything you warrant to us is not true when you make that
                    warranty or repeat it;
                  </p>
                </li>
                <li>
                  <p>
                    you cancel our Direct Debit Authority and, at or before the
                    cancellation, you do not give us a replacement Direct Debit
                    Authority;
                  </p>
                </li>
                <li>
                  <p>
                    you become insolvent or steps are taken to have you declared
                    bankrupt;
                  </p>
                </li>
                <li>
                  <p>
                    you dispose of the Secured Property, or intend to dispose of
                    the Secured Property, without our permission;
                  </p>
                </li>
                <li>
                  <p>
                    you do something you agree not to do, or you do not do
                    something you agree to do;
                  </p>
                </li>
                <li>
                  <p>
                    we believe on reasonable grounds that urgent action is
                    necessary to protect the Secured Property; or
                  </p>
                </li>
                <li>
                  <p>
                    you have provided us with incorrect, misleading or
                    fraudulent information (including in connection with your
                    LoanApplication).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                If you are in Default, we may send you a Notice of Default
                stating:
              </p>
              <ol>
                <li>
                  <p>that you are in Default;</p>
                </li>
                <li>
                  <p>what you need to do to correct the Default; and</p>
                </li>
                <li>
                  <p>
                    how much time you have to correct the Default, as decided by
                    us or required by law (a Grace Period).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                If you do not, or cannot, correct the Default by the date stated
                in the Notice of Default or as required by law (or if you are in
                Default again for a similar reason at such date) then, following
                such date and without further notice to you:
              </p>
              <ol>
                <li>
                  <p>
                    the Total Amount Owing will become immediately due for
                    payment (to the extent that it is not already due for
                    payment);
                  </p>
                </li>
                <li>
                  <p>
                    we may enforce our Security Interest in the Secured Property
                    in accordance with clause 18;
                  </p>
                </li>
                <li>
                  <p>
                    exercise any of our Powers under this Loan Agreement or the
                    law;
                  </p>
                </li>
                <li>
                  <p>
                    terminate any obligation we would otherwise have to you
                    under this Loan Agreement.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                If a scheduled repayment or any other amount due under this Loan
                Agreement is dishonoured on the due date, including because you
                do not have sufficient funds in your Nominated Account, then for
                each such dishonor event, a dishonour fee of $15.00 will become
                immediately payable. We will give you notice before debiting a
                dishonor fee to your Loan Account.
              </p>
            </li>
            <li>
              <p>
                If the National Credit Code applies to this Loan Agreement, we
                must give you written notice in accordance with the National
                Credit Code before we take any enforcement action in relation to
                this Loan Agreement.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">We can enforce our Security Interest</p>
          <ol>
            <li>
              <p>
                Unless the law says otherwise, if a Default occurs we can
                enforce our Security Interest under clause 17.c.2 by:
              </p>
              <ol>
                <li>
                  <p>
                    exercising all Powers given to us under this Loan Agreement
                    and under law. This includes taking or attempting to take
                    possession of the Secured Property;
                  </p>
                </li>
                <li>
                  <p>
                    dealing with the Secured Property as if we were the owner
                    and you had no interest in it; and
                  </p>
                </li>
                <li>
                  <p>
                    selling the Secured Property by any means. We do not have to
                  </p>
                  <p>
                    put the Secured Property for sale by public auction before
                    selling it any other way. We may sell it on any terms we
                    choose. Any sale will eliminate any interest you may have in
                    it.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                If we obtain any necessary consent or court order, we may enter
                any premises where we believe the Secured Property is, even if
                you do not control those premises. You authorise us to do so and
                promise to pay us the cost of any liability which arises as a
                result.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">PPSA</p>
          <ol>
            <li>
              <p>To the extent the law permits:</p>
              <ol>
                <li>
                  <p>
                    you and we agree to contract out of each section that
                    sections 115(1) and 115(7) of the PPSA permits us to
                    contract out of, other than sections 117, 118, 134(1) and
                    135 of the PPSA;
                  </p>
                </li>
                <li>
                  <p>
                    if the PPSA is amended after the date of this Loan Agreement
                    to permit you and us to agree to not comply with or to
                    exclude other provisions of the PPSA, we may notify you that
                    any of these provisions are excluded, or that we need not
                    comply with any of these provisions, as notified to you by
                    us; and
                  </p>
                </li>
                <li>
                  <p>
                    you and we agree that neither of us will disclose any
                    information of the kind referred to in section 275(1) of the
                    PPSA unless section 275(7) of the PPSA applies, in which
                    case we may disclose such information. This means that
                    neither of us may disclose nor confirm:
                  </p>
                  <ol>
                    <li>
                      <p>a copy of the Loan Agreement;</p>
                    </li>
                    <li>
                      <p>
                        details of the amount or terms of the Loan Agreement; or
                      </p>
                    </li>
                    <li>
                      <p>details of the Secured Property, unless:</p>
                      <ul>
                        <li>
                          <p>the other person has consented in writing;</p>
                        </li>
                        <li>
                          <p>the law requires or allows disclosure; or</p>
                        </li>
                        <li>
                          <p>you are in Default.</p>
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    you agree not to exercise your rights to make any request of
                    us under section 275 of the PPSA, to authorise the
                    disclosure of any information under that section or to waive
                    any duty of confidence that would otherwise permit
                    non-disclosure under that section.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                If we exercise a Power in connection with this Loan Agreement,
                that exercise is taken not to be an exercise of a Power under
                the PPSA unless we state otherwise at the time of exercise.
                However, this clause does not apply to a Power which can only be
                exercised under the PPSA.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">No Agency</p>
          <p>
            No agent or representative who promotes our services is authorised
            toact as our agent to agree or negotiate or change any term of this
            Loan Agreement or to make any warranties or representations about
            it, oral or otherwise.
          </p>
        </li>
        <li>
         <p class="heading">Authority to complete and amend errors</p>
          <p>
            You authorise us to complete any blanks (such as acknowledgement) or
            correct any errors in this Loan Agreement (including inserting dates
            and Loan references) that are clearly incorrect and the amendment of
            which would not have a detrimental effect on your liabilities or
            materially affect your obligations under this Loan Agreement.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Harmoney’s liability</p>
          <p>
            We are not liable to you or any other person for any consequential
            loss arising out of or in connection with this Loan Agreement. We
            are not liable for loss caused by the exercise, attempted exercise,
            failure to exercise, or delay in exercising, a right or remedy.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Changes to terms</p>
          <ol>
            <li>
              <p>
                Subject to any clause to the contrary in this Loan Agreement, or
                any applicable law, we may change any terms of this Loan
                Agreement without your consent for one or more of the following
                reasons:
              </p>
              <ol>
                <li>
                  <p>
                    to reduce your obligations under this Loan Agreement
                    (including by reducing the Secured Annual Percentage Rate or
                    Unsecured Annual Percentage Rate, or reducing any fee
                    payable in relation to your Loan);
                  </p>
                </li>
                <li>
                  <p>
                    to change the applicable interest rate as set out in clause
                    9;
                  </p>
                </li>
                <li>
                  <p>to change your repayments as set out in clause 9;</p>
                </li>
                <li>
                  <p>
                    to comply with any change or anticipated changes in any
                    relevant law, code of practice or guidance;
                  </p>
                </li>
                <li>
                  <p>
                    to reflect any decision of a court, ombudsman or regulator;
                  </p>
                </li>
                <li>
                  <p>
                    to reflect a change in our systems or procedures, including
                    for security reasons;
                  </p>
                </li>
                <li>
                  <p>
                    as a result of changed circumstances (including by adding
                    benefits or new features to your Loan and/or other
                    products);
                  </p>
                </li>
                <li>
                  <p>
                    to respond proportionately to changes in the cost of
                    providing your Loan; or
                  </p>
                </li>
                <li>
                  <p>to make the terms of the Loan Agreement clearer.</p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                We will give you not less than 20 days’ notice before making any
                changes to this Loan Agreement. However, where the change
                reduces what you have to pay or the change happens automatically
                under the contract, we will give you notice of the change at the
                same time we send the next statement of account to you after the
                change takes effect.
              </p>
            </li>
            <li>
              <p>
                Any changes to this Agreement to which the National Credit Code
                applies, will be made in accordance with the National Credit
                Code.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Changes to interest</p>
          <p>
            Except for a change in accordance with clauses 9 or 23.a.1 we will
            not change the Secured Annual Percentage Rate and/or the Unsecured
            Annual Percentage Rate.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Notices</p>
          <ol>
            <li>
              <p>You must provide all notices to us in writing by email to</p>
              <p>
                <a href="mailto:customerservice@harmoney.com.au"
                  >customerservice@harmoney.com.au.</a
                >
              </p>
            </li>
            <li>
              <p>
                We may provide you with communications in relation to this Loan
                Agreement and the Loan, including notices (excluding notices
                that cannot be sent electronically) and disclosures, by
                electronic means (including by email). Every electronic
                communication to you will be treated as provided to you on the
                day on which it is sent to you.
              </p>
            </li>
            <li>
              <p>
                In respect of any disclosure statements to be provided to you
                under the NCCPA:
              </p>
              <ol>
                <li>
                  <p>
                    you nominate the email address specified in the Loan
                    Application (or such other email address as you may notify
                    to us) as the information system to which any NCCPA
                    disclosure statements or other communications may be sent.
                    Any such disclosure statement may be provided to you by
                    sending you an electronic communication that allows the
                    disclosure statement to be accessed from our Website or by
                    means of the Internet; and
                  </p>
                </li>
                <li>
                  <p>
                    without limiting clause 25.c.1, you further agree that any
                    disclosure statement that the NCCPA requires to be provided
                    to you may be disclosed to you by notifying you that you are
                    able to access the relevant information on the Website.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                By entering into this Loan Agreement, you consent to us giving
                you documents by means of electronic communication (excluding
                notices that cannot be given electronically) and acknowledge
                that by reason of that consent:
              </p>
              <ol>
                <li>
                  <p>paper documents may not be given; and</p>
                </li>
                <li>
                  <p>
                    your electronic communication address (that is, your email
                    inbox) must be regularly checked for communications from us.
                  </p>
                  <p>
                    You may withdraw your consent to receiving documents by
                    electronic communication at any time.
                  </p>
                  <br />
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Statement of Account</p>
          <p>
            We will provide you with a Statement of Account every six months
            until there is no balance owing.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Privacy (including consents)</p>
          <p>
            By applying for a Loan, you agree to provide us certain consents in
            relation to your personal information. This clause sets out:
          </p>
          <ol>
            <li>
              <p>
                important information about your consents and how we collect,
                use, disclose and manage your personal information, including
                your credit information and credit eligibility information. For
                more details, please view our “Privacy Policy and Credit
                Reporting Policy” by visiting our Website and clicking on
                ‘Privacy Policy’.
              </p>
            </li>
            <li>
              <p>
                We are required to comply with the Privacy Act when we collect,
                use, disclose and manage your personal information.
              </p>
            </li>
            <li>
              <p>
                We usually collect your personal information for the purposes
                set out below and, to the extent permitted by law, you hereby
                consent to us using and disclosing your personal information for
                these purposes:
              </p>
              <ol>
                <li>
                  <p>
                    assessing and processing existing or future applications for
                    consumer credit and, where applicable, insurance products,
                    managing your Loan Account or other services, responding to
                    your questions, and performing our obligations in relation
                    to credit and insurance products provided to you;
                  </p>
                </li>
                <li>
                  <p>
                    either us, any relevant insurer, or any other service
                    provider appointed by us, contacting you about special
                    offers or promotions;
                  </p>
                </li>
                <li>
                  <p>
                    protecting us and our assets (including against fraud) and
                    selling our assets (including by assigning any debts);
                  </p>
                </li>
                <li>
                  <p>
                    enforcing our rights (including undertaking debt collection)
                    in relation to credit provided to you;
                  </p>
                </li>
                <li>
                  <p>
                    managing, changing and improving our systems and processes;
                    and
                  </p>
                </li>
                <li>
                  <p>
                    complying with laws. We may be required under various
                    Australian laws to collect your personal information, or to
                    comply with other obligations under those laws. These laws
                    include the Anti-Money Laundering and Counter-Terrorism
                    Financing Act and other anti-money laundering legislation
                    (for example, for identity verification), the NCCPA (for
                    example, for responsible lending), the Financial Sector
                    (Collection of Data) Act, the Corporations Act (Cth) and
                    other regulatory legislation (for example, requiring us to
                    maintain client and transaction records, to provide
                    information relating to your Loan to government authorities
                    such as APRA, and to make reports and provide other
                    information to regulators such as ASIC), and the Taxation
                    Administration Act, the Income Tax Assessment Act and other
                    taxation laws (for example, to comply with information
                    requests issued by the Commissioner of Taxation).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                We will usually collect your personal information directly from
                you, but we may sometimes collect personal information about you
                from third parties for the purposes described above where it is
                unreasonable or impracticable to collect it directly from you.
                These third parties include other credit providers; insurers
                (such as insurers who provide insurance in relation to your
                credit where applicable); any of your employers, former
                employers, referees, banks, landlords, accountants, lawyers and
                financial advisers; service providers to us (including debt
                collection agencies, introducers, private investigators,
                professional advisers); professional organisations; internet
                sources, public and subscriber only database; and government
                authorities. The circumstances in which we may seek your
                personal information from third parties would include, for
                example, where we need information from a third party to assist
                us to deal with any application or request made by you (such as
                to verify information you have provided or to assess your
                circumstances) or to assist us to locate you or communicate with
                you.
              </p>
            </li>
            <li>
              <p>
                If you do not provide us the personal information that we
                require, we may not be able to approve your Loan Application or
                a related product, or deal with future requests or queries from
                you in connection with credit we provide.
              </p>
            </li>
            <li>
              <p>
                You agree that we can disclose your personal information,
                including your credit information and credit eligibility
                information where permitted under
              </p>
              <p>
                the Privacy Act, for the purposes described above to any of our
                related bodies corporate; our assignees or potential assignees;
                investors; third party service providers; any other supplier
                appointed by us; credit reporting bodies or any business
                providing information about commercial credit worthiness; other
                credit providers; insurers (such as insurers who provide
                insurance in relation to your credit); your assignees or
                proposed assignees; debt collection agencies; direct debit
                companies that manage your scheduled repayments and one off
                payments; our banks and financial advisers; our lawyers,
                accountants and other professional advisers; any suppliers or
                contractors to us who may need to have access to your personal
                information for the purpose of providing services to us or you
                (including, without limitation, valuers, physical and electronic
                file storage suppliers, receivables management suppliers and
                data warehouses); and any person specifically authorised by you
                in writing.
              </p>
            </li>
            <li>
              <p>
                We may disclose your personal information, including your credit
                information or credit eligibility information, to recipients
                located overseas who may not have an Australian link. The
                countries in which overseas recipients are likely to be located
                currently include India, Fiji, Canada, the United States, New
                Zealand and the Philippines. (For an updated list of countries,
                please see our Privacy Policy and Credit Reporting Policy on our
                Website.) You acknowledge that by consenting to us disclosing
                your personal information to overseas recipients, Australian
                Privacy Principle (“APP”) 8.1 will not apply to the disclosure
                (which means that we will not be obliged under the Privacy Act
                to take reasonable steps to ensure that an overseas recipient
                does not breach the APPs; and we may not be liable under the
                Privacy Act if the recipient does not act consistently with the
                APPs, does not have Privacy Laws similar to Australia, may not
                be subject to any privacy obligations or to any principles
                similar to the APPs, or is subject to a foreign law that could
                compel the disclosure of personal information to a third party
                such as an overseas authority). By applying for a Loan, you are
                consenting to disclosure of your Personal Information to
                overseas recipients and agree that you will not be able to seek
                redress either under the Privacy Act or in the overseas
                jurisdiction.
              </p>
            </li>
            <li>
              <p class="heading">
                Important information about credit reporting:
              </p>
              <p>
                Our Website (select ‘Privacy Policy’) contains important
                information about credit reporting, including the credit
                reporting bodies that we deal with, credit information that we
                may give them about you (such as about defaults and serious
                credit infringements) and details regarding how those credit
                reporting bodies use and disclose that information to credit
                providers and their policies about managing the information. You
                have important rights regarding access, correction and
                complaints relating to your credit reporting information, as
                well as certain rights to prevent its use for direct marketing
                or where you have been a victim of fraud. You will also find
                details about these rights on the same page. You can also ask us
                to provide you with a copy of this important information.
              </p>
            </li>
            <li>
              <p>
                You agree that we can disclose your name, residential address
                and date of birth to a credit reporting body so that the credit
                reporting body can provide an assessment to us of whether the
                information provided by you
              </p>
              <p>
                matches (in whole or in part) the information in the credit
                reporting body’s possession or control (which may include
                personal information held by the credit reporting body about you
                or other individuals). This will be done for the purpose of
                verifying your identity as required under Australia’s anti-money
                laundering and counter-terrorism laws where applicable. If you
                would prefer us to use another form of verification, such as
                your passport or driver’s licence, you must notify us and
                provide us with any information that we request.
              </p>
            </li>
            <li>
              <p>Marketing products and services to you:</p>
              <p>
                You agree to us using and disclosing your personal information
                (including your telephone number, regardless of whether it is
                listed on the Do Not Call Register and your email or other
                electronic addresses) to provide you with information about our
                other products and services and the products and services
                offered by our dealers, insurers (such as insurers who provide
                credit- related insurance), our related companies or suppliers.
                This includes, without limitation, communicating with you via
                emails, text messages or other electronic messages (without an
                unsubscribe facility where permitted by law). This agreement
                operates indefinitely. However, you can tell us that you no
                longer wish us to use or disclose your personal information for
                these purposes by contacting us on 1800 042 766.
              </p>
              <p>Accessing and correcting your personal information</p>
            </li>
            <li>
              <p>
                You have rights to request access to and correction of personal
                information that we hold about you (including credit information
                and credit eligibility information). For details about how you
                can make such request please see our Privacy Policy and Credit
                Reporting Policy.
              </p>
            </li>
            <li>
              <p>
                We always try to deal with your personal information
                consistently with our privacy obligations and we are committed
                to resolving any issues that you may wish to raise. Our Privacy
                and Credit Reporting Policy contains details about how you can
                make a complaint if you think there has been a breach of the
                Privacy Act or the Credit Reporting Privacy Code and how we will
                deal with such a complaint.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Severability</p>
          <p>If:</p>
          <ol>
            <li>
              <p>
                the National Credit Code, PPSA or any other law would otherwise
                make a provision of this Loan Agreement illegal, void or
                unenforceable in any jurisdiction; or
              </p>
            </li>
            <li>
              <p>
                a provision of this Loan Agreement would otherwise contravene a
                requirement of the National Credit Code or impose an obligation
                or liability which is prohibited by the National Credit Code or
                any other law,
              </p>
              <p>
                then this Loan Agreement is to be read as if that provision were
                varied to the extent necessary to comply with the National
                Credit Code, PPSA or that other law or, if necessary, omitted,
                without affecting the continued operation of the rest of this
                Loan Agreement in that jurisdiction or any other jurisdiction.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Assignment</p>
          <ol>
            <li>
              <p>
                You may not transfer or assign any of your rights or obligations
                under this Loan Agreement to any person without our prior
                written consent (which consent may be withheld at our absolute
                discretion).
              </p>
            </li>
            <li>
              <p>
                At law we may, without giving you notice, sell, assign, novate
                or otherwise dispose of or deal with our interest under this
                Loan Agreement. You agree that we may disclose any information
                (including personal information about you) or documents we
                consider reasonably necessary for us to exercise this right. You
                also agree that we may disclose information (including personal
                information about you) or documents at any time to a person to
                whom we assign our rights under this Loan Agreement.
              </p>
              <br />
            </li>
          </ol>
        </li>
        <li>
          <p class="heading">Governing Law</p>
          <p>
            This Loan Agreement is governed by the laws of New South Wales. The
            parties submit to the non-exclusive jurisdiction of the courts of
            New South Wales in respect of all matters relating to this Loan
            Agreement and the Loan.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Dispute Resolution</p>
          <p>
            If you have any concerns in relation to your Loan, you can contact
            us on 1800 042 766. We will use our best endeavours to resolve any
            issues you have. We are a member of AFCA. If you are dissatisfied
            with the outcome of our internal dispute resolution process, you may
            refer your concerns to AFCA. AFCA is a free and independent disputes
            resolution service that assists individual customers. AFCA can be
            contacted on 1800 931 678 or at
            <a href="mailto:info@afca.org.au" target="_blank"
              >info@afca.org.au.</a
            >
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Waiver</p>
          <p>
            A right created under this Agreement may not be waived except in
            writing signed by the party or parties to be bound.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Definitions in this Agreement:</p>
          <br />
          <p>
            <span class="heading">AFCA</span> means the Australian Financial
            Complaints Authority.
          </p>
          <br />
          <p>
            <span class="heading">Business Day</span>means a day other than a
            Saturday, Sunday, or statutory public holiday in Australia.
          </p>
          <br />
          <p>
            <span class="heading">Balance Owing on your Loan Account</span>
            means at any point in time, the difference between all amounts
            credited and all amounts debited to your Loan Account. When this
            amount is to be calculated for the end of a day, it includes all
            debits and credits assigned to that day.
          </p>
          <br />
          <p>
            <span class="heading">Customer, you, or your</span> means the person
            or persons named in the Details as the Customer (including their
            successors and assigns), and if there is more than one, each such
            person separately and all such persons jointly.
          </p>
          <br />
          <p>
            <span class="heading">Default</span> means the occurrence of one or
            more events or circumstances described in clause 17 of these General
            Terms.
          </p>
          <br />
          <p>
            <span class="heading">Details</span> means the document titled Loan
            Agreement Details that we provided to you.
          </p>
          <br />
          <p>
            <span class="heading">Disclosure Date</span> means the date
            specified in the Details.
          </p>
          <br />
          <p>
            <span class="heading">Direct Debit Authority</span> means an
            authority authorising us to debit your Nominated Account, in a form
            specified by us from time to time.
          </p>
          <br />
          <p>
            <span class="heading">Fees and Charges</span> means any fee or
            charge specified in the Details and any new fee or charge we notify
            to you.
          </p>
          <br />
          <p>
            <span class="heading">General Terms</span> means these Loan
            Agreement General Terms.
          </p>
          <br />
          <p>
            <span class="heading">GST</span> means goods and services tax, as
            defined in the GST Act.
          </p>
          <br />
          <p>
            <span class="heading">GST Act</span> means A New Tax System (Goods
            and Services Tax) Act 1999.
          </p>
          <br />
          <p>
            <span class="heading">Loan</span> means the loan facility described
            in the Details, provided to you under this Loan Agreement.
          </p>
          <br />
          <p>
            <span class="heading">Loan Account</span> means an account we
            establish in your name for recording all transactions in connection
            with the Loan.
          </p>
          <br />
          <p>
            <span class="heading">Loan Agreement</span> means these General
            Terms, the Details and the security agreement contained therein.
          </p>
          <br />
          <p>
            <span class="heading">Loan Amount</span> means the total amount of
            credit provided to you under this Loan Agreement, as set out in the
            Details.
          </p>
          <br />
          <p>
            <span class="heading">Loan Application</span> means the application
            you made for the Loan on the Website.
          </p>
          <br />
          <p>
            <span class="heading">Loan Term</span>
            
              means the term of the Loan Agreement, as defined in clause 6
              above.
          </p>
          <br />
          <p>
            <span class="heading">National Credit Code</span> means Schedule 1
            of the National Consumer Credit Protection Act 2009.
          </p>
          <br />
          <p>
            <span class="heading">NCCPA</span> means the National Consumer
            Credit Protection Act 2009, the National Consumer Credit Protection
            Regulations 2010 and the National Credit Code.
          </p>
          <br />
          <p>
            <span class="heading">Nominated Account</span> means the bank 
            account nominated by you for debiting by us, in accordance with 
            a Direct Debit Authority.
          </p>
          <br />
          <p>
            <span class="heading">Notice of Default</span> means a notice to you
            setting out why you are in Default and the date by which you must
            correct the Default.
          </p>
          <br />
          <p>
            <b>PPSA</b> means the
            <i>Personal Property Securities Act 2009</i> (Cth).
          </p>
          <br />
          <p>
            <span class="heading">PPSR</span> means the Personal Property
            Securities Register. Power includes a right, authority, power,
            discretion or remedy. Proceeds has the meaning given in the PPSA.
          </p>
          <p>
            <span class="heading">Privacy Act</span> means the Privacy Act 1988
            (Cth) and any legally bind codes or guidance made under that Act.
          </p>
          <br />
          <p>
            <span class="heading">Secured Annual Percentage Rate</span> means
            the Secured Annual Percentage Rate specified in the Details.
          </p>
          <br />
          <p>
            <span class="heading">Security Agreement</span> has the same meaning
            under the PPSA.
          </p>
          <br />
          <p>
            <span class="heading">Security Interest</span> means a mortgage,
            transfer, pledge, lien or charge, or any security preference or
            arrangement of any kind which allows a creditor to have its claims
            satisfied prior to other creditors from the proceeds of an asset. It
            includes a security interest under the PPSA.
          </p>
          <br />
          <p>
            <span class="heading">Secured Loan</span> means a personal loan
            provided by Harmoney that requires collateral, namely Secured
            Property.
          </p>
          <br />
          <p>
            <span class="heading">Secured Property</span> means any property
            which you use the Loan Amount (in whole or in part) to acquire and
            in respect of which you grant us a Security Interest by executing a
            Security Schedule.
          </p>
          <br />
          <p>
            <span class="heading">Security Schedule</span> means a document in a
            form that we give you or make available you at your request which
            you execute to grant us a Security Interest in Secured Property.
          </p>
          <br />
          <p>
            <span class="heading">Settlement Date</span> means the date that we 
            first instruct our bank to disburse all or any part of the Loan Amount.
          </p>
          <br />
          <p>
            <span class="heading">Statement of Account</span> has the same
            meaning as in the National Credit Code
          </p>
          <br />
          <p>
            <span class="heading">Total Amount Owing</span> means, at any time,
            the Balance Owing on your Loan Account at that time, plus all
            accrued interest charges, Fees and Charges, and other amounts which
            you must pay under this Loan Agreement but which have not been
            debited to your Loan Account at that time.
          </p>
          <br />
          <p>
            <span class="heading">Unsecured Annual Percentage Rate</span> means
            the Unsecured Annual Percentage Rate specified in the Details.
          </p>
          <br />
          <p>
            <span class="heading">Unsecured Loan</span> means a personal loan
            provided by Harmoney that does not require any type of collateral.
          </p>
          <br />
          <p>
            <span class="heading">Vehicle</span> means the vehicle identified in
            the Details as Secured Property (it includes all replacements, parts
            and accessories.
          </p>
          <br />
          <p>
            <span class="heading">Website</span> means the website at
            <a href="http://www.harmoney.com.au/" target="_blank"
              >www.harmoney.com.au</a
            >, or such other website as we may from time to time notify to you.
          </p>
          <br />
          <p>
            <span class="heading">We, us, or our</span> means Harmoney Australia
            Pty Ltd and includes our successors and assigns.
          </p>
          <br />
        </li>
        <li>
          <p class="heading">Interpretation:</p>
          <p>In this Loan Agreement:</p>
          <ol>
            <li>
              <p>
                headings are inserted for ease of reference only, and do not
                affect the interpretation of this agreement;
              </p>
            </li>
            <li>
              <p>
                including or such as or for example when introducing an example,
                does not limit the meaning of the words to which the example
                relates to that example or examples of a similar kind;
              </p>
            </li>
            <li>
              <p>
                references to the singular include the plural, and vice versa;
              </p>
            </li>
            <li>
              <p>
                references to anything includes the whole and each part of it;
              </p>
            </li>
            <li>
              <p>
                references to a person include an individual, a firm, a company,
                a body
              </p>
              <p>
                corporate, partnership, firm, joint venture, association, trust,
                an unincorporated association, governmental or other regulatory
                body, or an authority or entity, in each case whether or not
                having a separate legal identity;
              </p>
            </li>
            <li>
              <p>
                reference to any party includes its permitted assignee or
                transferee;
              </p>
            </li>
            <li>
              <p>
                reference to the “liability” of a person include references to
                its liability under any cause of action, whether in contract,
                tort, or equity or under any enactment;
              </p>
            </li>
            <li>
              <p>
                reference to a document includes any variation or replacement of
                it; any document (however described) are references to that
                document as modified, novated, supplemented, varied or replaced
                from time to time and in any form, whether on paper or in an
                electronic form; and
              </p>
            </li>
            <li>
              <p>
                “law” means common law, principles of equity and laws made by
                the Australian Parliament (and includes regulations and other
                instruments under laws made by the Australian Parliament and
                consolidations, amendments, re-enactments or replacements of any
                of them).
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div> </body></html>`;

  const template = `
${template1}
${template2}
${template3}
`;
  if (withHtml) {
    return (
      <Document>
        <Page style={styles.body}>
          <Html>{template1}</Html>
        </Page>
        <Page style={styles.body}>
          <Html>{template2}</Html>
        </Page>
        <Page style={styles.body}>
          <Html>{template3}</Html>
        </Page>
      </Document>
    );
  }
  return <div dangerouslySetInnerHTML={{ __html: template }} />;
};

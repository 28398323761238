import { forwardRef, SVGAttributes } from 'react';
import classNames from 'classnames';

import type { PropsWithHTMLElement } from '../../types';

export type IconSize = 'xlarge' | 'large' | 'medium' | 'small' | 'tiny';

export type IconVariant = 'primary' | 'secondary' | 'tertiary' | 'black' | 'white' | 'none' | 'grey-4' | 'grey-2';

const sizes: { [key in IconSize]: { [key in 'height' | 'width']: string } } = {
  xlarge: {
    height: '48px',
    width: '48px',
  },
  large: {
    height: '32px',
    width: '32px',
  },
  medium: {
    height: '24px',
    width: '24px',
  },
  small: {
    height: '20px',
    width: '20px',
  },
  tiny: {
    height: '16px',
    width: '16px',
  },
};

export type IconInternalProps = {
  /**
   * Determines the fill color used
   */
  variant?: IconVariant;
  /**
   * Determines the size of the icon
   */
  size?: IconSize;
  /**
   * Whether or not to trim the icon width, i.e. set `width` to `auto`
   */
  trimmed?: boolean;
  /**
   * Custom SVG viewBox attribute to use
   */
  viewBox?: SVGAttributes<SVGSVGElement>['viewBox'];
};

export type IconProps = PropsWithHTMLElement<IconInternalProps, 'svg'>;

const useAriaHidden = (props: Pick<IconProps, 'aria-label' | 'aria-labelledby'>) => {
  const ariaLabel = props['aria-label'];
  const ariaLabelBy = props['aria-labelledby'];

  if (ariaLabel || ariaLabelBy) {
    return {};
  }

  return {
    'aria-hidden': true,
  };
};

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      children,
      className,
      variant = 'primary',
      size = 'medium',
      trimmed,
      viewBox = '0 0 24 24',
      width,
      height,
      ...restProps
    }: IconProps,
    ref: React.Ref<SVGSVGElement>
  ) => {
    const shared = {
      className: classNames(
        {
          'fill-primary': variant === 'primary',
          'fill-secondary': variant === 'secondary',
          'fill-tertiary': variant === 'tertiary',
          'fill-black': variant === 'black',
          'fill-white': variant === 'white',
          'fill-none': variant === 'none',
          'fill-grey-4': variant === 'grey-4',
          'fill-grey-2': variant === 'grey-2',
        },
        className
      ),
      height: height || sizes[size].height,
      width: width || trimmed ? 'auto' : sizes[size].width,
    };

    const ariaHiddenProps = useAriaHidden(restProps);

    return (
      <svg display="inline-block" viewBox={viewBox} ref={ref} {...ariaHiddenProps} {...restProps} {...shared}>
        {children}
      </svg>
    );
  }
);

import React, { useRef, useState } from 'react';
import { Label } from '@harmoney/ui-design-system';
import { useHotkeys } from '@mantine/hooks';

import 'regenerator-runtime/runtime';

type Props = {
  globalFilter: string;
  totalItems: number;
  setGlobalFilter: (value: string) => void;
};

const GlobalFilter = ({ globalFilter, setGlobalFilter, totalItems }: Props) => {
  const [value, setValue] = useState(globalFilter);

  const searchRef = useRef<HTMLInputElement>(null);

  useHotkeys([
    [
      '/',
      () => {
        searchRef.current?.focus();
      },
    ],
  ]);

  return (
    <div className="flex flex-row items-center gap-1">
      <Label className="flex flex-col font-bold">
        Search:{' '}
        <span className="font-mono text-[0.5rem]">
          Press{' '}
          <span className="max-w-2 bg-grey-1 max-h-2 rounded-sm border border-b-2 border-opacity-20 px-1 font-mono text-[0.5rem]">
            /
          </span>
        </span>
      </Label>
      <input
        ref={searchRef}
        type="text"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setGlobalFilter(e.target.value);
        }}
        placeholder={`${totalItems} records...`}
        className="h-8 w-auto rounded-md border border-gray-300 p-2"
      />
    </div>
  );
};

export default GlobalFilter;

import { useRef, useState } from 'react';
import { DebtConBenefits } from '@harmoney/hooks';
import { Benefits, Button, Card, Dialog, IconV2 } from '@harmoney/ui-design-system';
import { NumberFormatterNoDecimal } from '@harmoney/ui-utils';
import { DebtConsolidationBenefitEnum } from '@prisma/client';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { SwiperRef } from 'swiper/react';

type DebtConBenefitsRenderProps = {
  benefits: DebtConBenefits;
};

export const HARMONEY_CUSTOMER_COUNT = 37000;

export const standardDebtConsolidationReasons = [
  DebtConsolidationBenefitEnum.CHANGE_PROVIDER,
  DebtConsolidationBenefitEnum.NO_FEES,
];

export const DebtConBenefitsRender = ({ benefits }: DebtConBenefitsRenderProps) => {
  const [showDialog, setShowDialog] = useState(false);

  const swiperRef = useRef<SwiperRef>(null);

  const areBenefitsCommon = isEqual(
    benefits.filter((benefit) => benefit.display).map((benefit) => benefit.id),
    standardDebtConsolidationReasons
  );

  return (
    <>
      <Card className="flex flex-col !p-0 overflow-hidden">
        <div className="bg-secondary-lighter-3 p-4">
          <div className="flex flex-row items-center justify-between w-full">
            <span className="text-sm font-medium">Your benefits with Harmoney</span>
            <div
              className={classNames(
                swiperRef?.current?.swiper?.isLocked ? 'hidden' : 'flex flex-row gap-1 items-center text-grey-4 text-sm'
              )}
            >
              Scroll <IconV2 icon="ic:outline-chevron-right" className="align-text-bottom" width={16} height={16} />
            </div>
          </div>
        </div>
        <div className="pt-4">
          <Benefits.Container ref={swiperRef}>
            {benefits
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map(({ icon, children, iconClassName, display }, index) => (
                <Benefits.Card key={index} icon={icon} iconClassName={iconClassName} display={display}>
                  {children}
                </Benefits.Card>
              ))}
          </Benefits.Container>
        </div>
        <div className="flex flex-row p-4 text-xs gap-1 justify-start items-center">
          {areBenefitsCommon ? (
            <>
              <span>Not happy with these benefits? </span>
              <Button variant="link" size="small" onClick={() => setShowDialog(true)}>
                Find out more
              </Button>
            </>
          ) : (
            <div className="text-xs text-grey-4">*Savings apply to 7-year term only.</div>
          )}
        </div>
      </Card>
      <Dialog open={showDialog} onOpenChange={() => setShowDialog(false)} title="More about our benefits" modal>
        <div className="p-4 flex flex-col mb-8 gap-4">
          <p>
            Unfortunately, we are unable to offer a lower rate, lower monthly repayment, or consolidation of more than
            one debt. However you can still enjoy these benefits:
          </p>
          <ul className="list-disc px-4 space-y-1 pb-2">
            <li>You&rsquo;ll avoid ongoing and early repayment fees</li>
            <li>You&rsquo;ll join {NumberFormatterNoDecimal(HARMONEY_CUSTOMER_COUNT)} Harmoney customers</li>
          </ul>
          <Button variant="text" onClick={() => setShowDialog(false)} className="self-center">
            GOT IT
          </Button>
        </div>
      </Dialog>
    </>
  );
};

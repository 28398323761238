import { useEffect, useState } from 'react';
import { OrderEnum, PaymentTransactionGroupedByDateDTO, TimeUnitEnum } from '@harmoney/api-interfaces';
import { Transaction } from '@harmoney/components';
import { useGetPaymentTransactionsQuery, useLazyGetPaymentTransactionsQuery } from '@harmoney/redux';
import { Button, Card } from '@harmoney/ui-design-system';

interface TransactionHistoryProps {
  loanApplicationId: string;
}

export const TransactionHistory = ({ loanApplicationId }: TransactionHistoryProps) => {
  const [limit, setLimit] = useState<number>(3);
  const [allTransactions, setAllTransactions] = useState<PaymentTransactionGroupedByDateDTO[]>([]);
  const [transactions, setTransactions] = useState<PaymentTransactionGroupedByDateDTO[]>([]);
  const [recordsFetched, setRecordsFetched] = useState<number>(0);

  const TRANSACTIONS_TO_SHOW = 5;

  const commonParams = { numberOfPeriodUnits: 1, periodUnit: TimeUnitEnum.Year, order: OrderEnum.DESC };

  const { data: initialTransactionData } = useGetPaymentTransactionsQuery({
    loanApplicationId,
    params: { skipPeriod: 0, ...commonParams },
  });
  const [trigger, , lastPromiseInfo] = useLazyGetPaymentTransactionsQuery();

  useEffect(() => {
    if (initialTransactionData?.data) {
      setAllTransactions(initialTransactionData.data);
      setRecordsFetched(initialTransactionData.totalRecordsFetchedforCurrentPeriod);
    }
  }, [initialTransactionData]);

  useEffect(() => {
    setTransactions(allTransactions.slice(0, limit));
  }, [limit, allTransactions]);

  const handleLoadMore = async () => {
    const remainingTransactions = allTransactions.length - transactions.length;

    if (remainingTransactions < TRANSACTIONS_TO_SHOW && recordsFetched < initialTransactionData?.totalRecords) {
      try {
        const result = await trigger({
          loanApplicationId,
          params: {
            skipPeriod: lastPromiseInfo?.lastArg?.params ? lastPromiseInfo.lastArg.params.skipPeriod + 1 : 1,
            ...commonParams,
          },
        }).unwrap();

        setAllTransactions((prev) => {
          const newTransactions = result.data.filter(
            (transaction) => !prev.some((prevTransaction) => prevTransaction.date === transaction.date)
          );
          return [...prev, ...newTransactions];
        });
        setRecordsFetched((prev) => prev + result.totalRecordsFetchedforCurrentPeriod);
      } catch (error) {
        console.error('Failed to fetch transactions:', error);
      }
    }
    setLimit((prev) => prev + TRANSACTIONS_TO_SHOW);
  };

  return (
    <Card className="flex flex-col items-stretch !p-0 md:px-16 my-6">
      <div className="flex flex-col">
        <div className="p-4 my-2 text-grey-5 font-medium border-b border-b-grey-2">Recent transactions</div>
      </div>
      {transactions.length > 0 ? (
        <>
          <div className="my-4">
            {transactions.map((transaction, index) => (
              <Transaction key={index} transaction={transaction} />
            ))}
          </div>

          {(transactions.length < allTransactions.length || recordsFetched < initialTransactionData?.totalRecords) && (
            <Button className="mb-4 mx-auto" variant="outline-secondary" onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </>
      ) : (
        <div className="text-grey-4">No recent transactions</div>
      )}
    </Card>
  );
};

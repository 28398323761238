import Link from 'next/link';
import React, { Fragment } from 'react';
import { vaultAccountStatusMapper } from '@harmoney/api-interfaces';
import { useAppSelector, useGetAllLoansByUserIdQuery } from '@harmoney/redux';
import { CollapsibleCard, Spinner } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import dayjs from 'dayjs';

export const CustomerLoanTable = ({ userId }: { userId: string }) => {
  const token = useAppSelector((state) => state?.accessToken?.value);
  const { data: loans, isLoading: isLoanApplicationsLoading } = useGetAllLoansByUserIdQuery(userId as string, {
    skip: !userId || !token,
  });

  if (isLoanApplicationsLoading) {
    return <Spinner />;
  }

  if (loans?.length === 0) {
    return (
      <div className="h-full w-full flex flex-column justify-center items-center text-xl text-grey-2 text-bold">
        Customer has no active loans
      </div>
    );
  }

  return (
    <CollapsibleCard title="Loans" className="mb-6" disabled>
      <table className="w-full text-left">
        <thead>
          <tr className="border-b border-b-grey-2">
            <th className="p-4">Loan Number</th>
            <th className="p-4">Loan purpose</th>
            <th className="p-4">Application created on</th>
            <th className="p-4">Loan open</th>
            <th className="p-4">Status</th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {loans?.map((loan) => (
            <Fragment key={loan.businessKey}>
              <tr className="border-b border-b-grey-2">
                <td className="p-4">
                  <Link href={`/admin/customer/loan?applicationId=${loan.id}&userId=${loan.userId}`}>
                    {loan.businessKey}
                  </Link>
                </td>
                <td className="p-4">
                  {loan.loanApplicationPurposes
                    .map((loanApplicationPurpose) => loanApplicationPurpose.loanPurpose.displayName)
                    .join(', ')}
                </td>
                <td className="p-4">{dayjs(loan.createdAt).format(DATE_FORMAT)}</td>
                <td className="p-4">{dayjs(loan.loanInformation.contractDate).format(DATE_FORMAT)}</td>
                <td className="p-4">{vaultAccountStatusMapper[loan.loanInformation.loanStatus]}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </CollapsibleCard>
  );
};

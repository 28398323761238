import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ControlledInput, ToggleGroup } from '@harmoney/ui-design-system';
import { PaymentTypeOptions } from '@harmoney/ui-utils';
import { PaymentTypeEnum } from '@prisma/client';

export function BillerType({ formData, index, ...props }) {
  const {
    resetField,
    formState: { errors },
  } = useFormContext();
  const billerType = formData.debts[index]?.billerType;
  const getHelpText = () => {
    const selectedValue = formData.debts[index]?.billerType;
    if (!selectedValue) return '';
    return selectedValue === PaymentTypeEnum.BPAY
      ? 'On a bill, you can find your BPAY biller code in the payment options section.'
      : ' You can find your BSB number on the payment details on your statement, or you call your provider directly.';
  };

  const isBillerTypeSelected = errors?.debts?.[index]?.billerType?.type === 'invalid_union_discriminator';

  useEffect(() => {
    if (billerType === PaymentTypeEnum.BPAY) {
      resetField(`debts[${index}].bsbNumber`, undefined);
      resetField(`debts[${index}].bsbAccountNumber`, undefined);
      resetField(`debts[${index}].bsbReference`, undefined);
    } else {
      resetField(`debts[${index}].bpayBillerCode`, undefined);
      resetField(`debts[${index}].bpayReference`, undefined);
    }
  }, [billerType]);

  const handleInputChange = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
    const value = e.target.value.replace(/[^0-9]/g, '');
    const formattedValue = [];
    for (let i = 0; i < value.length; i++) {
      if (i > 0 && i % 3 === 0) {
        formattedValue.push('-');
      }
      formattedValue.push(value[i]);
    }

    e.target.value = formattedValue.join('');
  };

  return (
    <>
      <div className="flex items-center justify-between p-3">
        <ToggleGroup
          label="How should we pay this off for you?"
          name={`debts_[${index}]_billerType`}
          key={`debts_[${index}]_billerType`}
          displayValidation={false}
          validationMessage={isBillerTypeSelected && 'Please select an option'}
          {...props.register(`debts[${index}].billerType`)}
          helpText={getHelpText()}
          options={PaymentTypeOptions}
        />
      </div>
      {formData.debts[index]?.billerType === PaymentTypeEnum.BPAY && (
        <>
          <div className="items-center justify-between p-3 pb-0">
            <ControlledInput
              key={`debts_[${index}]_bpayBillerCode`}
              helpText="4-6 digit number"
              maxLength={6}
              isNumeric={true}
              type="text"
              className="mb-4"
              {...props.register(`debts[${index}].bpayBillerCode`)}
              label="BPay biller code"
            />
          </div>
          <div className="items-center justify-between p-3 pt-0">
            <ControlledInput
              key={`debts_[${index}]_bpayReference`}
              helpText="2-20 digit number"
              maxLength={20}
              isNumeric={true}
              type="text"
              className="mb-4"
              {...props.register(`debts[${index}].bpayReference`)}
              label="BPay Reference"
            />
          </div>
        </>
      )}

      {formData.debts[index]?.billerType === PaymentTypeEnum.BSB && (
        <>
          <div className="items-center justify-between p-3 pb-0">
            <ControlledInput
              helpText="6 digit number"
              type="text"
              maxLength={7}
              className="mb-4"
              onKeyDown={handleInputChange}
              {...props.register(`debts[${index}].bsbNumber`)}
              key={`debts_[${index}]_bsbNumber`}
              label="BSB number"
            />
          </div>
          <div className="items-center justify-between p-3 pb-0 pt-0">
            <ControlledInput
              helpText="4-9 digit number"
              type="text"
              maxLength={9}
              isNumeric={true}
              className="mb-4"
              {...props.register(`debts[${index}].bsbAccountNumber`)}
              key={`debts_[${index}]_bsbAccountNumber`}
              label="Account number"
            />
          </div>
          <div className="items-center justify-between p-3 pb-0 pt-0">
            <ControlledInput
              type="text"
              className="mb-4"
              {...props.register(`debts[${index}].bsbReference`)}
              key={`debts_[${index}]_bsbReference`}
              label="Reference"
            />
          </div>
        </>
      )}
    </>
  );
}

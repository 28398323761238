import { useEffect, useState } from 'react';
import { VaultLoanInformationDto } from '@harmoney/api-interfaces';
import {
  Alert,
  ArrowCircleRightIcon,
  Button,
  Checkbox,
  ControlledInput,
  DatePicker,
  Dialog,
  Form,
  Label,
  Select,
  Textarea,
  useForm,
} from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { LoanVariationPurposeEnum } from '@prisma/client';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { CommonProps } from '../../../common-props';
import { loanVariationPurposeOptions } from '../LoanVariation/loan-variation-options';

import { createSuspendDirectDebitFormSchema, getSuspendDirectDebitDefaultValues } from './form-config';
import { PaymentPlanFormFooter } from './PaymentPlanFormFooter';
import { PaymentPlanFormHeader } from './PaymentPlanFormHeader';
import { ChangeSummaryTypeEnum } from './util';

dayjs.extend(isSameOrBefore);

interface SuspendDirectDebitProps extends CommonProps {
  loanApplicationId: string;
  loanInformation: VaultLoanInformationDto;
}

export const SuspendDirectDebit = ({
  loanApplicationId,
  taskId,
  completeTask,
  loanInformation,
}: SuspendDirectDebitProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [showDateAlert, setShowDateAlert] = useState<boolean>(false);

  const form = useForm({
    mode: 'onTouched',
    schema: createSuspendDirectDebitFormSchema(),
    defaultValues: getSuspendDirectDebitDefaultValues(),
  });

  const { register, watch } = form;

  const watchForm = watch();

  useEffect(() => {
    const isPermanentSuspension = watchForm.isPermanentSuspension;
    const resumeDate = watchForm.resumeDate;
    const maturityDate = loanInformation?.maturityDate;
    if (!isPermanentSuspension && resumeDate && maturityDate && dayjs(resumeDate).isAfter(maturityDate)) {
      setShowDateAlert(true);
    } else {
      setShowDateAlert(false);
    }
  }, [loanInformation?.maturityDate, watchForm.isPermanentSuspension, watchForm.resumeDate]);

  const handleSubmit = () => {
    setIsConfirmModalOpen(true);
  };

  const onModalSubmit = () => {
    setIsSubmitting(true);
    const formData = form.getValues();

    const suspensionData = {
      ...formData,
      effectiveDate: dayjs(formData.effectiveDate).format('YYYY-MM-DD'),
      expiryDate: formData.isPermanentSuspension
        ? null
        : dayjs(formData.resumeDate).subtract(1, 'day').format('YYYY-MM-DD'),
    };

    completeTask({ taskId, variables: { loanApplicationId, suspensionData } });
  };

  return (
    <>
      <Form form={form} onSubmit={handleSubmit}>
        <PaymentPlanFormHeader headerTitle="Suspend direct debit" type={ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT} />
        <div className="px-4">
          <Select {...register('purpose')} label="Purpose" options={loanVariationPurposeOptions} className="mb-6" />

          {watchForm.purpose === LoanVariationPurposeEnum.OTHER && (
            <>
              <Label htmlFor="otherPurpose" className="mb-2">
                Specify the purpose
              </Label>
              <ControlledInput {...register('otherPurpose')} className="mb-6" type="text" />
            </>
          )}

          <p className="font-medium mb-6">All direct debits will be suspended during this period.</p>
          <DatePicker
            className="mb-8"
            {...register('effectiveDate')}
            label="Pause direct debit from:"
            placeholderText="DD/MM/YYYY"
            minDate={dayjs().add(1, 'day').toDate()}
            flexiblePopperPlacement
            onKeyDown={(e) => e.preventDefault()}
          />
          <Label htmlFor="resumeDate" className="mb-1">
            Resume direct debit on:
          </Label>
          <span className="text-grey-4">Direct debits will resume from this date</span>
          <DatePicker
            className="mb-4 mt-2"
            {...register('resumeDate')}
            minDate={dayjs().add(1, 'day').toDate()}
            placeholderText="DD/MM/YYYY"
            onKeyDown={(e) => e.preventDefault()}
            disabled={watchForm.isPermanentSuspension}
            flexiblePopperPlacement
            textColor={watchForm.isPermanentSuspension ? '!text-grey-3' : ''}
          />
          <Checkbox
            {...register('isPermanentSuspension')}
            className="mb-8 ml-1"
            label="Suspend direct debit permanently"
          />
          <Textarea {...register('notes')} label="Notes" placeholder="Add a note" />
          {showDateAlert && (
            <Alert variant="warning" title="Resume Date can not be after maturity date" className="mt-4" />
          )}
          <PaymentPlanFormFooter
            buttonText="Save"
            isSubmitting={isSubmitting}
            isDisabled={showDateAlert}
            type={ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT}
          />
        </div>
      </Form>
      <Dialog
        open={isConfirmModalOpen}
        onOpenChange={() => {
          setIsConfirmModalOpen(!isConfirmModalOpen);
        }}
        title="Suspend direct debit"
        modal
      >
        {watchForm.isPermanentSuspension ? (
          <p className="p-4">
            Are you sure you want to permanently suspend direct debits starting from{' '}
            <span className="font-medium">{dayjs(watchForm.effectiveDate).format(DATE_FORMAT)}</span>?
          </p>
        ) : (
          <p className="p-4">
            Are you sure you want to temporary suspend repayments from{' '}
            <span className="font-medium">{dayjs(watchForm.effectiveDate).format(DATE_FORMAT)}</span> to{' '}
            <span className="font-medium">{dayjs(watchForm.resumeDate).subtract(1, 'day').format(DATE_FORMAT)}</span>?
          </p>
        )}
        <div className="shrink-1 flex w-full flex-col items-center justify-start gap-2 sm:flex-col md:flex-row-reverse p-4 flex-wrap">
          <Button
            onClick={onModalSubmit}
            type="submit"
            variant="primary"
            className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            isLoading={isSubmitting}
            hasShadow
          >
            Suspend
          </Button>
          <Button onClick={() => setIsConfirmModalOpen(false)} variant="secondary" className="!min-w-fit !max-w-fit">
            Cancel
          </Button>
        </div>
      </Dialog>
    </>
  );
};

import {
  EmailCheckResult,
  PaginatedQueryInput,
  PaginatedResult,
  ResponseResult,
  UserDto,
} from '@harmoney/api-interfaces';
import { LoanApplicationStatusEnum, User } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation<UserDto, void>({
      query: () => ({
        url: `/users`,
        method: 'POST',
      }),
    }),
    updateUser: builder.mutation<ResponseResult, UserDto>({
      query: (data) => ({
        url: `/users`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    getUser: builder.query<UserDto, void>({
      query: () => ({
        url: '/users',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getUserById: builder.query<User, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getAllUsers: builder.query<
      PaginatedResult<
        User & {
          loanApplication: {
            id: string;
            status: LoanApplicationStatusEnum;
            createdAt: Date;
          }[];
        }
      >,
      PaginatedQueryInput
    >({
      query: (params) => ({
        url: '/users/all-users',
        method: 'GET',
        params,
      }),
      providesTags: ['User'],
    }),
    checkEmail: builder.query<EmailCheckResult, string>({
      query: (email) => ({
        url: `/users/check?email=${encodeURIComponent(email)}`,
        method: 'GET',
      }),
    }),
    updateEmail: builder.mutation<ResponseResult, UserDto>({
      query: (data) => ({
        url: `/users/update-email`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useUpdateUserMutation,
  useGetUserQuery,
  useGetUserByIdQuery,
  useGetAllUsersQuery,
  useLazyCheckEmailQuery,
  useUpdateEmailMutation,
} = userApi;

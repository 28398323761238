import { useMemo } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useGetLoanApplicationQuery,
  useGetLoanProductByTaskIdQuery,
  useGetPostApprovalConsolidatedDebtsQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, LottieConfetti, Spinner } from '@harmoney/ui-design-system';
import {
  AmountFormatter,
  COMPONENT_NAME,
  componentDebtConsDefaultCopyText,
  componentDefaultCopyText,
} from '@harmoney/ui-utils';
import { isDebtCon } from '@harmoney/utilities';
import { pick } from 'lodash';

import { CommonProps } from '../../common-props';

import { DebtDto, DebtList } from './components';

export function MoneyDisbursing({
  taskId,
  completeTask,
  copy = componentDefaultCopyText[COMPONENT_NAME.MONEY_DISBURSING],
  debtConsCopy = componentDebtConsDefaultCopyText[COMPONENT_NAME.MONEY_DISBURSING],
  taskFriendlyURL,
}: CommonProps) {
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanProduct } = useGetLoanProductByTaskIdQuery(taskId);
  const { data: loanApplicationData, isLoading: isLoanApplicationDataLoading } = useGetLoanApplicationQuery(
    variables?.loanApplicationId.toString(),
    {
      skip: !variables,
    }
  );
  const { data: consolidatedDebts } = useGetPostApprovalConsolidatedDebtsQuery(null, {
    skip: !isDebtCon(loanProduct?.name),
  });

  const { displayedCopy, fundedAmount, consolidatedAmount } = useMemo(() => {
    const consolidatedAmount = 0;
    if (!loanProduct || !loanApplicationData) return { displayedCopy: copy, fundedAmount: 0, consolidatedAmount };
    if (isDebtCon(loanProduct.name) && loanApplicationData.loanApplicationPurposes.length === 1) {
      return {
        displayedCopy: debtConsCopy,
        fundedAmount: loanApplicationData.fundedAmount,
        consolidatedAmount: 0,
      };
    }
    return {
      displayedCopy: copy,
      fundedAmount: isDebtCon(loanProduct.name)
        ? loanApplicationData.loanApplicationPurposes[1].fundedAmount
        : loanApplicationData.fundedAmount,
      consolidatedAmount: isDebtCon(loanProduct.name) ? loanApplicationData.loanApplicationPurposes[0].fundedAmount : 0,
    };
  }, [debtConsCopy, copy, loanProduct, loanApplicationData]);

  useFriendlyURL(taskFriendlyURL);

  if (isLoanApplicationDataLoading || !loanApplicationData?.fundedAmount) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="relative mb-16">
        <h1 className="text-center">
          <span className="text-[2.25rem]">{displayedCopy['title']}</span>
          <br />
          <span className="text-secondary text-[4rem]">{AmountFormatter.format(fundedAmount)}</span>
          <br />
          <span>{displayedCopy['subtitle']}</span>
        </h1>
        <LottieConfetti className="absolute top-[-5%] left-[15%]" />
      </div>
      {consolidatedAmount !== 0 && (
        <DebtList
          debts={consolidatedDebts?.map(
            (debt) => pick(debt, ['id', 'provider', 'outstandingBalance', 'networthSourceName']) as DebtDto
          )}
          consolidatedAmount={consolidatedAmount}
        />
      )}
      <Button
        className="mb-4"
        as="a"
        href="/dashboard"
        alignIcon="end"
        icon={<ArrowCircleRightIcon size="large" />}
        variant="primary"
        hasShadow
        onClick={() => completeTask({ taskId })}
      >
        {displayedCopy['ctaText']}
      </Button>
      <p>{displayedCopy['tipText']}</p>
    </div>
  );
}

import React, { useMemo } from 'react';
import { LoanApplicationRepaymentDetailDto } from '@harmoney/api-interfaces';
import { SummaryCard } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

import { BankAccountSelector } from './BankAccountSelector';

interface PaymentLoanDetailsProps {
  repaymentDetail: LoanApplicationRepaymentDetailDto;
  showLoanSummary?: boolean;
  isDebtCon?: boolean;
}

export const PaymentLoanDetails = ({
  repaymentDetail,
  showLoanSummary = false,
  isDebtCon = false,
}: PaymentLoanDetailsProps) => {
  const loanSummaryItems = useMemo(() => {
    if (!repaymentDetail || !showLoanSummary) return [];

    const loanAmount = {
      keys: [{ id: 'loan-amount', name: 'Loan amount' }],
      value: formatCurrency(repaymentDetail.loanPurposes.reduce((acc, { fundedAmount }) => acc + fundedAmount, 0)),
      description: '(excluding establishment fee)',
    };

    const loanProduct = {
      keys: [{ id: 'loan-product', name: 'Type' }],
      value: 'Personal loan',
    };

    const loanPurposes = {
      keys: isDebtCon
        ? [{ id: 'loan-purpose', name: 'Purpose' }]
        : repaymentDetail.loanPurposes.map((purpose, index) => ({
            id: `loan-purpose-${purpose.name}-${index}`,
            name: 'Purpose',
          })),
      value: isDebtCon
        ? repaymentDetail.loanPurposes[1].name
        : repaymentDetail.loanPurposes.map((purpose) => purpose.name).join(', '),
    };

    return [loanAmount, loanProduct, loanPurposes];
  }, [isDebtCon, repaymentDetail, showLoanSummary]);

  return (
    <SummaryCard
      title="Your loan"
      items={loanSummaryItems}
      footer={<BankAccountSelector repaymentDetail={repaymentDetail} bankAccountType="loanToBankAccount" />}
    />
  );
};

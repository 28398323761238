import { VaultLoanInformationDto } from '@harmoney/api-interfaces';
import { Badge, CollapsibleCard } from '@harmoney/ui-design-system';
import { DATE_FORMAT, valueOrNA } from '@harmoney/ui-utils';
import { formatCurrency } from '@harmoney/utilities';
import dayjs from 'dayjs';

import { Field } from '../shared';

interface Props {
  loanInformationData: VaultLoanInformationDto;
}

export const LoanInformationDetails = ({ loanInformationData }: Props) => {
  if (!loanInformationData) return null;

  const arrearsStatusBadgeVariant = {
    'Good Standing': 'success',
    'Bad Standing': 'action',
  };

  const loanStateBadgeVariant = {
    Active: 'success',
    'Written-off': 'action',
    Pending: 'warning',
    Cancelled: 'tertiary',
    'Paid-off': 'tertiary',
  };

  return (
    <CollapsibleCard title="Loan information" className="mb-6">
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field>
            <span>Loan Amount:</span>
            <span>{formatCurrency(loanInformationData?.loanAmount) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Establishment fee:</span>
            <span>{formatCurrency(loanInformationData?.establishmentFee) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Loan amount total (including fee):</span>
            <span>{formatCurrency(loanInformationData?.loanAmountTotalWithFee) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Interest rate (p.a.):</span>
            <span>{`${(loanInformationData?.interestRate * 100).toFixed(2)}%` ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Currency:</span>
            <span>
              {valueOrNA(loanInformationData?.currency === 'AUD' ? 'Australian dollar' : 'New Zealand dollar')}
            </span>
          </Field>
          <Field>
            <span>Contract date:</span>
            <span>{dayjs(loanInformationData?.contractDate).format(DATE_FORMAT) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Maturity date:</span>
            <span>{dayjs(loanInformationData?.maturityDate).format(DATE_FORMAT ?? 'N/A')}</span>
          </Field>
          <Field>
            <span>Loan term (months):</span>
            <span>{`${loanInformationData?.loanTermInMonths} months` ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Remaining term (months):</span>
            <span>{`${loanInformationData?.remainingTermInMonths} months` ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Arrears amount:</span>
            <span>{formatCurrency(loanInformationData?.arrearsAmount) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Grace period (days):</span>
            <span>{`${loanInformationData?.gracePeriodInDays} days` ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Principal:</span>
            <span>{formatCurrency(loanInformationData?.principal) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Principal due:</span>
            <span>{formatCurrency(loanInformationData?.principalDue) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Principal overdue:</span>
            <span>{formatCurrency(loanInformationData?.principalOverDue) ?? 'N/A'}</span>
          </Field>
        </div>
        <div className="p-4">
          <Field>
            <span>Interest:</span>
            <span>{formatCurrency(loanInformationData?.interestAccrued) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Interest due:</span>
            <span>{formatCurrency(loanInformationData?.interestDue) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Interest overdue:</span>
            <span>{formatCurrency(loanInformationData?.interestOverDue) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Interest accrued high precision:</span>
            <span>${loanInformationData?.interestAccruedHighPrecision}</span>
          </Field>
          <Field>
            <span>Penalities:</span>
            <span>{formatCurrency(loanInformationData?.penalities) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Penalities due:</span>
            <span>{formatCurrency(loanInformationData?.penalitiesDue) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Penalities overdue:</span>
            <span>{formatCurrency(loanInformationData?.penalitiesOverDue) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Written off amount:</span>
            <span>{formatCurrency(loanInformationData?.writtenOffAmount) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Written off principal:</span>
            <span>{formatCurrency(loanInformationData?.principalWrittenOff) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Written off interest:</span>
            <span>{formatCurrency(loanInformationData?.interestWrittenOff) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Written off penalities:</span>
            <span>{formatCurrency(loanInformationData?.penaltiesWrittenOff) ?? 'N/A'}</span>
          </Field>
          <Field>
            <span>Loan state:</span>
            <Badge
              variant={loanStateBadgeVariant[loanInformationData?.loanState]}
              label={loanInformationData?.loanState}
            />
          </Field>
          <Field>
            <span>Arrears status:</span>
            <Badge
              variant={arrearsStatusBadgeVariant[loanInformationData?.arrearsStatus]}
              label={loanInformationData?.arrearsStatus}
            />
          </Field>
        </div>
      </div>
    </CollapsibleCard>
  );
};

import { useEffect, useState } from 'react';
import { RepaymentScheduleDto } from '@harmoney/api-interfaces';
import { useGetUserByIdQuery, useGetUserProfileForAdminQuery } from '@harmoney/redux';
import { Button, CollapsibleCard, IconV2 } from '@harmoney/ui-design-system';
import { DATE_FORMAT } from '@harmoney/ui-utils';
import { dayjsUTCToSydney, formatCurrency, formatFrequency } from '@harmoney/utilities';
import { LoanVariation } from '@prisma/client';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import {
  ChangeSummaryTypeEnum,
  getFinalPaymentPlanData,
  isAllPaymentsCompleted,
  isAllPaymentsDeleted,
  isDDSuspensionDeleted,
  PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR,
} from './util';

export interface LoanVariationHistoryProps {
  changeSummaryData: RepaymentScheduleDto;
  sendData?: (data: LoanVariation, committedBy: string, deletedBy: string) => void;
}

const TableRow = ({ tableRow, sendData }) => {
  const getDeletedUserId = (type) => {
    switch (type) {
      case ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT:
        return tableRow.updatedBy;
      case ChangeSummaryTypeEnum.ONE_OFF_PAYMENT:
        return tableRow.cancelledBy;
    }
  };
  const deletedByUserId = getDeletedUserId(tableRow.type);

  const { data: createdByUserProfileData } = useGetUserProfileForAdminQuery(tableRow.createdBy as string, {
    skip: !tableRow.createdBy,
    refetchOnMountOrArgChange: true,
  });
  const { data: createdByUserData } = useGetUserByIdQuery(tableRow.createdBy as string, {
    skip: !tableRow.createdBy,
    refetchOnMountOrArgChange: true,
  });

  const { data: updatedByUserProfileData } = useGetUserProfileForAdminQuery(deletedByUserId as string, {
    skip: !deletedByUserId,
    refetchOnMountOrArgChange: true,
  });
  const { data: updatedByUserData } = useGetUserByIdQuery(deletedByUserId as string, {
    skip: !deletedByUserId,
    refetchOnMountOrArgChange: true,
  });

  const [committedBy, setCommittedBy] = useState<string>('');
  const [deletedBy, setDeletedBy] = useState<string>('');

  useEffect(() => {
    if (createdByUserProfileData && createdByUserProfileData?.firstName) {
      const { firstName, middleName, lastName } = createdByUserProfileData;
      const fullNameParts = [firstName, middleName, lastName].filter(
        (part) => typeof part === 'string' && part.trim() !== ''
      );
      if (fullNameParts.length > 0) {
        setCommittedBy(fullNameParts.join(' '));
      }
    } else {
      setCommittedBy(createdByUserData?.email);
    }
    if (updatedByUserProfileData && updatedByUserProfileData?.firstName) {
      const { firstName, middleName, lastName } = updatedByUserProfileData;
      const fullNameParts = [firstName, middleName, lastName].filter(
        (part) => typeof part === 'string' && part.trim() !== ''
      );
      if (fullNameParts.length > 0) {
        setDeletedBy(fullNameParts.join(' '));
      }
    } else {
      setDeletedBy(updatedByUserData?.email);
    }
  }, [createdByUserProfileData, createdByUserData?.email, updatedByUserProfileData, updatedByUserData?.email]);

  const handleSendData = async () => {
    await sendData(tableRow, committedBy, deletedBy);
  };

  const isPermanent = dayjs(tableRow.expiryDate).year() === PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR;

  const getChangeData = () => {
    switch (tableRow.type) {
      case ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT:
        return tableRow.isPermanent ? (
          <>
            Permanent suspension of direct debits{' '}
            <span className="font-medium">
              from {`${dayjsUTCToSydney(tableRow.effectiveDate).format(DATE_FORMAT)}`}
            </span>
          </>
        ) : (
          <>
            Suspend direct debits{' '}
            <span className="font-medium">
              {isPermanent ? 'permanently' : ''} from{' '}
              {`${dayjsUTCToSydney(tableRow.effectiveDate).format(DATE_FORMAT)}`}{' '}
              {isPermanent ? '' : `to ${dayjsUTCToSydney(tableRow.expiryDate).format(DATE_FORMAT)}`}
            </span>
          </>
        );
      case ChangeSummaryTypeEnum.ONE_OFF_PAYMENT:
        return (
          <>
            {formatCurrency(tableRow.schedules[0]?.amount)} scheduled for{' '}
            {dayjsUTCToSydney(tableRow.schedules[0]?.scheduledAt).format(DATE_FORMAT)}
            <br />
            {tableRow.schedules.length > 1 ? `+${tableRow.schedules.length - 1} other scheduled extra payments` : ''}
          </>
        );
      case ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN:
        return (
          <div className="flex flex-col">
            {tableRow?.paymentDate && (
              <span>Next payment: {dayjsUTCToSydney(tableRow?.paymentDate)?.format(DATE_FORMAT)}</span>
            )}
            {tableRow?.paymentFrequency && (
              <span>Payment frequency: {capitalize(formatFrequency(tableRow?.paymentFrequency))}</span>
            )}
          </div>
        );
    }
  };

  return (
    <tr className="border-b-2 border-b-grey-1">
      <td className="p-4">
        <Button variant="text" className="font-body font-normal normal-case	tracking-normal" onClick={handleSendData}>
          {capitalize(tableRow.type)}
        </Button>
      </td>
      <td className="p-4">{dayjs(tableRow.createdAt).format(DATE_FORMAT)}</td>
      <td className="p-4">{committedBy}</td>
      <td className="p-4">
        {getChangeData()}
        {isDDSuspensionDeleted(tableRow) && <p className="text-error text-sm mt-2">Suspension removed</p>}
        {isAllPaymentsDeleted(tableRow) && <p className="text-error text-sm mt-2">Deleted</p>}
        {isAllPaymentsCompleted(tableRow) && <p className="text-success text-sm mt-2">Completed</p>}
      </td>
    </tr>
  );
};

export const ChangeSummary = ({ changeSummaryData, sendData }: LoanVariationHistoryProps) => {
  const [showAllRows, setShowAllRows] = useState(false);
  const [open, setOpen] = useState(false);
  const finalSummaryData = getFinalPaymentPlanData(changeSummaryData);

  return (
    <CollapsibleCard title="Change Summary" className="mb-6" defaultOpen>
      <div className="overflow-auto max-h-[600px]" id="change-summary">
        <table className="w-full border-collapse bg-white text-sm">
          <thead className="text-left">
            <tr className="border-b-2 border-b-grey-1">
              <th className="p-4">Type</th>
              <th className="p-4">Committed On</th>
              <th className="p-4">Committed By</th>
              <th className="p-4">Change</th>
            </tr>
          </thead>
          <tbody>
            {finalSummaryData
              ?.slice(0, 2)
              .map((row, index) => <TableRow key={index} tableRow={row} sendData={sendData} />)}
            {finalSummaryData?.length > 2 && (
              <tr className={classNames(open ? 'border-b-2 border-b-grey-1' : '')}>
                <td className="p-4" colSpan={4}>
                  <Button
                    onClick={() => {
                      setShowAllRows(!showAllRows);
                      setOpen(!open);
                    }}
                    variant="text"
                    isFullWidth={true}
                    alignIcon="end"
                    icon={
                      <IconV2
                        icon="ic:round-chevron-right"
                        width={24}
                        className={classNames(open ? '-rotate-90' : 'rotate-90', 'transition-all text-grey-4')}
                      />
                    }
                    className="font-body justify-between font-normal capitalize tracking-normal !text-grey-4 hover:!text-grey-4 active:!text-grey-4 focus-visible:!text-grey-4"
                  >{`${finalSummaryData?.length - 2} changes`}</Button>
                </td>
              </tr>
            )}
            {showAllRows &&
              finalSummaryData
                ?.slice(2)
                .map((row, index) => <TableRow key={index + 2} tableRow={row} sendData={sendData} />)}
          </tbody>
        </table>
      </div>
    </CollapsibleCard>
  );
};

import { GroupedPaymentHistoryForPeriodResult, PaymentHistoryForPeriodQuery } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const paymentHistoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentTransactions: builder.query<
      GroupedPaymentHistoryForPeriodResult,
      { loanApplicationId: string; params: PaymentHistoryForPeriodQuery }
    >({
      query: ({ loanApplicationId, params }) => ({
        url: `/payment-history/${loanApplicationId}`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetPaymentTransactionsQuery, useLazyGetPaymentTransactionsQuery } = paymentHistoryApi;

import { RepaymentScheduleDto } from '@harmoney/api-interfaces';
import { DATE_TIME_FORMAT } from '@harmoney/ui-utils';
import { PaymentStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export enum ChangeSummaryTypeEnum {
  SUSPEND_DIRECT_DEBIT = 'Suspend direct debit',
  ONE_OFF_PAYMENT = 'Scheduled extra payment',
  UPDATE_DIRECT_DEBIT_PLAN = 'Updated direct debit plan',
}

interface Schedule {
  id: string;
  scheduledAt: string;
  amount: number;
  status: string;
}

interface GroupedPayments {
  spokeId: string;
  purpose: string;
  otherPurpose: string;
  note: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  cancelledAt: string;
  cancelledBy: string;
  type: ChangeSummaryTypeEnum;
  schedules: Schedule[];
}

export const PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR = 2099;

export const getFinalPaymentPlanData = (summaryData: RepaymentScheduleDto) => {
  const finalSummaryData: any[] = [];

  if (summaryData) {
    summaryData?.directDebitSuspensions?.forEach((suspension) => {
      finalSummaryData.push({
        ...suspension,
        type: ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT,
      });
    });

    const groupedPayments: { [key: string]: GroupedPayments } = summaryData?.oneoffRepayments?.reduce(
      (acc: { [key: string]: GroupedPayments }, payment) => {
        const key = `${dayjs(payment.createdAt).format(DATE_TIME_FORMAT)}-${payment.createdBy}-${payment.purpose}`;
        if (!acc[key]) {
          acc[key] = {
            spokeId: payment.spokeId,
            purpose: payment.purpose,
            otherPurpose: payment.otherPurpose,
            note: payment.note,
            createdAt: payment.createdAt ? payment.createdAt.toString() : null,
            createdBy: payment.createdBy,
            updatedAt: payment.updatedAt ? payment.updatedAt.toString() : null,
            updatedBy: payment.updatedBy,
            cancelledAt: payment.cancelledAt ? payment.cancelledAt.toString() : null,
            cancelledBy: payment.cancelledBy,
            schedules: [],
            type: ChangeSummaryTypeEnum.ONE_OFF_PAYMENT,
          };
        }
        acc[key].schedules.push({
          id: payment.id,
          scheduledAt: payment.scheduledAt,
          amount: payment.amount,
          status: payment.status,
        });
        return acc;
      },
      {}
    );

    if (groupedPayments) {
      Object.values(groupedPayments).forEach((group) => {
        group.schedules.sort((a, b) => {
          return new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime();
        });
        finalSummaryData.push(group);
      });
    }

    summaryData?.directDebitPlans?.forEach((plan) => {
      finalSummaryData.push({
        ...plan,
        type: ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN,
      });
    });

    return finalSummaryData;
  }
};

export const getCurrentOneOffPaymentDates = (finalPaymentPlanData): string[] => {
  if (!finalPaymentPlanData) return [];

  return finalPaymentPlanData
    .filter((item) => item.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT)
    .flatMap((payment) => payment.schedules)
    .filter(
      (schedule) =>
        dayjs(schedule.scheduledAt).isSameOrAfter(dayjs(), 'day') &&
        (schedule.status === PaymentStatusEnum.new ||
          schedule.status === PaymentStatusEnum.success ||
          schedule.status === PaymentStatusEnum.processing)
    )
    .map((schedule) => dayjs(schedule.scheduledAt).format('YYYY-MM-DD'));
};

export const isAllPaymentsDeleted = (oneOffRepayments) => {
  return oneOffRepayments?.schedules?.every((schedule) => schedule?.status === PaymentStatusEnum.cancelled);
};

export const isAllPaymentsCompleted = (oneOffRepayments) => {
  return oneOffRepayments?.schedules?.every((schedule) => schedule?.status === PaymentStatusEnum.success);
};

export const isDDSuspensionDeleted = (directDebitSuspension) => {
  return directDebitSuspension?.isActive === false;
};

export const isSomePaymentsDeleted = (oneOffRepayments) => {
  return oneOffRepayments?.schedules?.some((schedule) => schedule?.status === PaymentStatusEnum.cancelled);
};

export const getCurrentPendingOneOffPaymentAmount = (finalPaymentPlanData) => {
  if (!finalPaymentPlanData) return [];

  return finalPaymentPlanData
    .filter((item) => item.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT)
    .flatMap((payment) => payment.schedules)
    .filter(
      (schedule) =>
        dayjs(schedule.scheduledAt).isSameOrAfter(dayjs(), 'day') &&
        (schedule.status === PaymentStatusEnum.new || schedule.status === PaymentStatusEnum.processing)
    )
    .map((schedule) => schedule.amount)
    .reduce((acc, amount) => acc + amount, 0);
};

import { Card, Divider, Label } from '@harmoney/ui-design-system';
import { AmountFormatter } from '@harmoney/ui-utils';
import { capitalize } from 'lodash';

import { BillerType } from '../BillerType/BillerType';
export function DebtPaymentDetail({ formData, debts, errors, ...props }) {
  return (
    <>
      <Card className="!p-1">
        <div className="flex items-center justify-between">
          <Label className="p-4 text-lg font-medium">How we will pay your providers</Label>
        </div>
        <Divider className="text-grey-2 m-0 p-0" />
        {debts?.map((debt, index) => {
          const hasFailedVerification = errors?.data?.message?.failedVerifications?.find(
            (f) => f.liabilityId === debt.id
          );
          const { networthSourceName, provider, outstandingBalance } = debt;
          const borderClass = `${
            hasFailedVerification ? 'border-error' : 'border-grey-2'
          } m-4 mb-1 rounded-md border  border-gray-300`;
          return (
            <>
              <div key={index} className={borderClass}>
                <div className="flex items-center justify-between p-3">
                  <Label>Paid to</Label>
                  <span className="font-medium">{capitalize(provider.toLowerCase())}</span>
                </div>
                <Divider className="text-grey-2 m-0 p-2" />

                <div className="flex items-center justify-between p-3">
                  <Label>Type</Label>
                  <span className="font-medium">{capitalize(networthSourceName)}</span>
                </div>
                <Divider className="text-grey-2 m-0 p-2" />

                <div className="flex items-center justify-between p-3">
                  <Label>Amount they will receive</Label>
                  <span className="font-medium">{AmountFormatter.format(outstandingBalance)}</span>
                </div>
                <Divider className="text-grey-2 m-0 p-2" />
                <BillerType formData={formData} index={index} register={props.register} />
              </div>
              {hasFailedVerification && (
                <p className="text-error leading-sm ml-4 mt-0 pb-2 text-sm">
                  Please double check your {formData?.debts[index].billerType} details.
                </p>
              )}
            </>
          );
        })}
      </Card>
    </>
  );
}

import Link from 'next/link';
import { Badge, Button } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';

type loanCardProps = {
  loanApplicationId: string;
  loanBusinessKey: string;
  loanPurpose: string;
  loanStatus: string;
};
export const LoanCard = ({ loanApplicationId, loanBusinessKey, loanPurpose, loanStatus }: loanCardProps) => {
  const isActive = loanStatus === 'Active';

  return (
    <Link href={`/loans/details?id=${loanApplicationId}`}>
      <div className="flex flex-row items-center rounded-lg border border-grey-2 px-2 py-4 ">
        <div className="min-w-[6rem]">
          <Badge label={loanStatus} variant={isActive ? 'success' : 'tertiary'} />
        </div>
        <div className="flex-grow">
          <p className="text-grey-5 font-medium m-0">{loanPurpose}</p>
          <p className="text-grey-4 m-0">{loanBusinessKey}</p>
        </div>
        <div>
          <Button icon={<Icon icon="ic:round-chevron-right" className="text-grey-3" width={30} height={30} />} />
        </div>
      </div>
    </Link>
  );
};

declare global {
  interface Window {
    Intercom: (action: 'show' | 'hide') => void;
  }
}

export const openChatWindow = () => {
  if (!window?.Intercom) {
    console.warn('Intercom is not loaded');
    return;
  }
  return window.Intercom('show');
};

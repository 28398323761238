import React, { useState } from 'react';
import { relationshipStatusMapper, ResidencyStatusMapping } from '@harmoney/api-interfaces';
import { useAppSelector, useGetUserQuery } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Spinner } from '@harmoney/ui-design-system';
import { valueOrNA } from '@harmoney/ui-utils';

import { InformationSummary, InfoSummaryField } from './components';

export default function UserInformationSummary() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userData } = useGetUserQuery(null, { skip: !token });

  const handleSubmit = () => {
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 1000);
    //TODO:
    //The customer is taken to the following page on clicking That’s right [Getting to know you: IDV]
  };
  const handleUpdateInfo = () => {
    //TODO:
    //On clicking Update My Info, the customer is moved to the [Update your information page]
  };

  if (!userData) {
    return <Spinner />;
  }

  return (
    <>
      <h1>
        Does everything <span className="text-primary">look right</span>?
      </h1>
      <InformationSummary title="Personal">
        <InfoSummaryField
          label="Citizenship"
          value={valueOrNA(ResidencyStatusMapping[userData?.residencyStatus])}
          ratio={3}
        />
        <InfoSummaryField
          label="Relationship status"
          value={valueOrNA(relationshipStatusMapper[userData?.relationshipStatus])}
        />
      </InformationSummary>
      <InformationSummary title="Contact">
        <InfoSummaryField label="Phone number" value={valueOrNA(userData?.mobilePhoneNumber)} />
        <InfoSummaryField label="Email" value={valueOrNA(userData?.email)} />
      </InformationSummary>
      <div className="flex flex-col gap-6">
        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          onClick={handleSubmit}
          isLoading={isSubmitting}
        >
          That&rsquo;s right
        </Button>
        <Button type="submit" variant="outline-secondary" hasShadow onClick={handleUpdateInfo}>
          Update my info
        </Button>
      </div>
    </>
  );
}

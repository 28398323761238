import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { AmountInput, Card, Label } from '@harmoney/ui-design-system';
import {
  dayjsSydney,
  EXPIRY_DAYS_FOR_UNUTILISED_LIMIT,
  formatCurrency,
  MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT,
} from '@harmoney/utilities';
import { Icon } from '@iconify/react';

import { FormSchema } from '../form-config';

interface LoanPurposeAmountProps {
  remainingAmount: number;
  loanApplicationData: LoanApplicationDto & { bankStatementReferences: any[] };
  register: UseFormRegister<FormSchema>;
}

export const LoanPurposeAmount: React.FC<LoanPurposeAmountProps> = ({
  loanApplicationData,
  register,
  remainingAmount,
}) => {
  return (
    <Card className="!p-0">
      <Label className="m-4 mb-2">
        Borrow up to <strong>{formatCurrency(loanApplicationData?.quotePresentedAmount)}</strong> for{' '}
        {loanApplicationData?.loanApplicationPurposes[0]?.loanPurpose?.displayName?.toLowerCase()}
      </Label>
      <AmountInput {...register('amount')} maxLength={6} className="m-4" />
      {loanApplicationData.isEligibleForExtraLimit &&
        loanApplicationData.hasActiveMortgage &&
        remainingAmount > 0 &&
        loanApplicationData?.quotePresentedAmount - remainingAmount >=
          MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT && (
          <div className="flex items-start p-4 bg-secondary-lighter-3 rounded-b-xl">
            <Icon icon="ic:baseline-currency-exchange" className="text-grey-3 mr-2" width={24} />
            <p className="text-sm">
              You can borrow the remaining {formatCurrency(remainingAmount)} now or anytime before it expires on{' '}
              <span className="font-medium">
                {dayjsSydney(
                  loanApplicationData?.bankStatementReferences?.filter(
                    (ref) => ref?.bankStatementReference?.callBackReceivedAt !== null
                  )[0]?.bankStatementReference?.callBackReceivedAt
                )
                  .add(EXPIRY_DAYS_FOR_UNUTILISED_LIMIT - 1, 'days')
                  .format('D MMM YYYY')}
              </span>
              .{' '}
            </p>
          </div>
        )}
    </Card>
  );
};

import { ChangeEvent, useEffect } from 'react';
import { AccommodationExpenseEnum, NetworthSourceEnum, ResidentialStatusEnum } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useAppSelector,
  useGetUserProfileQuery,
  useSaveExpensesMutation,
  useSubmitIncomeMutation,
  useUpdateAddressMutation,
  useUpdateUserProfileMutation,
} from '@harmoney/redux';
import { eventAnalytics, FINANCIALPROFILE_LIVING_SITUATION_PROVIDED } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, Form, RadioSelectSelector, useForm } from '@harmoney/ui-design-system';
import { otherLivingSituationOptions, primaryLivingSituationOptions, stringToBool } from '@harmoney/ui-utils';
import { IncomeAndExpenseFrequencyEnum, RelationshipStatusEnum } from '@prisma/client';

import { CommonProps } from '../../common-props';

import { AccommodationExpenseCard, ChildSupportCard, DependantsCard } from './components';
import { getErrorMessageForSharedExpense, getNetWorthSourceId, shouldDisplaySharedExpense } from './form.util';
import { defaultValues, formSchema, FormSchemaTypeEnum } from './form-config';

export function HouseholdV2({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const userId = useAppSelector((state) => state.userId.value);

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });

  const {
    register,
    watch,
    resetField,
    setValue,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const formChanges = watch();
  const watchAccommodationExpense = watch('accommodationExpense');

  const { data: userProfile } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const [saveExpenses] = useSaveExpensesMutation();
  const [submitIncome] = useSubmitIncomeMutation();
  const [updateAddress] = useUpdateAddressMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  useEffect(() => {
    setValue('type', FormSchemaTypeEnum.BASE);
    setValue('accommodationExpense.relationshipStatus', userProfile?.relationshipStatus);
  }, [setValue, userProfile?.relationshipStatus]);

  useEffect(() => {
    const handleAccommodationExpenseError = () => {
      const { declaredAmount, declaredTotalAmount } = watchAccommodationExpense || {};
      if (declaredAmount && declaredTotalAmount && declaredAmount > declaredTotalAmount) {
        setError('accommodationExpense.declaredAmount', {
          type: 'custom',
          message: getErrorMessageForSharedExpense(formChanges?.livingType?.primary as ResidentialStatusEnum),
        });
      } else {
        clearErrors('accommodationExpense.declaredAmount');
      }
    };

    handleAccommodationExpenseError();
  }, [
    clearErrors,
    setError,
    formChanges?.livingType?.primary,
    watchAccommodationExpense,
    watchAccommodationExpense?.declaredAmount,
    watchAccommodationExpense?.declaredTotalAmount,
  ]);

  const isAccommodationExpenseFormType =
    formChanges?.type === FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT ||
    formChanges?.type === FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITHOUT_CHILD_SUPPORT;

  const isChildSupportRelatedFormType =
    formChanges?.type === FormSchemaTypeEnum.CHILD_SUPPORT ||
    formChanges?.type === FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT;

  const handleFormChange = (event: ChangeEvent<HTMLButtonElement | HTMLSelectElement>) => {
    const target = event.target;
    const { name, value } = target;
    const hasDependants =
      stringToBool(formChanges?.dependants?.hasDependants) ||
      (name === 'dependants.hasDependants' && value.toLowerCase() === 'yes');
    const isHomeOwner =
      formChanges?.livingType?.primary === ResidentialStatusEnum.HOME_OWNER ||
      (name === 'livingType.primary' && value === ResidentialStatusEnum.HOME_OWNER);

    setValue('accommodationExpense.livingType', formChanges?.livingType?.primary);
    setValue('dependants.livingType', formChanges?.livingType?.primary);

    if (!isHomeOwner && (name === 'livingType.primary' || name === 'livingType.secondary')) {
      resetField('accommodationExpense.situation', { keepError: false });
      resetField('accommodationExpense.declaredAmount', { keepError: false });
      resetField('accommodationExpense.declaredFrequency', { keepError: false });
      resetField('accommodationExpense.declaredTotalAmount', { keepError: false });
      resetField('accommodationExpense.declaredTotalFrequency', { keepError: false });
    }

    setValue('type', determineFormSchemaType(isHomeOwner, hasDependants));
  };

  const determineFormSchemaType = (isHomeOwner: boolean, hasDependants: boolean) => {
    if (isHomeOwner) return hasDependants ? FormSchemaTypeEnum.CHILD_SUPPORT : FormSchemaTypeEnum.BASE;
    return hasDependants
      ? FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITH_CHILD_SUPPORT
      : FormSchemaTypeEnum.ACCOMMODATION_EXPENSE_WITHOUT_CHILD_SUPPORT;
  };

  const handleSubmit = async (data) => {
    const activeAddressId = getActiveAddressId();
    const updatedCurrentAddress = getUpdatedCurrentAddress(data, activeAddressId);
    const updatedUserProfile = getUpdatedUserProfile(data);

    await updateUserProfile({ data: updatedUserProfile });
    await updateAddress({ id: activeAddressId, data: updatedCurrentAddress });

    const expensesData = getExpensesData(data, activeAddressId);
    await saveExpenses({ taskId, expenses: expensesData });

    if (stringToBool(data?.childSupport?.hasChildSupportReceived)) {
      await submitChildSupportIncome(data);
    }

    eventAnalytics.track(FINANCIALPROFILE_LIVING_SITUATION_PROVIDED, {
      userid_str: userId,
      taskid_str: taskId,
    });

    completeTask({ taskId });
  };

  const getActiveAddressId = () => {
    const currentAddress = userProfile?.addresses?.find((address) => address.isCurrent);
    return currentAddress?.id;
  };

  const getUpdatedCurrentAddress = (data, activeAddressId: string) => {
    const currentAddress = userProfile?.addresses?.find((address) => address.id === activeAddressId);

    return {
      ...currentAddress,
      residentialStatus: data.livingType.primary,
    };
  };

  const getUpdatedUserProfile = (data) => {
    return {
      numberOfDependants: stringToBool(data?.dependants?.hasDependants) ? +data?.dependants?.numberOfDependants : 0,
    };
  };

  const getExpensesData = (data, activeAddressId: string) => {
    const expensesData = [];
    const { livingType, accommodationExpense, childSupport } = data || {};

    if (!livingType) return expensesData;

    const networthSourceId = getNetWorthSourceId(livingType.primary);

    if (livingType.primary !== ResidentialStatusEnum.HOME_OWNER && accommodationExpense) {
      const { situation, declaredFrequency, declaredAmount, declaredTotalAmount, declaredTotalFrequency } =
        accommodationExpense;
      const isExpenseShared = situation === AccommodationExpenseEnum.SHARED;

      const expenseData = {
        networthSourceId,
        addressId: activeAddressId,
        isExpenseShared,
      };

      if (isExpenseShared) {
        expensesData.push({
          ...expenseData,
          declaredAmount: declaredAmount || undefined,
          frequency: declaredFrequency || undefined,
          declaredTotalAmount: declaredTotalAmount || undefined,
          frequencyForTotalAmount: declaredTotalFrequency || undefined,
        });
      } else {
        expensesData.push({
          ...expenseData,
          declaredAmount: declaredAmount || undefined,
          frequency: declaredFrequency || undefined,
        });
      }
    }

    if (!childSupport) return expensesData;

    if (stringToBool(childSupport.hasChildSupportPaid)) {
      expensesData.push({
        networthSourceId: NetworthSourceEnum.EXPENSE_CHILD_SUPPORT_ID,
        declaredAmount: childSupport.childSupportPaidAmount,
        frequency: childSupport.childSupportPaidFrequency as IncomeAndExpenseFrequencyEnum,
        deductedFromIncome: stringToBool(childSupport.hasChildSupportDeducted),
      });
    }

    return expensesData;
  };

  const submitChildSupportIncome = async (data) => {
    await submitIncome({
      taskId,
      incomes: [
        {
          networthSourceId: NetworthSourceEnum.INCOME_CHILD_SUPPORT_ID,
          declaredAmount: data?.childSupport?.childSupportReceivedAmount,
          frequency: data?.childSupport?.childSupportReceivedFrequency as IncomeAndExpenseFrequencyEnum,
        },
      ],
    });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Tell us about your <span className="text-primary">household</span>
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <RadioSelectSelector
            name="livingType"
            label="What is your living situation?"
            radioOptions={primaryLivingSituationOptions}
            selectOptions={otherLivingSituationOptions}
            onChange={handleFormChange}
          />
        </Card>

        {formChanges?.livingType?.primary && (
          <>
            {isAccommodationExpenseFormType &&
              formChanges?.livingType?.primary !== ResidentialStatusEnum.HOME_OWNER && (
                <AccommodationExpenseCard
                  register={register}
                  livingType={formChanges?.livingType?.primary as ResidentialStatusEnum}
                  watchAccommodationExpense={watchAccommodationExpense}
                  shouldDisplaySharedExpense={shouldDisplaySharedExpense(
                    userProfile?.relationshipStatus as RelationshipStatusEnum
                  )}
                />
              )}

            <DependantsCard
              register={register}
              hasDependants={stringToBool(formChanges?.dependants?.hasDependants)}
              onFormChange={handleFormChange}
            />

            {isChildSupportRelatedFormType && stringToBool(formChanges?.dependants?.hasDependants) && (
              <ChildSupportCard
                register={register}
                hasChildSupportReceived={stringToBool(formChanges?.childSupport?.hasChildSupportReceived)}
                hasChildSupportPaid={stringToBool(formChanges?.childSupport?.hasChildSupportPaid)}
              />
            )}
          </>
        )}

        <Button
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
          type="submit"
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

import { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import classNames from 'classnames';

import { CommonOptionProps } from '../../types';
import { AmountInput } from '../AmountInput';
import { HelpText } from '../HelpText';
import { Label } from '../Label';
import { ControlledSelect } from '../Select/ControlledSelect';
import { ValidationMessage } from '../ValidationMessage';

interface AmountFrequencyProps {
  /**
   * Input name
   */
  name: string;

  /**
   * Determine the layout of the radio items
   */
  label?: string | React.ReactNode;
  inputKey: string;
  selectKey: string;
  helpText?: string;
  /**
   * The items to display in the radio group.
   * @param label The label to display for the item.
   * @param value The value of the item.
   * @param rightLabel The label to display to the right of the item.
   * @param imageSrc The image to display to the left of the item.
   */
  options: CommonOptionProps[];
  inputClassName?: string;
  selectClassName?: string;
  className?: string;
  register?: any;
}

export const AmountFrequency = forwardRef<HTMLInputElement, AmountFrequencyProps>(
  (
    {
      className,
      name,
      label,
      inputKey,
      selectKey,
      helpText,
      selectClassName,
      inputClassName,
      options,
      ...restProps
    }: AmountFrequencyProps,
    _: React.Ref<HTMLInputElement>
  ) => {
    const { control, register, getFieldState } = useFormContext();

    const hasError = getFieldState(inputKey).invalid || getFieldState(selectKey).invalid;

    useController({
      name,
      control,
      defaultValue: '',
    });

    return (
      <div className={className}>
        {label && (
          <Label htmlFor={name} className="mb-2">
            {label}
          </Label>
        )}
        {helpText && <HelpText>{helpText}</HelpText>}
        <div
          className={classNames(
            hasError ? 'border-error' : 'border-grey-2',
            'focus-within:border-secondary flex rounded-md border bg-white'
          )}
        >
          <div className={classNames(inputClassName?.includes('w-') ? inputClassName : 'w-3/6 md:w-4/6')}>
            <AmountInput
              className={classNames('mb-5', inputClassName)}
              formFieldClass={classNames('border-none')}
              maxLength={8}
              displayValidation={false}
              {...register(inputKey)}
              type="text"
            />
          </div>
          <div
            className={classNames(
              selectClassName?.includes('w-') ? selectClassName : 'w-3/6 md:w-2/6',
              "before:bg-grey-4 relative my-auto mx-4 h-full before:absolute before:top-1/4 before:left-0 before:h-1/2 before:w-[0.0625rem] before:content-['']"
            )}
          >
            <ControlledSelect
              {...register(selectKey)}
              formFieldClassName="border-none mb-0 pr-0"
              className={classNames('mb-0', selectClassName)}
              options={options}
              displayValidation={false}
            />
          </div>
        </div>
        <ValidationMessage name={inputKey} />
        <ValidationMessage name={selectKey} />
      </div>
    );
  }
);

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const TZ_AU_SYDNEY = 'Australia/Sydney';

export const dayjsSydney = (date?: string | Date) => dayjs.tz(date, TZ_AU_SYDNEY);

export const dayjsUTCToSydney = (date?: string | Date) => dayjs.utc(date).tz(TZ_AU_SYDNEY);

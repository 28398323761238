import { Fragment } from 'react';
import { FinancialProfileDto, NetworthSourceEnum } from '@harmoney/api-interfaces';
import { Card, CurrencyWithFrequency, Divider, Label } from '@harmoney/ui-design-system';
import { capitalizeTitle, replaceChar } from '@harmoney/ui-utils';
import { IncomeAndExpenseFrequencyEnum } from '@prisma/client';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

const IncomeItem = ({
  label,
  helpText,
  amount,
  frequency,
}: {
  label: string;
  amount: number;
  frequency?: IncomeAndExpenseFrequencyEnum;
  helpText?: string;
}) => (
  <Fragment>
    <div className="flex items-center justify-between p-4">
      <Label className="flex flex-col">
        <span> {label}</span>
        {helpText && <span className="text-sm">({helpText})</span>}
      </Label>
      <CurrencyWithFrequency amount={amount} frequency={frequency} />
    </div>
    <Divider className="text-grey-1 m-0 px-4" />
  </Fragment>
);

const HouseholdIncome = ({
  income: { declaredAmount, frequency },
}: {
  income: FinancialProfileDto['incomes'][number];
}) => (
  <Card className="!p-0">
    <Label className="p-4 text-lg font-medium">Household income</Label>
    <Divider className="text-grey-2 m-0 p-0" />
    <div className="flex items-center justify-between p-4">
      <Label>Household income</Label>
      <CurrencyWithFrequency amount={declaredAmount} frequency={frequency} />
    </div>
  </Card>
);

const YourIncome = ({ incomes }: { incomes: FinancialProfileDto['incomes'] }) => (
  <Card className="!p-0">
    <Label className="p-4 text-lg font-medium">Your income</Label>
    <Divider className="text-grey-2 m-0 p-0" />
    {incomes.map(
      ({
        id,
        networthSourceId,
        networthSourceName,
        otherIncomeType,
        employmentType,
        selfEmploymentType,
        seasonalWorkingMonths,
        frequency,
        declaredAmount,
        isRentalIncomeShared,
        employmentStartDate,
        benefitType,
        payType,
      }) => {
        const employmentTypeFormatted = capitalize(replaceChar(employmentType));
        const employmentStartDateFormatted = dayjs(employmentStartDate).format('MMMM YYYY');
        const seasonalWorkingMonthsFormatted = seasonalWorkingMonths ? `, ${seasonalWorkingMonths}` : '';

        const labelMapper: Partial<Record<NetworthSourceEnum, string>> = {
          [NetworthSourceEnum.INCOME_OTHER_ID]: capitalizeTitle(otherIncomeType),
          [NetworthSourceEnum.INCOME_NO_ID]: 'Income',
        };

        const helpTextMapper: Partial<Record<NetworthSourceEnum, string>> = {
          [NetworthSourceEnum.INCOME_SALARY_WAGES_ID]: `${employmentTypeFormatted}${seasonalWorkingMonthsFormatted}, since ${employmentStartDateFormatted}`,
          [NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID]: capitalize(replaceChar(selfEmploymentType)),
          [NetworthSourceEnum.INCOME_BENEFIT_ID]: capitalize(benefitType),
          [NetworthSourceEnum.INCOME_RENT_ID]: isRentalIncomeShared === 'Yes' ? 'Shared income' : 'Individual income',
          [NetworthSourceEnum.INCOME_PARTNER_ID]: `${capitalize(payType)} pay`,
        };

        return (
          <IncomeItem
            key={`incomes-${id}`}
            label={labelMapper[networthSourceId] ?? capitalizeTitle(networthSourceName)}
            helpText={helpTextMapper[networthSourceId]}
            amount={declaredAmount}
            frequency={frequency}
          />
        );
      }
    )}
  </Card>
);

export const Incomes = ({ financialSummary }: { financialSummary: FinancialProfileDto }) => {
  const categorizeIncomes = (
    incomes: FinancialProfileDto['incomes']
  ): {
    householdIncome: FinancialProfileDto['incomes'][number];
    incomesWithoutHousehold: FinancialProfileDto['incomes'];
  } => {
    return incomes.reduce(
      (accumulator, income) => {
        if (income.networthSourceId === NetworthSourceEnum.INCOME_HOUSEHOLD_ID) {
          accumulator.householdIncome = income;
        } else {
          accumulator.incomesWithoutHousehold.push(income);
        }
        return accumulator;
      },
      { householdIncome: null, incomesWithoutHousehold: [] }
    );
  };

  const { householdIncome, incomesWithoutHousehold } =
    financialSummary?.incomes?.length > 0
      ? categorizeIncomes(financialSummary.incomes)
      : { householdIncome: null, incomesWithoutHousehold: [] };

  return (
    <>
      {householdIncome && <HouseholdIncome income={householdIncome} />}
      {incomesWithoutHousehold.length > 0 && <YourIncome incomes={incomesWithoutHousehold} />}
    </>
  );
};

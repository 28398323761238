import React from 'react';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import { useClipboard } from '@mantine/hooks';

import { Button } from '../Button';

import 'react-toastify/dist/ReactToastify.css';
import styles from './CopyButton.module.scss';

export interface CopyButtonProps {
  withText?: boolean;
  valueToCopy: string;
  size?: 'small' | 'medium';
}

export const CopyButton = ({ withText = false, valueToCopy, size = 'medium' }: CopyButtonProps) => {
  const clipboard = useClipboard();

  const handleCopy = () => {
    clipboard.copy(valueToCopy);
    toast.success('Copied to clipboard!', {
      position: 'bottom-center',
      hideProgressBar: true,
      autoClose: 2500,
      closeButton: false,
      className: 'border bg-tertiary-lighter-3 border-tertiary !p-0 !mx-4 !mb-4 rounded-lg',
      bodyClassName: 'bg-tertiary-lighter-3 !m-0 !p-4 text-grey-5 text-sm',
    });
  };
  return (
    <Button
      variant="text"
      alignIcon="start"
      size={size}
      className={styles['copy-btn']}
      icon={<Icon icon="mdi:content-copy" className="text-secondary" width={size === 'small' ? 16 : 24} />}
      onClick={handleCopy}
    >
      {withText && 'COPY'}
    </Button>
  );
};

import React, { useMemo } from 'react';
import { NetworthSourceEnum } from '@harmoney/api-interfaces';
import { NumberFormatter } from '@harmoney/ui-utils';
import { convertToMonthlyAmount } from '@harmoney/utilities';
import { AssetAndLiability, FinancialProfile, IncomeAndExpense, NetworthSource, Prisma } from '@prisma/client';

type Props = {
  affordabilityData: Prisma.JsonValue;
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
};

export type RowData = {
  title: string;
  final: string;
  minAcceptableValue?: string;
  declared?: string;
  bankStatement?: string;
};

export const DebtsTable = ({ affordabilityData, financialProfileData }: Props) => {
  const getMonthlyDeclaredTotalMortgageRepayment = (financialProfileData): number => {
    return financialProfileData.asset_and_liability.reduce((acc, item) => {
      if (item.networthSourceId === NetworthSourceEnum.LIABILITY_MORTGAGE_ID) {
        return acc + convertToMonthlyAmount(item.repaymentAmount, item.frequency);
      }
      return acc;
    }, 0);
  };

  const rowsData: RowData[] = useMemo(() => {
    return [
      {
        title: 'Mortgage',
        declared: NumberFormatter(getMonthlyDeclaredTotalMortgageRepayment(financialProfileData)),
        bankStatement: null,
        minAcceptableValue: NumberFormatter(affordabilityData?.['debt']?.['calculatedMortgageRepayment'] as number),
        final: NumberFormatter(affordabilityData?.['finalDebt']?.['mortgageRepayment'] as number),
      },
      {
        title: 'BNPL',
        declared: NumberFormatter(affordabilityData?.['debt']?.['declaredBuyNowPayLaterRepayment'] as number),
        bankStatement: NumberFormatter(affordabilityData?.['decisionMetrics']?.['DM085'] as number),
        minAcceptableValue: null,
        final: NumberFormatter(0),
      },
      {
        title: 'Credit Card',
        declared: affordabilityData?.['debt']?.['declaredCreditCardRepayment']
          ? NumberFormatter(affordabilityData?.['debt']?.['declaredCreditCardRepayment'] as number)
          : NumberFormatter(affordabilityData?.['debt']?.['declaredCreditCardLimit'] as number),
        bankStatement: NumberFormatter(affordabilityData?.['decisionMetrics']?.['DM015'] as number),
        minAcceptableValue: NumberFormatter(affordabilityData?.['finalDebt']?.['creditCardRepayment'] as number),
        final: NumberFormatter(affordabilityData?.['finalCreditCardRepayment'] as number),
      },
      {
        title: 'Overdraft',
        declared: NumberFormatter(affordabilityData?.['debt']?.['declaredOverdraftRepayment'] as number),
        bankStatement: null,
        minAcceptableValue: null,
        final: NumberFormatter((affordabilityData?.['finalDebt']?.['overdraftRepayment'] as number) ?? 0),
      },
      {
        title: 'Personal Loan',
        declared: NumberFormatter(affordabilityData?.['debt']?.['declaredPersonalLoanRepayment'] as number),
        bankStatement: null,
        minAcceptableValue: NumberFormatter(affordabilityData?.['debt']?.['calculatedPersonalLoanRepayment'] as number),
        final: NumberFormatter(affordabilityData?.['finalDebt']?.['personalLoanRepayment'] as number),
      },
      {
        title: 'SACC Loan',
        declared: null,
        bankStatement: NumberFormatter(affordabilityData?.['decisionMetrics']?.['DM010'] as number),
        minAcceptableValue: null,
        final: null,
      },
      {
        title: 'Non-SACC',
        declared: null,
        bankStatement: NumberFormatter(affordabilityData?.['decisionMetrics']?.['DM041'] as number),
        minAcceptableValue: null,
        final: null,
      },
    ];
  }, [affordabilityData]);
  return (
    <table className="border-grey-2 w-full table-fixed border">
      <thead>
        <tr className="divide-grey-2 bg-grey-1 divide-x">
          <th className="w-1/5">Debts Repayments</th>
          <th className="w-1/5">Declared</th>
          <th className="w-1/5">Bank Statement</th>
          <th className="w-1/5">Minimum Acceptable value</th>
          <th className="w-1/5">Final</th>
        </tr>
      </thead>
      <tbody>
        {rowsData.map((row) => (
          <tr key={row.title} className="divide-grey-2 border-grey-2 divide-x border">
            <td className="bg-grey-1 px-2 py-1 font-bold">{row.title}</td>
            <td className={`px-2 text-right ${row.declared ? '' : 'bg-grey-2'}`}>{row.declared ?? ''}</td>
            <td className={`px-2 text-right ${row.bankStatement ? '' : 'bg-grey-2'}`}>{row.bankStatement ?? ''}</td>
            <td className={`px-2 text-right ${row.minAcceptableValue ? '' : 'bg-grey-2'}`}>
              {row.minAcceptableValue ?? ''}
            </td>
            <td className="px-2 text-right">{row.final}</td>
          </tr>
        ))}
        <tr>
          <td />
          <td />
          <td />
          <td className="bg-grey-1 border-x-grey-2 border-x px-2 py-1 font-bold">Total Debts Repayments</td>
          <td className="px-2 text-right">{NumberFormatter(affordabilityData?.['finalTotalDebt'] as number)}</td>
        </tr>
      </tbody>
    </table>
  );
};

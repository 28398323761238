import React, { useMemo } from 'react';
import { LoanApplicationQueryResponse, UserInstanceQueryResponse } from '@harmoney/redux';
import { Divider, getIconComponent } from '@harmoney/ui-design-system';
import {
  convertMonthsToYears,
  FrequencyFormatter,
  isApplication,
  isQuoteInReview,
  isQuoteOffered,
  isQuoteOfferedAndDebtConConsolidated,
  isValidApplication,
} from '@harmoney/ui-utils';
import { formatCurrency, isDebtCon, isPersonalLoan } from '@harmoney/utilities';
import { kebabCase } from 'lodash';

type Props = {
  loanApplication: LoanApplicationQueryResponse;
  userInstances?: UserInstanceQueryResponse;
};

export const LoanDetails = ({ loanApplication, userInstances }: Props) => {
  const amountToDisplay: {
    display: 'Amount requested' | 'Borrow up to' | 'Amount' | 'Consolidate up to';
    amount: number;
  } = useMemo(() => {
    if (!loanApplication) return { display: 'Amount requested', amount: 0 };
    if (isApplication(loanApplication?.status) && loanApplication?.fundedAmount)
      return {
        display: 'Amount',
        amount: loanApplication?.fundedAmount + loanApplication?.establishmentFee,
      };
    if (userInstances && isQuoteOfferedAndDebtConConsolidated(loanApplication, userInstances)) {
      return {
        display: 'Amount',
        amount: loanApplication?.fundedAmount,
      };
    }
    if (isQuoteOffered(loanApplication?.status) && loanApplication?.maximumBorrowingLimit) {
      return {
        display: isDebtCon(loanApplication?.loanProduct?.name) ? 'Consolidate up to' : 'Borrow up to',
        amount: loanApplication?.quotePresentedAmount || loanApplication?.maximumBorrowingLimit,
      };
    }
    if (isQuoteOffered(loanApplication?.status) && loanApplication?.originalLoanApplicationId) {
      return {
        display: 'Borrow up to',
        amount: loanApplication?.quotePresentedAmount,
      };
    }
    return {
      display: 'Amount requested',
      amount: loanApplication?.requestedAmount,
    };
  }, [loanApplication, userInstances]);
  return (
    <div className="border-b-grey-2 border-b p-4">
      {loanApplication?.loanApplicationPurposes?.[0]?.loanPurpose?.name && (
        <>
          <div className="flex flex-row items-center space-x-2">
            <span className="flex items-center font-medium">
              {loanApplication?.loanApplicationPurposes?.[0]?.loanPurpose?.name &&
                getIconComponent(
                  kebabCase(loanApplication?.loanApplicationPurposes?.[0]?.loanPurpose?.name),
                  'secondary'
                )}
              <span className="ml-2">
                {loanApplication?.loanApplicationPurposes
                  ?.map((purpose) => purpose?.loanPurpose?.displayName)
                  .join(' + ')}
              </span>
            </span>
          </div>
          <Divider className="text-grey-1 my-4" />
        </>
      )}
      {(isPersonalLoan(loanApplication?.loanProduct?.name) ||
        (isDebtCon(loanApplication?.loanProduct?.name) &&
          (isValidApplication(loanApplication?.status) || isQuoteOffered(loanApplication.status)))) && (
        <>
          <div className="ml-4 grid grid-cols-2">
            <div className="col-span-1 flex flex-col items-stretch justify-start space-y-0">
              <span>{amountToDisplay.display}</span>
              <span className="text-xl font-medium">{formatCurrency(amountToDisplay.amount)}</span>
            </div>
            <div className="col-span-1 flex flex-col items-stretch justify-center space-y-0 ml-6">
              <span>Interest rate</span>
              {loanApplication.finalInterestRate && !isQuoteInReview(loanApplication?.status) ? (
                <span className="text-xl font-medium">{loanApplication.finalInterestRate as unknown as number}%</span>
              ) : (
                <span className="text-grey-4 text-sm font-light">
                  {isQuoteInReview(loanApplication?.status)
                    ? 'Pending'
                    : 'Complete your quote to get your interest rate.'}
                </span>
              )}
            </div>
          </div>
        </>
      )}
      {isValidApplication(loanApplication?.status) && (
        <>
          <Divider className="text-grey-1 my-4" />
          <div className="ml-4 grid grid-cols-2">
            <div className="col-span-1 flex flex-col items-stretch justify-start space-y-0">
              <span>Repayment</span>
              {loanApplication?.repaymentInfo?.recurringRepaymentAmount &&
                !isNaN(loanApplication?.repaymentInfo?.recurringRepaymentAmount) && (
                  <span className="text-xl font-medium">
                    {formatCurrency(loanApplication?.repaymentInfo?.recurringRepaymentAmount)}
                    <span className="text-sm font-normal">
                      /{FrequencyFormatter(loanApplication?.repaymentInfo?.frequency)}
                    </span>
                  </span>
                )}
            </div>
            <div className="col-span-1 flex flex-col items-stretch justify-center space-y-0 ml-6">
              <span>Term</span>
              <span className="text-xl font-medium">{convertMonthsToYears(loanApplication?.termInMonths)}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

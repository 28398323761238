import { DebtConsolidationLiabilityDto, DebtConsolidationObjectDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const debtConsolidationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDebtConsolidation: builder.query<
      DebtConsolidationObjectDto,
      { financialProfileId: string; interestRate: number; maximumBorrowingLimit: number; hmyRepaymentAmount?: number }
    >({
      query: ({ financialProfileId, interestRate, maximumBorrowingLimit, hmyRepaymentAmount }) => ({
        url: `/debt-consolidation/objective`,
        method: 'GET',
        params: {
          financialProfileId,
          interestRate,
          maximumBorrowingLimit,
          hmyRepaymentAmount,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    reprocessObjective: builder.query<
      DebtConsolidationObjectDto,
      {
        debts: DebtConsolidationLiabilityDto[];
        interestRate: number;
        maximumBorrowingLimit: number;
        hmyRepaymentAmount: number;
      }
    >({
      query: ({ debts, interestRate, maximumBorrowingLimit, hmyRepaymentAmount }) => ({
        url: `/debt-consolidation/reprocess-objective`,
        method: 'POST',
        body: {
          debts,
          interestRate,
          maximumBorrowingLimit,
          hmyRepaymentAmount,
        },
      }),
    }),
  }),
});

export const { useGetDebtConsolidationQuery, useLazyReprocessObjectiveQuery } = debtConsolidationApi;

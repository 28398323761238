import { useMemo } from 'react';
import { useBranch, useFriendlyURL } from '@harmoney/hooks';
import { useUpdateUserMutation } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Card, ControlledInput, Form, Label, useForm } from '@harmoney/ui-design-system';
import { errors } from '@harmoney/ui-utils';
import { BranchEnum } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

export type Country = {
  countryCode: string;
  countryPhoneLabel: string;
  minLength?: number;
  maxLength?: number;
  firstDigit?: string;
};

export const RegionalText: Record<BranchEnum, Country> = {
  [BranchEnum.AU]: {
    countryCode: '+61',
    countryPhoneLabel: 'Australian',
    minLength: 9,
    maxLength: 10,
    firstDigit: '4',
  },
  [BranchEnum.NZ]: {
    countryCode: '+64',
    countryPhoneLabel: 'New Zealand',
    minLength: 8,
    maxLength: 10,
    firstDigit: '2',
  },
};

export function PhoneNumberVerification({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const [updateUser] = useUpdateUserMutation();
  const branch = useBranch();

  const handleSubmit = async (data) => {
    await updateUser(data);
    completeTask({ taskId });
  };

  // regex to allow only digits in phone number
  const phoneNumberRegex = /^\d+$/;

  const prefix = useMemo(() => {
    if (branch) {
      return RegionalText[branch]?.countryCode;
    }
  }, [branch]);

  const formSchema = z.object({
    mobilePhoneNumber: z
      .string({
        required_error: errors.invalid('phone number'),
        invalid_type_error: errors.invalid('phone number'),
      })
      .min(RegionalText[branch]?.minLength, errors.invalid('phone number'))
      .max(RegionalText[branch]?.maxLength, errors.invalid('phone number'))
      .regex(phoneNumberRegex, errors.invalid('phone number'))
      .transform((value) => {
        // if value starts with 0, remove it
        if (value.startsWith('0')) {
          return prefix + value.slice(1);
        }
        return prefix + value;
      })
      .refine((value) => {
        return value.startsWith(`${RegionalText[branch]?.countryCode}${RegionalText[branch]?.firstDigit}`);
      }, errors.invalid('phone number'))
      .refine((value) => {
        const auValueLengthWithPrefix =
          RegionalText[BranchEnum.AU]?.countryCode.length + RegionalText[BranchEnum.AU]?.minLength;
        if (branch === BranchEnum.AU && value.length !== auValueLengthWithPrefix) {
          return false;
        }
        return true;
      }, errors.invalid('phone number')),
  });

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      mobilePhoneNumber: '',
    },
  });

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  useFriendlyURL(taskFriendlyURL);

  return (
    <div className="flex flex-col">
      <h1>
        What is your <span className="text-primary">phone number</span>?
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <Label className="mb-4">Please enter your {RegionalText[branch]?.countryPhoneLabel} phone number</Label>
          <ControlledInput
            prefix={prefix}
            {...register('mobilePhoneNumber')}
            type="text"
            maxLength={RegionalText[branch]?.maxLength}
            isNumeric
          />
        </Card>
        <Button
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          type="submit"
          className="mt-6"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}

import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Layout, NestedLayout } from '@harmoney/components';
import { useBranchFromUrl } from '@harmoney/hooks';
import { injectStore, setupStore } from '@harmoney/redux';
import { GTMScript, isDevelopment, isProduction, useEventAnalytics } from '@harmoney/ui-app-shell';
import { Spinner } from '@harmoney/ui-design-system';
import { startsWith, trimStart } from 'lodash';

import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

// eslint-disable-next-line
import 'react-toastify/dist/ReactToastify.minimal.css';
import '../../../libs/ui-design-system/src/global-styles/styles.scss';

const store = setupStore();
injectStore(store);

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  useEventAnalytics();

  const router = useRouter();
  const getIAMParams = () => {
    const query = new URLSearchParams(router.asPath.split(/\?/)[1]);
    const authorizationParams = {};
    query.forEach((value, key) => {
      if (startsWith(key, 'iam_')) {
        authorizationParams[trimStart(key, 'iam_')] = value;
      }
    });
    return authorizationParams;
  };

  const getLayout =
    Component.getLayout ||
    (() => {
      return (
        <Layout>
          <NestedLayout>
            <div className="mx-auto max-w-xl p-4">
              <Component {...pageProps} />
            </div>
          </NestedLayout>
        </Layout>
      );
    });

  const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
  const auth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
  const auth0Audience = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE;

  const [auth0RedirectUri, setAuth0RedirectUri] = useState('');
  const branch = useBranchFromUrl();

  useEffect(() => {
    if (typeof window !== undefined) {
      setAuth0RedirectUri(window.location.origin);
    }
  }, []);

  if (!auth0RedirectUri) return <Spinner />;

  return (
    <>
      {isProduction() && <GTMScript GTM_ID={process.env.NEXT_PUBLIC_GTM_ID} />}
      <ErrorBoundary>
        <Auth0Provider
          domain={auth0Domain}
          clientId={auth0ClientId}
          cacheLocation={'localstorage'}
          useRefreshTokens={!isDevelopment()}
          authorizationParams={{
            redirect_uri: auth0RedirectUri,
            audience: auth0Audience,
            acr_values: auth0RedirectUri,
            'ext-branch': branch,
            ...getIAMParams(),
          }}
          onRedirectCallback={(appState?: AppState) => {
            if (appState?.returnTo !== window.location.href) {
              window.location.href = appState?.returnTo || auth0RedirectUri;
            }
          }}
        >
          <Provider store={store}>{getLayout(<Component {...pageProps} />)}</Provider>
          <ToastContainer className="!mx-auto" />
        </Auth0Provider>
        {isProduction() && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://${window.location.hostname.endsWith('.nz') ? 'analytics.harmoney.co.nz' : 'analytics.harmoney.com.au'}/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}" height="0" width="0" style="display: none; visibility: hidden;" />`,
            }}
          />
        )}
      </ErrorBoundary>
    </>
  );
}

export default CustomApp;

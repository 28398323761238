import {
  CommonOptionProps,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@harmoney/ui-design-system';
import { capitalizeTitle } from '@harmoney/ui-utils';

export type ActionCenterGroup = {
  name: string;
  options: CommonOptionProps[];
};

type Props = {
  actionsGroup: ActionCenterGroup[];
  isHeader?: boolean;
};

export const ActionControl = ({ actionsGroup, isHeader = false }: Props) => {
  return (
    <NavigationMenu className="my-4">
      <NavigationMenuList>
        {actionsGroup.map(({ name, options }) => (
          <NavigationMenuItem key={name}>
            <NavigationMenuTrigger isHeader={isHeader}>{name}</NavigationMenuTrigger>
            <NavigationMenuContent>
              <div className="flex flex-col gap-2 p-4">
                {options.map(({ label, onClick, value, disabled }) => (
                  <NavigationMenuLink
                    onClick={(e) => {
                      e.preventDefault();
                      onClick();
                    }}
                    disabled={disabled}
                    key={value}
                  >
                    {typeof label === 'string' && capitalizeTitle(label)}
                  </NavigationMenuLink>
                ))}
              </div>
            </NavigationMenuContent>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
};

import { LoanApplicationDto, ResidencyStatusMapping } from '@harmoney/api-interfaces';
import { Card, Divider, Label } from '@harmoney/ui-design-system';

export const DebtConLoanDetails = ({ loanApplicationData }: { loanApplicationData: LoanApplicationDto }) => {
  return (
    <Card className="!p-0">
      <div className="flex items-center justify-between">
        <Label className="p-4 text-lg font-medium">Loan</Label>
      </div>
      <Divider className="text-grey-2 m-0 p-0" />

      <div className="flex items-center justify-between p-4">
        <Label>Purpose</Label>
        <span className="font-medium">
          {loanApplicationData?.loanApplicationPurposes.length > 0 &&
            loanApplicationData?.loanApplicationPurposes[0].loanPurpose.displayName}
        </span>
      </div>
      <Divider className="text-grey-2 m-0 px-4" />

      <div className="flex items-center justify-between p-4">
        <Label>Residency status</Label>
        <span className="font-medium">{ResidencyStatusMapping[loanApplicationData?.residencyStatus]}</span>
      </div>
    </Card>
  );
};

import { useEffect, useMemo } from 'react';
import { ResidencyStatusMapping } from '@harmoney/api-interfaces';
import { useAppSelector, useGetUserByIdQuery, useGetUserProfileForAdminQuery } from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { booleanToYesNo, DATE_FORMAT, valueOrNA } from '@harmoney/ui-utils';
import { Address, User, UserProfile } from '@prisma/client';
import dayjs from 'dayjs';

import { Field } from './shared';

interface CustomerDetailsProps {
  userProfileData: UserProfile & { addresses: Address[] };
  userData: User;
  userId?: string;
}

export const CustomerDetails = (params: CustomerDetailsProps) => {
  const userId = params.userId;
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userDataResult } = useGetUserByIdQuery(userId as string, { skip: !token || !userId });
  const { data: userProfileDataResult } = useGetUserProfileForAdminQuery(userId as string, { skip: !token || !userId });
  const userProfileData = useMemo(() => {
    if (params.userProfileData) {
      return params.userProfileData;
    }
    if (userProfileDataResult) {
      return userProfileDataResult;
    }
  }, [params, userProfileDataResult]);
  const userData = useMemo(() => {
    if (params.userData) {
      return params.userData;
    }
    if (userDataResult) {
      return userDataResult;
    }
  }, [params, userDataResult]);

  const addresses = useMemo(() => {
    return userProfileData?.addresses?.map((address) => {
      const addressLine = `${address.streetNumber || ''} ${address.streetName || ''}, ${
        address.suburb ? `${address.suburb},` : ''
      } ${address.state || ''} ${address.postalCode} ${address.country}`.trim();

      return {
        id: address.id,
        addressLine,
        isCurrent: address.isCurrent,
      };
    });
  }, [userProfileData?.addresses]);

  useEffect(() => {
    document.title = userData?.businessKey;
  }, [userData]);

  return (
    <CollapsibleCard title="Customer Details" className="mb-6" disabled>
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field className="grid-cols-[30%_80%]">
            <span>Customer Id:</span>
            <span className="pr-6 break-words">{valueOrNA(userData?.id)}</span>
          </Field>
          <Field className="grid-cols-[30%_80%]">
            <span>Customer Number:</span>
            <span>{valueOrNA(userData?.businessKey)}</span>
          </Field>
          <Field className="grid-cols-[30%_80%]">
            <span>First name:</span>
            <span>{valueOrNA(userProfileData?.firstName)}</span>
          </Field>
          <Field className="grid-cols-[30%_80%]">
            <span>Middle name:</span>
            <span>{valueOrNA(userProfileData?.middleName)}</span>
          </Field>
          <Field className="grid-cols-[30%_80%]">
            <span>Last name:</span>
            <span>{valueOrNA(userProfileData?.lastName)}</span>
          </Field>
          <Field className="grid-cols-[30%_80%]">
            <span>Residency status:</span>
            <span>{valueOrNA(ResidencyStatusMapping[userData?.residencyStatus])}</span>
          </Field>
          <Field className="grid-cols-[30%_80%]">
            <span>Relationship status:</span>
            <span>{valueOrNA(userProfileData?.relationshipStatus)}</span>
          </Field>
          <Field className="grid-cols-[30%_80%]">
            <span>Dependants:</span>
            <span>{valueOrNA(userProfileData?.numberOfDependants)}</span>
          </Field>
        </div>
        <div className="p-4 pr-12">
          <Field className="grid grid-cols-[30%_80%]">
            <span>Email address:</span>
            <span className="break-all">{valueOrNA(userData?.email)}</span>
          </Field>
          <Field className="grid grid-cols-[30%_80%]">
            <span>Email verified:</span>
            <span>{booleanToYesNo(userData?.emailVerified)}</span>
          </Field>
          <Field className="grid grid-cols-[30%_80%]">
            <span>Mobile phone:</span>
            <span>{valueOrNA(userData?.mobilePhoneNumber)}</span>
          </Field>
          <Field className="grid grid-cols-[30%_80%]">
            <span>DOB:</span>
            <span>
              {userProfileData?.dateOfBirth ? dayjs(userProfileData?.dateOfBirth).format(DATE_FORMAT) : 'N/A'}
            </span>
          </Field>
          <Field className="grid grid-cols-[30%_80%]">
            <span>Age:</span>
            <span>{userProfileData?.dateOfBirth ? dayjs().diff(userProfileData?.dateOfBirth, 'year') : 'N/A'}</span>
          </Field>
          <Field className="grid grid-cols-[30%_80%]">
            <span>Current Address:</span>
            <span>{valueOrNA(addresses?.find((address) => address.isCurrent)?.addressLine)}</span>
          </Field>
          {addresses?.length > 1 && (
            <Field className="grid grid-cols-[30%_80%]">
              <span>Previous Address(es):</span>
              <span>{valueOrNA(addresses?.find((address) => !address.isCurrent)?.addressLine)}</span>
            </Field>
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};

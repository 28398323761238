import { BranchEnum } from '@prisma/client';

/* The `branchMapper` is a constant variable that maps the enum values of
`BranchEnum` to their corresponding string representations. */
export const branchMapper: Record<BranchEnum, string> = {
  [BranchEnum.AU]: 'AU',
  [BranchEnum.NZ]: 'NZ',
};

export const branchEmojiMapper: Record<BranchEnum, string> = {
  [BranchEnum.AU]: '🇦🇺',
  [BranchEnum.NZ]: '🇳🇿',
};

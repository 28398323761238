import React, { useMemo } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { AmountInput, Button, ControlledSelect, Label } from '@harmoney/ui-design-system';
import { CommonOptionProps } from '@harmoney/ui-design-system';
import {
  dayjsSydney,
  EXPIRY_DAYS_FOR_UNUTILISED_LIMIT,
  formatCurrency,
  MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT,
} from '@harmoney/utilities';
import { Icon } from '@iconify/react';

import { FormSchema } from '../form-config';

interface SecondaryPurposeFormProps {
  loanApplicationData: LoanApplicationDto & { bankStatementReferences: any[] };
  remainingAmount: number;
  availableSecondaryPurposes: CommonOptionProps[];
  maxExtraLimitForSecondaryPurpose: number;
  errorMsgForSecondaryPurposeAmount: string;
  register: UseFormRegister<FormSchema>;
  onDeleteClick: () => void;
}

export const SecondaryPurposeForm: React.FC<SecondaryPurposeFormProps> = ({
  loanApplicationData,
  remainingAmount,
  availableSecondaryPurposes,
  maxExtraLimitForSecondaryPurpose,
  errorMsgForSecondaryPurposeAmount,
  onDeleteClick,
  register,
}: SecondaryPurposeFormProps) => {
  const loanPurposes = useMemo(() => {
    if (!availableSecondaryPurposes) {
      return [];
    }
    return [
      {
        label: 'Select loan purpose',
        value: '',
        iconName: 'more-loan-purposes',
      },
      ...availableSecondaryPurposes,
    ];
  }, [availableSecondaryPurposes]);

  return (
    <div>
      <div className="p-4">
        <fieldset className="mb-4">
          <Label className="mb-2">
            Borrow up to an extra <strong>{formatCurrency(maxExtraLimitForSecondaryPurpose)}</strong>
          </Label>
          <AmountInput {...register('secondaryPurposeAmount')} maxLength={6} displayValidation={false} />
          {errorMsgForSecondaryPurposeAmount && (
            <p role="alert" aria-live="assertive" className="leading-sm text-error mt-1 block text-sm mb-0">
              {errorMsgForSecondaryPurposeAmount}
            </p>
          )}
        </fieldset>
        <fieldset className="mb-4">
          <Label className="mb-2">What would you like to use it for?</Label>
          <ControlledSelect {...register('secondaryPurposeId')} options={loanPurposes} />
        </fieldset>
        <div className="text-right">
          <Button variant="text" onClick={onDeleteClick}>
            Delete
          </Button>
        </div>
      </div>
      {remainingAmount !== maxExtraLimitForSecondaryPurpose &&
        loanApplicationData.isEligibleForExtraLimit &&
        loanApplicationData.hasActiveMortgage &&
        remainingAmount >= MINIMUM_BORROWING_AMOUNT_FOR_UNUTILISED_LIMIT && (
          <div className="flex items-start p-4 bg-secondary-lighter-3 rounded-b-xl">
            <Icon icon="ic:baseline-currency-exchange" className="text-grey-3 mr-2" width={24} />
            <p className="text-sm">
              You can borrow the remaining {formatCurrency(remainingAmount)} now or anytime before it expires on{' '}
              <span className="font-medium">
                {dayjsSydney(
                  loanApplicationData?.bankStatementReferences?.filter(
                    (ref) => ref?.bankStatementReference?.callBackReceivedAt !== null
                  )[0]?.bankStatementReference?.callBackReceivedAt
                )
                  .add(EXPIRY_DAYS_FOR_UNUTILISED_LIMIT - 1, 'days')
                  .format('D MMM YYYY')}
              </span>
              .{' '}
            </p>
          </div>
        )}
    </div>
  );
};

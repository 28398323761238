import React, { useMemo } from 'react';
import { UiQuoteStatus } from '@harmoney/api-interfaces';
import { UserInstanceQueryResponse } from '@harmoney/redux';

import { initialProgress, ProgressRender, trackProgress, UiQuoteStatusWithProgress } from '../ProgressRender';

type Props = {
  data: UserInstanceQueryResponse;
  display?: 'list' | 'bar';
};

export const QuoteProgress = ({ data, display = 'list' }: Props) => {
  const uiStatusMap: UiQuoteStatus[] = useMemo(() => {
    return data?.uiQuoteStatusMap as UiQuoteStatus[];
  }, [data]);

  const progress: UiQuoteStatusWithProgress[] = useMemo(() => {
    if (!data || !uiStatusMap) return null;

    if (!data?.completedTasks) return initialProgress(uiStatusMap);

    return uiStatusMap
      .slice()
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map((uiStatus) => {
        return trackProgress(data?.completedTasks, data?.incompleteTasks, uiStatus);
      });
  }, [data, uiStatusMap]);

  return <ProgressRender progress={progress} taskTotal={uiStatusMap?.length} display={display} />;
};

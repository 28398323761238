import { forwardRef } from 'react';
import type { CheckboxProps as RadixCheckboxProps } from '@radix-ui/react-checkbox';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import classNames from 'classnames';

import { CheckSquareIcon, IconSize, IconV2 } from '../Icon';
import { Label } from '../Label';

import styles from './Checkbox.module.scss';

export interface UncontrolledCheckboxProps extends Omit<RadixCheckboxProps, 'asChild'> {
  /**
   * The name of the checkbox. Submitted with its owning form as part of a name/value pair.
   */
  name: string;
  /**
   * The label of the checkbox
   */
  label?: React.ReactElement | string;
  /**
   * The position of label
   */
  alignLabel?: 'left' | 'right';

  flexClassName?: string;
  checkboxClassName?: string;
  checkIconSize?: IconSize;
  iconWidth?: number;
  blueIcon?: boolean;
}

export const UncontrolledCheckbox = forwardRef<HTMLButtonElement, UncontrolledCheckboxProps>(
  (
    {
      className,
      name,
      disabled,
      label,
      alignLabel = 'right',
      value,
      checked,
      flexClassName,
      checkboxClassName,
      checkIconSize = 'medium',
      iconWidth = 24,
      blueIcon = false,
      ...restProps
    }: UncontrolledCheckboxProps,
    forwardRef: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <div className={className}>
        <div className={classNames('flex items-center gap-x-4', flexClassName)}>
          {label && alignLabel === 'left' && (
            <Label className="cursor-pointer" htmlFor={name}>
              {label}
            </Label>
          )}
          <RadixCheckbox.Root
            id={name}
            data-testid={name}
            ref={forwardRef}
            disabled={disabled}
            checked={checked}
            value={value}
            className={classNames(styles['checkbox'], checkboxClassName)}
            {...restProps}
          >
            <RadixCheckbox.Indicator>
              {checked === 'indeterminate' && (
                <IconV2
                  icon="ic:round-indeterminate-check-box"
                  className={classNames('block scale-[132%]', blueIcon ? 'text-secondary' : 'text-tertiary')}
                  width={iconWidth}
                  preserveAspectRatio="none"
                />
              )}
              {checked === true &&
                (blueIcon === true ? (
                  <IconV2
                    icon="material-symbols:check-box-rounded"
                    className="text-secondary block scale-[132%]"
                    preserveAspectRatio="none"
                    width={iconWidth}
                  />
                ) : (
                  <CheckSquareIcon size={checkIconSize} />
                ))}
            </RadixCheckbox.Indicator>
          </RadixCheckbox.Root>
          {label && alignLabel === 'right' && (
            <Label className="cursor-pointer" htmlFor={name}>
              {label}
            </Label>
          )}
        </div>
      </div>
    );
  }
);

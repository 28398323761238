import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  IExpenseOverrideReasonFields,
  IncomeAndExpensesOverrideDto,
  NetworthSourceEnum,
} from '@harmoney/api-interfaces';
import { useOverrideIncomeAndExpenseMutation } from '@harmoney/redux';
import {
  Badge,
  Button,
  Card,
  CommonOptionProps,
  CurrencyWithFrequency,
  Divider,
  Form,
  useForm,
} from '@harmoney/ui-design-system';
import { booleanToYesNo, capitalizeTitle, CurrencyFormatter, stringToBool, valueOrNA } from '@harmoney/ui-utils';
import { IncomeAndExpenseFrequencyEnum, LoanApplicationStatusEnum } from '@prisma/client';
import classNames from 'classnames';
import { Entry } from 'contentful';

import { Field } from '../../shared';
import {
  NonSharedOverrideExpenseFormSchemaType,
  OverrideForm,
  sharedOverrideFormSchema,
  SharedOverrideFormSchemaType,
} from '../Override/OverrideForm';

import { Expense } from './ExpenseDetails';

export const IndividualExpense = ({
  expense,
  applicationStatus,
  overrideReasons,
  withForm,
}: {
  expense: Expense;
  applicationStatus: LoanApplicationStatusEnum;
  overrideReasons?: Entry<IExpenseOverrideReasonFields>[];
  withForm?: boolean;
}) => {
  const [overrideForm, setOverrideForm] = useState(false);
  const overrideOptions: CommonOptionProps[] = overrideReasons
    ? [
        { label: 'Select an option', value: '' },
        ...overrideReasons.map((reason) => ({
          label: reason.fields.reason,
          value: reason.sys.id,
        })),
        { label: 'Other', value: 'other' },
      ]
    : [];
  const [overrideExpense] = useOverrideIncomeAndExpenseMutation();

  const form = useForm({
    schema: sharedOverrideFormSchema,
    defaultValues: {
      overrideItem: [expense].map((expense) => ({
        itemId: expense.id,
        overrideAmount: undefined,
        overrideFrequency: undefined,
        overrideReason: undefined,
        overrideReasonForOther: undefined,
        overrideTotalAmount: undefined,
        overrideTotalFrequency: undefined,
        overrideIsExpenseShared: expense.isExpenseShared ? undefined : 'No',
      })),
    },
  });

  const { reset, control } = form;

  const { fields } = useFieldArray({
    control,
    name: 'overrideItem',
  });

  const handleOverrideSubmit = async (data: SharedOverrideFormSchemaType | NonSharedOverrideExpenseFormSchemaType) => {
    const overrideDto: IncomeAndExpensesOverrideDto = data.overrideItem.map((item) => ({
      id: item.itemId,
      creditOfficerAmount: item.overrideAmount,
      creditOfficerFrequency: item.overrideFrequency as IncomeAndExpenseFrequencyEnum,
      overrideReason:
        item.overrideReason === 'other'
          ? `Other - ${item.overrideReasonForOther}`
          : (overrideOptions.find((option) => option.value === item.overrideReason).label as string),
      overrideReasonId: item.overrideReason,
      creditOfficerTotalAmount: item.overrideTotalAmount,
      creditOfficerFrequencyForTotalAmount: item.overrideTotalFrequency as IncomeAndExpenseFrequencyEnum,
      creditOfficerIsExpenseShared: stringToBool(item.overrideIsExpenseShared) || undefined,
    }));
    await overrideExpense(overrideDto);
    setOverrideForm(false);
  };

  if (expense.networthSource.id === NetworthSourceEnum.EXPENSE_CHANGE_ID) {
    return (
      <Card
        key={expense.id}
        className={classNames('shadow-none border border-grey-2 h-fit !py-4 !px-8 space-y-2 !mb-0')}
      >
        <Field>
          <span>Expense change:</span>
          <span>{booleanToYesNo(expense?.expectIncreaseDecreaseChange)}</span>
        </Field>
        {expense?.expectIncreaseDecreaseChange && (
          <Field>
            <span>By how much will it increase:</span>
            <CurrencyWithFrequency amount={expense?.declaredAmount} frequency={expense?.frequency} />
          </Field>
        )}
      </Card>
    );
  }

  return (
    <Card className={classNames('shadow-none relative border border-grey-2 h-fit !p-4 space-y-2 !mb-0')}>
      <div className="p-4 space-y-2">
        {expense?.createdBy && <Badge label="Added by Credit Officer" variant="secondary" className="mb-4" />}
        <Field>
          <span>{expense?.isAccommodationExpense ? 'Accommodation Type' : 'Expense Category'}:</span>
          <span className="font-medium">{capitalizeTitle(expense?.networthSource.name)}</span>
        </Field>

        {expense?.networthSourceId === NetworthSourceEnum.EXPENSE_OTHER_ID && (
          <Field>
            <span>Other Expense Type:</span>
            <span>{valueOrNA(expense?.otherExpenseType)}</span>
          </Field>
        )}

        {!expense?.createdBy && (
          <>
            <Field>
              <span>Shared Cost</span>
              <span>{booleanToYesNo(expense?.isExpenseShared)}</span>
            </Field>

            <Field>
              <span>Declared Total Amount</span>
              <CurrencyWithFrequency
                amount={expense?.isExpenseShared ? expense?.declaredTotalAmount : expense?.declaredAmount}
                frequency={expense?.isExpenseShared ? expense?.frequencyForTotalAmount : expense?.frequency}
              />
            </Field>

            <Field>
              <span>Total Shared Amount</span>
              <span>
                {expense?.isExpenseShared ? (
                  <CurrencyWithFrequency amount={expense?.declaredAmount} frequency={expense?.frequency} />
                ) : (
                  'N/A'
                )}
              </span>
            </Field>
          </>
        )}

        {expense?.networthSourceId === NetworthSourceEnum.EXPENSE_CHILD_SUPPORT_ID && (
          <Field>
            <span>Child support deducted from Income:</span>
            <span>{booleanToYesNo(expense?.deductedFromIncome)}</span>
          </Field>
        )}
        {!overrideForm && (
          <>
            {expense?.updatedBy && (
              <div className="py-2 space-y-4">
                <Divider className="text-grey-2 absolute left-0 right-0" />
                <Badge label="Overwritten by credit officer" variant="secondary" />
              </div>
            )}
            {expense?.creditOfficerFrequency && (
              <Field>
                <span>{`Override ${expense.isExpenseShared || expense.creditOfficerIsExpenseShared ? 'shared' : ''} amount:`}</span>
                {expense?.creditOfficerAmount >= 0 ? (
                  <CurrencyWithFrequency
                    amount={expense?.creditOfficerAmount}
                    frequency={expense?.creditOfficerFrequency}
                  />
                ) : (
                  CurrencyFormatter.format(expense?.creditOfficerAmount)
                )}
              </Field>
            )}
            {expense?.creditOfficerFrequencyForTotalAmount && (
              <Field>
                <span>Override Total Amount:</span>
                {expense?.creditOfficerTotalAmount >= 0 ? (
                  <CurrencyWithFrequency
                    amount={expense?.creditOfficerTotalAmount}
                    frequency={expense?.creditOfficerFrequencyForTotalAmount}
                  />
                ) : (
                  CurrencyFormatter.format(expense?.creditOfficerTotalAmount)
                )}
              </Field>
            )}
            {expense?.creditOfficerIsExpenseShared && (
              <Field>
                <span>Override Shared Cost:</span>
                <span>{booleanToYesNo(expense?.creditOfficerIsExpenseShared)}</span>
              </Field>
            )}
            {expense?.overrideReason && (
              <Field>
                <span>Override Reason:</span>
                <span className="first-letter:capitalize">
                  {valueOrNA(
                    expense?.overrideReason.includes(' - ')
                      ? expense?.overrideReason.split(' - ')[1]
                      : expense?.overrideReason
                  )}
                </span>
              </Field>
            )}
          </>
        )}
        {overrideForm && (
          <Form form={form} id={`${expense.id}-form`} onSubmit={handleOverrideSubmit}>
            <OverrideForm
              index={fields.findIndex((field) => field?.itemId === expense.id)}
              field={fields.find((field) => field?.itemId === expense.id)}
              overrideOptions={overrideOptions}
              isShared={expense.isExpenseShared}
              type="expense"
              networthSourceCode={expense.networthSource.code}
            />
          </Form>
        )}
      </div>
      {withForm && (
        <>
          <Divider className="text-grey-2" />
          <div className="flex flex-row justify-end p-2">
            {!overrideForm ? (
              <Button
                size="small"
                variant="outline-secondary"
                className="!min-w-fit"
                disabled={applicationStatus !== LoanApplicationStatusEnum.quote_in_review}
                onClick={() => {
                  reset();
                  setOverrideForm(true);
                }}
              >
                {expense.updatedBy ? 'edit' : 'Override'}
              </Button>
            ) : (
              <div className="flex flex-row gap-2">
                <Button size="small" variant="text" className="!min-w-fit" onClick={() => setOverrideForm(false)}>
                  Cancel
                </Button>
                <Button size="small" variant="accent" className="!min-w-fit" type="submit" form={`${expense.id}-form`}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </Card>
  );
};

import { z } from 'zod';

export const defaultValues = {
  loanPurpose: { primary: '', secondary: '' },
};

export const formSchema = z.object({
  loanPurpose: z
    .object({
      primary: z.string(),
      secondary: z.string(),
    })
    .refine((data) => data.primary, {
      path: ['primary'],
    })
    .refine((data) => data.primary, {
      message: 'Please select a loan purpose',
      path: ['secondary'],
    }),
});

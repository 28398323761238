import { useEffect, useMemo, useRef } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useGetDebtConsolidationQuery,
  useGetLoanApplicationQuery,
  useGetVariablesQuery,
  useUpdateDebtConsolidationBenefitAndFundedAmountMutation,
  useUpdatePostApprovalConsolidatedLiabilitiesMutation,
} from '@harmoney/redux';
import { DEBTCON_OBJECTIVES_ACCEPTED, eventAnalytics } from '@harmoney/ui-app-shell';
import { Alert, ArrowCircleRightIcon, Button, Form, Spinner, useForm } from '@harmoney/ui-design-system';
import { AmountFormatter } from '@harmoney/ui-utils';

import { CommonProps } from '../../common-props';

import { DebtsToConsolidate, Disclaimer } from './components';
import { formSchema } from './form-config';

export function ConsolidateDebt({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const pageRef = useRef<HTMLFormElement>(null);
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });

  const applicationId = variables?.loanApplicationId as string;
  const financialProfileId = variables?.financialProfileId as string;
  const interestRate = loanApplicationData?.finalInterestRate as unknown as number;
  const minimumBorrowingLimit =
    (loanApplicationData?.quoteMinBorrowingLimit as number) ||
    ((variables?.quote as any)?.minimumBorrowingLimit as number);
  const maximumBorrowingLimit =
    (loanApplicationData?.quoteMaxBorrowingLimit as number) ||
    ((variables?.quote as any)?.maximumBorrowingLimit as number);
  const establishmentFee = {
    establishmentFeeOverThreshold: (variables?.quote as any)?.establishmentFeeOverThreshold as number,
    establishmentFeeUnderThreshold: (variables?.quote as any)?.establishmentFeeUnderThreshold as number,
    establishmentFeeThreshold: (variables?.quote as any)?.establishmentFeeThreshold as number,
  };
  const {
    data: debtConsolidationData,
    isLoading,
    isSuccess,
  } = useGetDebtConsolidationQuery(
    {
      financialProfileId,
      interestRate,
      maximumBorrowingLimit,
    },
    { skip: !variables || !loanApplicationData, refetchOnMountOrArgChange: true }
  );
  const [updateLiabilities] = useUpdatePostApprovalConsolidatedLiabilitiesMutation();
  const [updateDebtConPurposeAndFundedAmount] = useUpdateDebtConsolidationBenefitAndFundedAmountMutation();

  const combinableDebts = debtConsolidationData?.liabilities.filter(
    (debt) => debt.liability.outstandingBalance <= maximumBorrowingLimit
  );

  const prefillDebtSelection = useMemo(() => {
    if (!combinableDebts || isLoading) return {};
    if (isSuccess)
      return combinableDebts?.reduce((acc, curr) => {
        acc[curr.liability.id] = curr.liability.postApprovalDebtConsolidation;
        return acc;
      }, {});
  }, [isLoading, isSuccess]);

  const form = useForm({
    mode: 'onSubmit',
    schema: formSchema(minimumBorrowingLimit, maximumBorrowingLimit),
    defaultValues: {
      reason: null,
      debtsSelection: {},
      totalToConsolidate: 0,
    },
  });

  useEffect(() => {
    if (Object.values(prefillDebtSelection).some((value) => value === true)) {
      form.setValue('debtsSelection', prefillDebtSelection);
    }
  }, [prefillDebtSelection]);

  const handleSubmit = async (data) => {
    eventAnalytics.track(DEBTCON_OBJECTIVES_ACCEPTED, {
      taskid_str: taskId,
    });

    await updateLiabilities(data.debtsSelection);
    await updateDebtConPurposeAndFundedAmount({
      applicationId,
      debtConsolidationBenefits: data.reason,
      fundedAmount: data.totalToConsolidate,
    });

    completeTask({ taskId, variables: { totalToConsolidate: data?.totalToConsolidate } });
  };

  useFriendlyURL(taskFriendlyURL);

  const canConsolidateAll = combinableDebts?.length === debtConsolidationData?.liabilities.length;

  const canSelectAll =
    combinableDebts?.reduce((acc, curr) => acc + curr.liability.outstandingBalance, 0) <= maximumBorrowingLimit;

  const allDebtsTotal = debtConsolidationData?.liabilities.reduce(
    (acc, curr) => acc + curr.liability.outstandingBalance,
    0
  );

  if (isLoading || !debtConsolidationData || !loanApplicationData) {
    return <Spinner size="large" />;
  }

  return (
    <>
      <h1>
        Select your <span className="text-primary">debts to consolidate</span>
      </h1>
      <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-2 relative" ref={pageRef}>
        <DebtsToConsolidate
          debts={combinableDebts}
          canSelectAll={canSelectAll}
          maximumBorrowingLimit={maximumBorrowingLimit}
          interestRate={interestRate}
          applicationId={applicationId}
          establishmentFee={establishmentFee}
          pageRef={pageRef}
        />
        {(!canConsolidateAll || !canSelectAll) && (
          <Alert variant="info" collapsible title="Why can’t I consolidate all of my debts?" defaultCollapsed={true}>
            <p className="text-sm">
              {`The total balance of your debts is ${AmountFormatter.format(allDebtsTotal)}. However, we've calculated that you can only borrow up to
              ${AmountFormatter.format(maximumBorrowingLimit)}.`}
            </p>
          </Alert>
        )}
        <Disclaimer />
        <div className="flex flex-col gap-4">
          <Button alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} variant="primary" type="submit">
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
}

import { useEffect, useState } from 'react';
import { loanVariationTypeMapper, VaultLoanInformationDto, VaultPaymentInformationDto } from '@harmoney/api-interfaces';
import { useGetVariablesQuery, useSimulateVariationMutation } from '@harmoney/redux';
import { Alert, Button, ControlledInput, Form, Label, Select, useForm } from '@harmoney/ui-design-system';
import { LoanVariationPurposeEnum } from '@prisma/client';
import dayjs from 'dayjs';

import { CommonProps } from '../../../common-props';

import { ContractualPaymentDateFields } from './ContractualPaymentDateFields';
import { createSimulationFormSchema, getSimulationDefaultValues } from './form-config';
import { loanVariationOptions, LoanVariationOptionsEnum, loanVariationPurposeOptions } from './loan-variation-options';
import { RepaymentHolidayFields } from './RepaymentHolidayFields';
import { contractualEndDate, contractualStartDate } from './utils';
import { VariationFormHeader } from './VariationFormHeader';

export interface LoanVariationSimulationProps extends CommonProps {
  loanApplicationId: string;
  loanInformation: VaultLoanInformationDto;
  paymentInformation: VaultPaymentInformationDto;
}

export const LoanVariationSimulation = ({
  loanApplicationId,
  loanInformation,
  taskId,
  paymentInformation,
  completeTask,
}: LoanVariationSimulationProps) => {
  const { data: variables } = useGetVariablesQuery(taskId);

  const spokeId = variables?.spokeId?.toString();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showDateAlert, setShowDateAlert] = useState<boolean>(false);

  const form = useForm({
    mode: 'onTouched',
    schema: createSimulationFormSchema(loanInformation?.remainingTermInMonths),
    defaultValues: getSimulationDefaultValues(loanInformation?.remainingTermInMonths),
  });

  const {
    register,
    watch,
    formState: { isValid },
  } = form;

  const watchForm = watch();

  const [submitSimulateVariation] = useSimulateVariationMutation();

  const handleSubmit = async (simulationData) => {
    setIsSubmitting(true);
    const formattedDate = dayjs(simulationData.contractualPaymentDate).format('YYYY-MM-DD');
    const effectiveDate =
      simulationData.loanVariationType === LoanVariationOptionsEnum.REPAYMENT_HOLIDAY
        ? dayjs(simulationData.effectiveDate).format('YYYY-MM-DD')
        : null;

    const data = {
      ...simulationData,
      contractualPaymentDate: formattedDate,
      loanTerm: simulationData.loanTerm.toString(),
      effectiveDate,
      spokeId,
    };
    const result = await submitSimulateVariation({ loanApplicationId, data }).unwrap();

    const splitSimulationInfo = result.split('/');
    const simulationId = splitSimulationInfo[splitSimulationInfo.length - 2];

    completeTask({
      taskId,
      variables: { loanApplicationId, simulationId, variationType: simulationData.loanVariationType },
    });
  };

  useEffect(() => {
    const watchDate = dayjs(watchForm.contractualPaymentDate).format('DD/MM/YYYY');
    const paymentDate = dayjs(paymentInformation?.monthlyContractualPaymentDate).format('DD/MM/YYYY');
    const isDateEqual = watchDate === paymentDate;
    const isTermEqual = Number(watchForm.loanTerm) === Number(loanInformation?.remainingTermInMonths);

    if (isDateEqual && isTermEqual) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [watchForm.contractualPaymentDate, watchForm.loanTerm]);

  useEffect(() => {
    const effectiveDate = watchForm.effectiveDate;
    const contractualPaymentDate = watchForm.contractualPaymentDate;

    if (contractualPaymentDate <= effectiveDate) {
      setShowDateAlert(true);
    } else {
      setShowDateAlert(false);
    }
  }, [watchForm.effectiveDate, watchForm.contractualPaymentDate]);

  return (
    <>
      <VariationFormHeader form={form} />
      <Form form={form} onSubmit={handleSubmit}>
        <div className="px-4">
          <Select
            {...register('loanVariationType')}
            label="Variation type"
            options={loanVariationOptions}
            className="mb-6"
          />

          {watchForm.loanVariationType && (
            <Select {...register('purpose')} label="Purpose" options={loanVariationPurposeOptions} className="mb-6" />
          )}

          {watchForm.purpose === LoanVariationPurposeEnum.OTHER && (
            <>
              <Label htmlFor="otherPurpose" className="mb-2">
                Specify the purpose for the {loanVariationTypeMapper[watchForm.loanVariationType]}
              </Label>
              <ControlledInput {...register('otherPurpose')} className="mb-6" type="text" />
            </>
          )}

          {watchForm.loanVariationType == LoanVariationOptionsEnum.REPAYMENT_HOLIDAY && (
            <RepaymentHolidayFields register={register} contractualStartDate={contractualStartDate} />
          )}

          {watchForm.loanVariationType == LoanVariationOptionsEnum.CONTRACTUAL_PAYMENT_DATE && (
            <ContractualPaymentDateFields
              contractualStartDate={contractualStartDate}
              contractualEndDate={contractualEndDate}
              register={register}
            />
          )}

          {showAlert && (
            <Alert variant="warning" title="No changes found">
              <p className="text-sm">
                Before simulating a variation please update the information entered as it matches the original terms.
              </p>
            </Alert>
          )}

          {showDateAlert && (
            <Alert variant="warning" title="Pause date can not be on or after new contractual payment date" />
          )}

          {isValid && watchForm.purpose && watchForm.loanVariationType && !showDateAlert && (
            <div className="flex items-center justify-start gap-2 sm:flex-col md:flex-row-reverse py-4">
              <Button
                type="submit"
                variant="tertiary"
                className="sm:!min-w-full md:!min-w-fit md:!max-w-fit"
                isLoading={isSubmitting}
              >
                Simulate Variation
              </Button>
            </div>
          )}
        </div>
      </Form>
    </>
  );
};

import { emptySplitApi } from './empty-api';

const emailApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    sendVerificationEmail: builder.query<void, void>({
      query: () => ({
        url: '/emails/trigger-verification',
        method: 'GET',
      }),
    }),
    checkEmailVerificationStatus: builder.query<
      { verificationFlag: boolean },
      { userId: string; idpIdentifier: string }
    >({
      query: ({ userId, idpIdentifier }) => ({
        url: `/emails/check-verification-status/${userId}/${idpIdentifier}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useSendVerificationEmailQuery, useCheckEmailVerificationStatusQuery } = emailApi;

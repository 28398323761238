export enum EventNameEnum {
  LOAN_APPLICATION_CREATED = 'loan_application_created',
  LOAN_APPLICATION_STATUS_UPDATED = 'loan_application_status_updated',
  DISBURSAL_FAILURE = 'disbursal_failure',
  DISBURSAL_SUCCESS = 'disbursal_success',
  LOAN_INSTANTIATED = 'loan_instantiated',
  READY_TO_DISBURSE = 'ready_to_disburse',
  INTEREST_RATE_ADJUSTED = 'interest_rate_adjusted',
  CREDIT_AUDITED = 'credit_audited',
  DISBURSEMENT_AUDITED = 'disbursement_audited',
  MANUAL_REVIEW_COMPLETED = 'manual_review_completed',
  UNUTILISED_LIMIT_QUALIFIED = 'unutilised_limit_qualified',
  UNUTILISED_LIMIT_UNQUALIFIED = 'unutilised_limit_unqualified',
}

export enum SendBackportBasedOnTypeEnum {
  START_END_DATE = 'start_end_date',
  RECORD_ID_LIST = 'record_id_list',
}

export enum BackportEventTypeEnum {
  LOAN_APPLICATION = 'loan_application',
  SPOKE = 'spoke',
  USER = 'user',
  SPOKE2 = 'spoke2',
  SPOKE3 = 'spoke3',
  SPOKE4 = 'spoke4',
  REPROCESS_OPERATION = 'reprocess_operation',
}

export enum SpokeEventNameEnum {
  GETTING_TO_KNOW_YOU_START = 'Getting to Know You - Started - bpm',
  GETTING_TO_KNOW_YOU_COMPLETE = 'Getting to Know You - Completed - bpm',
  IDENTITY_VERIFICATION_START = 'Identity Verification - Started - bpm',
  IDENTITY_VERIFICATION_COMPLETE = 'Identity Verification - Completed',
  IDENTITY_VERIFICATION_DUPLICATED = 'Identity Verification - Duplicate (stop) - bpm',
  IDENTITY_VERIFICATION_SUCCESSFUL = 'Identity Verification - Completed - Success - bpm',
  IDENTITY_VERIFICATION_FAILED = 'Identity Verification - Fail (stop) - bpm',
  CREDIT_FILE_SOFT_PULL_START = 'Credit File Soft Pull - Started - bpm',
  CREDIT_FILE_HARD_PULL_START = 'Credit File Hard Pull - Started - bpm',
  CREDIT_FILE_SOFT_PULL_SUCCESS_COMPLETE = 'Credit File Soft Pull - Completed - success - bpm',
  CREDIT_FILE_HARD_PULL_SUCCESS_COMPLETE = 'Credit File Hard Pull - Completed - success - bpm',
  CREDIT_FILE_SOFT_PULL_FAILED = 'Credit File Soft Pull - Completed - Fail (stop) - bpm',
  CREDIT_FILE_HARD_PULL_FAILED = 'Credit File Hard Pull - Completed - Fail (stop) - bpm',
  CREDIT_RULES_SOFT_PULL_HMONEY_START = 'Credit File Rules Soft Pull hMoney - Started - bpm',
  CREDIT_RULES_SOFT_PULL_HMONEY_SUCCESS = 'Credit File Rules Soft Pull hMoney - Completed - Success - bpm',
  CREDIT_RULES_SOFT_PULL_HMONEY_FAILED = 'Credit File Rules Soft Pull hMoney - Completed - Fail - bpm',
  CREDIT_RULES_SOFT_PULL_HLOAN_START = 'Credit File Rules Soft Pull hLoan - Started - bpm',
  CREDIT_RULES_SOFT_PULL_HLOAN_SUCCESS = 'Credit File Rules Soft Pull hLoan - Completed - Success - bpm',
  CREDIT_RULES_SOFT_PULL_HLOAN_FAILED = 'Credit File Rules Soft Pull hLoan - Completed - Fail - bpm',
  CREDIT_RULES_HARD_PULL_START = 'Credit File Rules Hard Pull - Started - bpm',
  CREDIT_RULES_HARD_PULL_SUCCESS = 'Credit File Rules Hard Pull - Completed - Success - bpm',
  CREDIT_RULES_HARD_PULL_FAILED = 'Credit File Rules Hard Pull - Completed - Fail - bpm',
  BANK_STATEMENT_STARTED = 'Bank Statement - Started - bpm',
  BANK_STATEMENT_COMPLETED = 'Bank Statement - Completed - bpm',
  BIOMETRIC_STARTED = 'Biometrics - Started - bpm',
  BIOMETRIC_SUCCESS = 'Biometrics - Completed - bpm',
  BIOMETRIC_FAILED = 'Biometrics - Completed - Fail (stop) - bpm',
  FRAUD_CHECK_STARTED = 'Fraud Check - Started - bpm',
  FRAUD_CHECK_SUCCESS = 'Fraud Check - Completed - Success - bpm',
  FRAUD_CHECK_FAILED = 'Fraud Check - Completed - Fail - bpm',
  RISK_RULE_HLOAN_STARTED = 'Risk rules hLoan - Started - bpm',
  RISK_RULE_HLOAN_SUCCESS = 'Risk rules hLoan - Completed - Success - bpm',
  RISK_RULE_HLOAN_FAILED = 'Risk rules hLoan - Completed - Fail - bpm',
  RISK_RULE_HMONEY_STARTED = 'Risk rules hMoney - Started - bpm',
  RISK_RULE_HMONEY_SUCCESS = 'Risk rules hMoney - Completed - Success - bpm',
  RISK_RULE_HMONEY_FAILED = 'Risk rules hMoney - Completed - Fail - bpm',
  AFFORDABILITY_STARTED = 'Affordability - Started - bpm',
  AFFORDABILITY_SUCCESS = 'Affordability - Completed - Success - bpm',
  AFFORDABILITY_FAILED = 'Affordability - Completed - Fail - bpm',
  FULFILMENT_STARTED = 'Fulfillment - Started - bpm',
  FULFILMENT_SUCCESS = 'Fulfillment - Completed - Success - bpm',
  FULFILMENT_READY_TO_DISBURSE = 'ready_to_disburse',
  DISBURSAL_STARTED = 'Disbursement - Started - bpm',
  DISBURSAL_COMPLETED = 'Disbursement - Completed - bpm',
  DISBURSAL_SUCCESS = 'disbursal_success',
  SCORECARD_STARTED = 'Scorecard - Started - bpm',
  SCORECARD_SUCCESS = 'Scorecard - Success - bpm',
  SCORECARD_FAILED = 'Scorecard - Fail - bpm',
}

import { useEffect, useState } from 'react';
import { IdentityVerificationStatus } from '@harmoney/api-interfaces';
import { SmartUIConfigType, useFriendlyURL, useInitialiseFrankieSmartUI } from '@harmoney/hooks';
import { useLazyUpdateVerifiedUserProfileQuery } from '@harmoney/redux';
import { eventAnalytics, IDENTITY_VERIFICATION_COMPLETED } from '@harmoney/ui-app-shell';
import { LoanApplicationStatusEnum } from '@prisma/client';

import { CommonProps } from '../../common-props';

const timeoutMillisecond = +process.env['NEXT_PUBLIC_FRANKIEONE_SCREEN_DISPLAY_DURATION_IN_MILLISECONDS'];

export function FrankieSmartUI({ taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const [shouldRender, setShouldRender] = useState(false);
  const { userId, branch } = useInitialiseFrankieSmartUI(taskId, SmartUIConfigType.DEFAULT);
  const [trigger] = useLazyUpdateVerifiedUserProfileQuery();

  const isFinalResult = (verificationStatus: IdentityVerificationStatus): boolean => {
    return [
      IdentityVerificationStatus.SUCCESS,
      IdentityVerificationStatus.PENDING_SUCCESS,
      IdentityVerificationStatus.TOO_MANY_TRIES,
    ].includes(verificationStatus);
  };

  useEffect(() => {
    const handleCheckResult = async (e: CustomEvent) => {
      const verificationStatus = e.detail.resultSlug;
      const entityId = e.detail.applicant.entityId;
      const checkSummary = e.detail.checkSummary;
      if (isFinalResult(verificationStatus)) {
        let applicationStatus: LoanApplicationStatusEnum = LoanApplicationStatusEnum.quote_in_progress;
        await trigger({ branch, entityId });
        if (verificationStatus === IdentityVerificationStatus.TOO_MANY_TRIES) {
          applicationStatus = LoanApplicationStatusEnum.quote_unsuccessful;
          completeTask({
            taskId,
            variables: {
              verificationStatus,
              applicationStatus,
              eventProperties: { f1CheckSummary: checkSummary },
            },
          });
          return;
        }
        if (
          verificationStatus === IdentityVerificationStatus.SUCCESS ||
          verificationStatus === IdentityVerificationStatus.PENDING_SUCCESS
        ) {
          eventAnalytics.track(IDENTITY_VERIFICATION_COMPLETED, {
            userid_str: userId,
            taskid_str: taskId,
          });
        }
        setTimeout(
          () =>
            completeTask({
              taskId,
              variables: {
                verificationStatus,
                applicationStatus,
                eventProperties: { f1CheckSummary: checkSummary },
              },
            }),
          timeoutMillisecond
        );
      }
    };

    const handleCheckError = async (e: CustomEvent) => {
      const entityId = e.detail?.applicant?.entityId;
      await trigger({ branch, entityId });
      setTimeout(
        () =>
          completeTask({
            taskId,
            variables: {
              verificationStatus: 'check-error',
              applicationStatus: LoanApplicationStatusEnum.quote_unsuccessful,
              eventProperties: { f1CheckSummary: e?.detail?.checkSummary },
            },
          }),
        timeoutMillisecond
      );
    };

    const handleSaveError = async (e: CustomEvent) => {
      const entityId = e.detail?.applicant?.entityId;
      await trigger({ branch, entityId });
      setTimeout(
        () =>
          completeTask({
            taskId,
            variables: {
              verificationStatus: 'save-error',
              applicationStatus: LoanApplicationStatusEnum.quote_unsuccessful,
              eventProperties: { f1CheckSummary: e?.detail?.checkSummary },
            },
          }),
        timeoutMillisecond
      );
    };

    const handleScreenFailure = async (e: CustomEvent) => {
      const entityId = e.detail?.applicant?.entityId;
      await trigger({ branch, entityId });
      await completeTask({
        taskId,
        variables: {
          verificationStatus: 'check-error',
          applicationStatus: LoanApplicationStatusEnum.quote_unsuccessful,
        },
      });
    };

    /**
     * FF_SAVE_RESULT: Emitted when save is successful.
     * FF_CHECK_RESULT: Emitted when retrieving a check result. We use this event to make the success screen hold for timeoutMillisecond.
     * FF_CHECK_ERROR or FF_SAVE_ERROR: Emitted when one of the two results above fail due to a System error OR lack of permissions. We use this event to make the error screen hold for timeoutMillisecond and send the verification status back to Camunda.
     * More details see https://apidocs.frankiefinancial.com/docs/events
     */
    window.addEventListener('FF_CHECK_RESULT', handleCheckResult);
    window.addEventListener('FF_CHECK_ERROR', handleCheckError);
    window.addEventListener('FF_SAVE_ERROR', handleSaveError);
    window.addEventListener('SCREEN:FAILURE', handleScreenFailure);

    return () => {
      window.removeEventListener('FF_CHECK_RESULT', handleCheckResult);
      window.removeEventListener('FF_CHECK_ERROR', handleCheckError);
      window.removeEventListener('FF_SAVE_ERROR', handleSaveError);
      window.removeEventListener('SCREEN:FAILURE', handleScreenFailure);
    };
  }, [completeTask, taskId, userId, branch]);

  useFriendlyURL(taskFriendlyURL);

  useEffect(() => {
    setShouldRender(true);
    return () => {
      setShouldRender(false);
    };
  }, []);

  if (shouldRender) return <ff-onboarding-widget width="576px" height="600px" />;
}
